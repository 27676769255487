import { useState, useEffect } from 'react';
import { onPostPersonsChange } from 'src/services/cartApi';
import { onDeleteRemoveFromCart } from 'src/services/cartApi';
import { onGetSingleDes } from 'src/services/homeDestinationsApi';
import { onGetAvailableOption } from 'src/services/availableOptionApi';
import { onPostDateChange } from 'src/services/cartApi';
import './Cart.css';
import Rating from '@mui/material/Rating'
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { CDropdown, CDropdownToggle, CDropdownMenu, CDropdownItem } from '@coreui/react';
import { setTimeout } from 'core-js';

const CartDestination = ({id, bId, persons, price}) => {

    const [value, setValue] = useState(new Date());
    const [msg, setMsg] = useState("");
    const [successMsg, setSuccessMsg] = useState(false);
    const [destination, setDestination] = useState({});
    const [newBookingId, setNewBookingId] = useState("");


    useEffect(() => {

        onGetSingleDes(id).then((res) => {
            if(!res.error){
            setDestination(res.data.results.destination_data)
            }
        })
    },[id])


    const changeParticipats = (e) => {
        
        onPostPersonsChange({booking_option_id: bId, no_of_person: e.target.value}).then((res) => {
            if(!res.error){
                setMsg(res.data.message)
                setSuccessMsg(true)
                setTimeout(() => {
                    window.location.href = "/cart"
                },2000)
                
            }
        })
    }


    const removeFromCart = () => {
        
        onDeleteRemoveFromCart(bId).then((res) => {
            if(!res.error){
                
                setMsg(res.data.message)
                setSuccessMsg(true)
                setTimeout(() => {
                    window.location.href = "/cart"    
                },2000)
            }
        })
    }


    const availableOption = (value) => {
        setValue(value)
        onGetAvailableOption(id,value.toISOString()).then((res) => {
            if(!res.error){
                setNewBookingId(res.data?.results[0]._id)
            }
        })
    }


    const changeDate = () => {

        onPostDateChange({old_booking_option_id:bId, new_booking_option_id:newBookingId, no_of_person:persons}).then((res) => {
            if(!res.error){
                setMsg(res.data.message)
                setSuccessMsg(true)
                setTimeout(() => {
                    window.location.href = '/cart'
                },2000)
            }
        })
    }

    return (
        <>       
        <div className="cartDestination">
            {successMsg && <div className="removeCartMsg"><h6>{msg}</h6></div>}
            <div className='desImage'>
                <img src={`${process.env.REACT_APP_BASE_URL}${destination.image}`} alt="" />   
            </div>

            <div className='desDetails'>
                    <div className='desTitle'>
                    <h6>{destination.title}</h6>
                    <i className="fa-solid fa-trash-can" onClick={removeFromCart}></i>
                    </div>
                    <div className='ratingPrice'>
                        <div className='cartStars'>{!destination.star ? <>0 <Rating name="read-only" value={0}  readOnly /></> : <>{destination.star} <Rating name="read-only" value={destination.star}  readOnly /> </>}</div>
                        <p>₹{price}</p>
                    </div>
                    <div className='cartDesPersons'>
                        <div>
                        <i className="fa-solid fa-user-group"></i> {persons}
                        </div>
                        
                        <div id="personDropdown">
                        <CDropdown>
                            <CDropdownToggle color="secondary"></CDropdownToggle>
                            <CDropdownMenu >
                                <CDropdownItem value={1} onClick={changeParticipats} component="button">1</CDropdownItem>
                                <CDropdownItem value={2} onClick={changeParticipats} component="button">2</CDropdownItem>
                                <CDropdownItem value={3} onClick={changeParticipats} component="button">3</CDropdownItem>
                                <CDropdownItem value={4} onClick={changeParticipats} component="button">4</CDropdownItem>
                                <CDropdownItem value={5} onClick={changeParticipats} component="button">5</CDropdownItem>
                            </CDropdownMenu>
                        </CDropdown>
                        </div>

                    </div>
                    <div className='cartDesDate'>
                        <div>
                        <i className="fa-solid fa-calendar-days"></i> August 28, 2022
                        </div>

                        <div id="cartDatePicker">
                        <div style={{display:"flex", flexDirection:"column", alignItems:"flex-end"}}>    
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                renderInput={(props) => <TextField {...props} />}
                                views={["day"]}
                                value={value}
                                label="DatePicker" 
                                onChange={availableOption}
                            />
                        </LocalizationProvider>
                        <button className='chngDateBtn' onClick={changeDate}>apply</button>
                        </div>
                        
                        </div>
                        
                    </div>
                    <div className='cartDesStartingTime'><i className="fa-solid fa-clock"></i> Starting Time: 8:30 AM</div>
                    <div className='cartLang'><i className="fa-solid fa-globe"></i> Language: English</div>
                    <div className='cartCancel'>
                        <div><i className="fa-solid fa-calendar-check"></i> Free cancellation
                        Cancel before 8:30 AM (local time) on August 27 for a full refund.</div>
                    </div>
                    <button className="cartChangeBtn" id="cartChngBtn" onClick={() => {
                        document.getElementById("cartDatePicker").style.display = "block"
                        document.getElementById("personDropdown").style.display = "block"
                        document.getElementById("cartChngBtn").style.display = "none"
                        document.getElementById("cancelBtn").style.display = "block"
                    }}>Change date or participants</button>
                    <button className="cartChangeBtn" id="cancelBtn" onClick={() => {
                        document.getElementById("cancelBtn").style.display = "none"
                        document.getElementById("cartDatePicker").style.display = "none"
                        document.getElementById("personDropdown").style.display = "none"
                        document.getElementById("cartChngBtn").style.display = "block"
                    }}>Cancel</button>
                    
            </div>
        </div>
        
        </>
    )
}

export default CartDestination;