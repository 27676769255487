import {useState} from 'react';
import Modal from "@mui/material/Modal";
import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from 'react-image-gallery';
import './DestinationDetail.css';


const StaticImage = ({image,image0, number}) => {

    const [fullImage, setFullImage] = useState(false);
    const ImageOpen = () => setFullImage(true);
    const ImageClose = () => setFullImage(false);

    return (
        <>
        <Modal
        open={fullImage}
        onClose={ImageClose}
      >
       <div className="allImgModal">
       <ImageGallery items={image} startIndex={number} />
          <i style={{color:"#fff"}} className="fa-solid fa-xmark" onClick={ImageClose}></i>
        </div>
      </Modal>
      <img src={image0} alt='' onClick={ImageOpen}/>
         </>
    )
}

export default StaticImage;