import { useEffect, useState } from 'react';
import Modal from '@mui/material/Modal';
import { useForm } from "react-hook-form";
import { useSelector } from 'react-redux'; 
import { onGetqa } from 'src/services/qaApi';
import { onPostQA } from 'src/services/qaApi';
import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import Answers from './Answers';
import './DestinationDetail.css';
import Questions from './Questions';
import QAVote from './QAVote';

const QA = ({id, cId}) => {

    const { register, handleSubmit, formState: { errors } } = useForm();

    const userId = useSelector((state) => state.detailReducer.user_Id);

    const navigate = useNavigate();

    const qaToShow = 2;
    const [next, setNext] = useState(qaToShow)
    const [qaList, setqaList] = useState([]);
    const [question, setQuestion] = useState("");
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [msg, setMsg] = useState("");
    const [search, setSearch] = useState("");
    

    const [isOpenQA, setIsOpenQA] = useState(false);

    const token = localStorage.getItem('token');

    let count = false;

    const qaReverse = qaList?.slice()?.reverse();

    const handleOpenQA = () => {
      !token ? navigate('/login') : setIsOpenQA(true);
    };
  
    const handleCloseQA = () => {
      setIsOpenQA(false);
    };
   


    useEffect(() => {
     
        onGetqa(id,search).then((res) => {
            if(!res.error){
                setqaList(res.data?.result)
               
            }
        })
    },[search])


    const handleFaqShow = () => {
        setNext(next + qaToShow)
        
    }

    const handleFaqHide = () => {
        setNext(2)
    }

    const postQuestion = () => {
      
        setLoading(true)
        onPostQA({destination_id: id, category_id: cId, question}).then((res) => {

            if(res.status === 201){
                setMsg(res.data.message)
                setSuccess(true)
                window.location.reload()
            }else{
                setLoading(false)
            }
        })
    }


   

    return (
        <>
        <div className='faq reuseableContainer' id="qa">
          <div className='strechContainer' style={{width:"100%"}}>
          <div className='strechSection'>
            
            <h4>Customers Questions & Answers</h4>
            
           <div className='qaSearch'>
                <div style={{width:"95%"}}>
                    <i className="fa-solid fa-magnifying-glass" style={{marginRight:"5px"}}></i> 
                    <input type="text" placeholder='Have a question? Search here' value={search} onChange={(e) => {
                        setSearch(e.target.value)
                    }}/>
                    </div> <i className="fa-solid fa-xmark" onClick={() => {
                        setSearch("")
                    }}></i>
                </div>

            {!qaList?.length ? <div className="faqError"><h5>No QA found</h5></div> : qaReverse?.slice(0, next)?.map((qa) => {
             return <div className='qaContainer'>
                        {qa.is_active === true && <QAVote vote={qa.total_votes} id={qa._id}/>}

             <div className="faqContainer" style={{marginTop: qa.answers?.length ? "40px" : "0px"}}>           
                        {qa.is_active === true && <Questions key={qa._id} question={qa.question} id={qa._id} qaUserId={qa.user_id} userId={userId}/>}
                        {qa.is_active === true ? count = true : count = false}
                         <Answers qa={qa} ans={qa.answers} qId={qa._id}/>
             
            </div>
            </div>})}
            {qaList?.length && count === false ? <div className="faqError sss"><h5>No QA found</h5></div> : <></>}
            
            {qaList?.length && qaList?.length > qaToShow ? <>
            {next < qaList?.length ? <button className="faqBtn" onClick={handleFaqShow}>Show more questions</button> :
            <button className="faqBtn" onClick={handleFaqHide}>Show less questions</button>}
            </>: <></>}
    
            
            <div className='postQuestion'><span>Don't see the answer you're looking for?</span>  <button onClick={handleOpenQA}>Post Your Question</button></div>
          </div>
          </div>
        </div>

       
        <Modal
        open={isOpenQA}
        onClose={handleCloseQA}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className='qaModal'>
            <div className='qaModalContent'>
                <div className='qaModalTitle'>
                    Post A Question
                    <i className="fa-solid fa-xmark" onClick={handleCloseQA}></i>
                </div>

                <div className='qaInput'>
                <input type='text' placeholder='Question...'
                 {...register("question", {required: "Question is required"})}
                onChange={(e) => {
                    setQuestion(e.target.value)
                } }/>
                {success && <div className='qaMsg'><p>{msg}</p></div>}
                <p style={{color: "red"}}>{errors.question?.message}</p>
                
                <Button type="primary" size="middle" onClick={handleSubmit(postQuestion)} loading={loading} disabled={loading}>
                        Post
                </Button>
                </div>
            </div>    
        </div>
      </Modal>
 
        </>    
    )
}

export default QA;