import axios from 'axios';

const BASE_URL = process.env.REACT_APP_BASE_URL;


export const onGetPopularOffers = async () => {

    return await axios.get(`${BASE_URL}/destinations/getDestinationsBySearch?searchTerm&duration&price_range&limit=0&star&sortByPrice&cat`)
    .then(response => response)
    .catch(error => error)
}


export const onGetSingleDes = async (payload) => {

    return await axios.get(`${BASE_URL}/destinations/getSingleDestinations/${payload}`)
    .then(response => response)
    .catch(error => error)
}
