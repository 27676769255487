import { useState } from 'react';
import { onVote } from 'src/services/qaApi';
import "./DestinationDetail.css";

const QAVote = ({vote, id}) => {

    const [msg, setMsg] = useState("");
    const [successV, setSuccessV] = useState(false);

    const upVoting = () => {

        onVote(id, {upvote: true, downvote: false}).then((res) => {

            if(res.status === 200 || res.status === 201){
              setMsg(res.data.message)
              setSuccessV(true)
              window.location.reload()
            }
        })
    }


    const downVoting = () => {

        onVote(id, {upvote: false, downvote: true}).then((res) => {

            if(res.status === 200 || res.status === 201){
              setMsg(res.data.message)
              setSuccessV(true)
              window.location.reload()
              
            }
        })
    }

  return (
    <div className="qaVote">
      {successV && <div className='qaSuccMsg'><p style={{color:"green"}}>{msg}</p></div>}
      <i
        className="fa-solid fa-caret-up"
        onClick={upVoting}
      ></i>
      <span>{vote}</span>
      <p style={{ fontSize: "13px", marginBottom: "0px" }}>votes</p>
      <i
        className="fa-solid fa-caret-down"
        onClick={downVoting}
      ></i>
    </div>
  );
};

export default QAVote;
