import "react-app-polyfill/stable";
import "core-js";
import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { Suspense } from "react";
import "./i18n/config";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { store } from "./redux/store";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Suspense fallback="...">
    <React.StrictMode>
      <Router>
        <Provider store={store}>
          {/* <Provider store={store}> */}
          <App />
          {/* </Provider> */}
        </Provider>
      </Router>
    </React.StrictMode>
  </Suspense>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
