import { useEffect, useState } from "react";
import axios from "axios";
import "./Footer.css";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import TopPlaces from "./TopPlaces";

const Footer = () => {
  const [pages, setPages] = useState([]);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_BASE_URL}/cms/getAllCms`).then((res) => {
      setPages(res.data.results);
    });
  }, []);

  const location = useLocation();
  const path = location.pathname.split("/");
  if (path[1] === "admin") {
    return <></>;
  }
  if (path[1] === "supplier") {
    return <></>;
  } else {
    return (
      <div className="user-footer">
        <div className="strechContainer">
          <div className="strechSection">
            <div className="footerContainer">
              <div className="first">
                <h1>Petra Booking</h1>
                <span>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been...
                </span>
                <p>
                  <i className="fa-solid fa-location-dot"></i>A-32, Albany,
                  Newyork.
                </p>
                <p>
                  <i className="fa-solid fa-phone"></i>518 - 457 - 5181
                </p>
                <p>
                  <i className="fa-solid fa-envelope"></i>contact@gmail.Com
                </p>
              </div>
              <div className="second">
                <h5>About</h5>
                {pages.map((page) => {
                  return (
                    <div key={page._id} style={{ width: "40%" }}>
                      <Link to={`/${page.slug}`}>
                        <li>{page.title}</li>
                      </Link>
                    </div>
                  );
                })}
              </div>

              <div className="topPlaces">
                <h5 style={{ fontSize: "18px" }}>New Destinations</h5>
                <div className="images">
                  <div>
                    <TopPlaces />
                  </div>
                </div>
              </div>
            </div>

            <div className="copyContainer">
              <div className="accounts">
                <i className="fa-brands fa fa-facebook"></i>
                <i className="fa-brands  fa fa-instagram"></i>
                <i className="fa-brands  fa fa-twitter"></i>
                <i className="fa-brands fa-google"></i>
              </div>
              <div className="copyright">
                <h5>Copyright 2022 By Petra Booking</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default Footer;
