import {useEffect} from "react";
import CustomRoutes from "./Routes/CustomRoutes";

function App() {

  useEffect(() => {

    const script = document.createElement('script');
  
    script.src = "https://www.paypal.com/sdk/js?client-id=ARjIQicHI3v8OYLZ-qCDa9sxWXDx6ehRB7YdHj8RboFdFZH53DPO6C8RFL40V1aWIoJoYbsvYu1DyADF&currency=CAD";
    script.async = true;
  
    document.body.appendChild(script);
  },[])


  return (
    <>
    <CustomRoutes/>
    </>
  );
}

export default App;
