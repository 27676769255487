import { setTimeout } from 'core-js';
import { useState } from 'react';
import { onRemoveGift } from 'src/services/cartApi';
import './Cart.css';

const CartGifts = ({image, title, description, price, id}) => {

    const [successMsg, setSuccessMsg] = useState(false);
    const [msg, setMsg] = useState("");


    const removeGift = () => {

        onRemoveGift(id).then((res) => {
            if(res.status === 200){
                setSuccessMsg(true)
                setMsg(res.data.message)

                setTimeout(() => {
                    window.location.href = "/cart"
                },2000)
            }
        })
    }

    return (
        <div className='cartGifts'>
            {successMsg && <div className="removeCartMsg" ><h6 >{msg}</h6></div>}
            <img src={`${process.env.REACT_APP_BASE_URL}${image}`} alt='' />
            <div className='giftInfo'>
                <h5>{title}</h5>
                <p>{description}</p>

                <div className='giftPrice'>
                <span>₹{price}</span>
                <button onClick={removeGift}>delete</button>
                </div>
            </div>
        </div>
    )
}

export default CartGifts;