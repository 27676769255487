import './DestinationDetail.css';
import axios from 'axios';
import {useEffect, useState} from 'react';
import Suggestion from './Suggestion';
import Carousel from 'react-elastic-carousel';

const MoreDest = () => {

    const [destinations, setDestinations] = useState([]);
    const breakPoints = [
        { width: 1, itemsToShow: 1 },
        { width: 550, itemsToShow: 2, itemsToScroll: 2 },
        { width: 768, itemsToShow: 2 },
        { width: 1200, itemsToShow: 3 }
      ];

    const shuffle = arr => [...arr].sort(() => Math.random() - 0.5);  
    
    const newSuggestion = shuffle(destinations);

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_BASE_URL}/destinations/getAllDestinations`).then((res) => {
            if(!res.error){
                setDestinations(res.data.results)
            }
        })

    },[])


    return (
        <div className='moredest'>
        <Carousel breakPoints={breakPoints} >
         {newSuggestion.slice(0,5).map((des) => (
            <Suggestion key={des._id} slug={des.slug} image={des.image} title={des.title} days={des.tour_in_days} nights={des.tour_in_nights} id={des._id}/>
         ))      
         }
        </Carousel>
        </div>
    )
}

export default MoreDest;