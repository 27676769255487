import {CProgress, CProgressBar} from '@coreui/react';
import './DestinationDetail.css';

const ReviewSummary = ({reviews}) => {

    return (
        <div className='reviewSummary'>
            <h6>Review summary</h6>
            <div className='progressBar'>
                <span>Value for money</span>
                <CProgress className="mb-3">
                    {reviews?.length ? <CProgressBar color="warning" value={75}/>
                    : <CProgressBar color="warning" value={0}/>}
                </CProgress>
            </div>
            <div className='progressBar'>
                <span>Service</span>
                <CProgress className="mb-3">
                {reviews?.length ? <CProgressBar color="warning" value={65}/>
                    : <CProgressBar color="warning" value={0}/>}
                </CProgress>
            </div>
            <div className='progressBar'>
                <span>Orgonization</span>
                <CProgress className="mb-3">
                {reviews?.length ? <CProgressBar color="warning" value={85}/>
                    : <CProgressBar color="warning" value={0}/>}
                </CProgress>
            </div>
        </div>
    )
}

export default ReviewSummary;