import "./DestinationDetail.css";
import Rating from '@mui/material/Rating'

const Review = ({createdAt, ratingBy, comment,stars}) => {

  const reviewDate = new Date(createdAt?.slice(0,10)).toLocaleString('en-us',{month:'short', day:'numeric', year:'numeric'})


  return (
    
    <div className="review">
      <div className="rating">
        <div className='stars'>
          { stars === 5 ? (
            <>
                <Rating name="read-only" value={5}  readOnly />
            </>
          ) :
          stars === 4 ? ( 
        <>
          <Rating name="read-only" value={4}  readOnly />
        </>  
            ) : stars === 3 ? (
            <>
                <Rating name="read-only" value={3}  readOnly />
            </>
            ) : stars === 2 ? (
                <>
                    <Rating name="read-only" value={2}  readOnly />
                </> 
            ) : stars === 1 ? (
                <>
                    <Rating name="read-only" value={1}  readOnly />
                </>    
            ) : (<></>)}
        </div>
        <h6 className="createdAt">{reviewDate}</h6>
      </div>
      <div className="reviewBy">{ratingBy}</div>
      <div className="comment">
        <p>
          {comment}
        </p>
      </div>
      <div className="userComment">
          <span>P</span>
          <div className="userFrom">
              <p>Petra Booking travler</p>
              <p>Verified booking</p>
          </div>
          <div className='helpful'>Helpful? <p>Yes</p></div>
      </div>
    </div>
  );
};

export default Review;
