import {useState} from 'react';
import Modal from "@mui/material/Modal";
import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from 'react-image-gallery';
import './DestinationDetail.css';

const Image = ({image, image0, number}) => {

    const [Img, setImg] = useState(false);
    const ImgOpen = () => setImg(true);
    const ImgClose = () => setImg(false);

    return (
        <>
        <Modal
        open={Img}
        onClose={ImgClose}
      >
        <>    
       <div className="allImgModal">
        <ImageGallery items={image} startIndex={number}/>
          <i style={{color:"#fff"}} className="fa-solid fa-xmark" onClick={ImgClose}></i>
        </div>
        </>
      </Modal>
        <img src={`${process.env.REACT_APP_BASE_URL}${image0}`} alt='' onClick={ImgOpen}/>
           
        </>
    )
}

export default Image;