import { useState } from "react";
import Modal from '@mui/material/Modal';
import { Button } from 'antd';
import { useForm } from "react-hook-form";
import { onDeleteAnswer, onEditAnswer } from "src/services/qaApi";
import { useSelector } from 'react-redux';
 
import './DestinationDetail.css';


const Answer = ({qa, qId, ansId, answer, name, id}) => {

  

  const userId = useSelector((state) => state.detailReducer.user_Id);

  const [successAns, setSuccessAns] = useState(false);
  const [success, setSuccess] = useState(false);
  const [msg, setMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const [editAnswer, setEditAnswer] = useState(answer);

  const { register, handleSubmit, formState: { errors } } = useForm();


  const [isOpenAns, setIsOpenAns] = useState(false);

  const handleOpenAns = () => {
    setIsOpenAns(true);
  };

  const handleCloseAns = () => {
    setIsOpenAns(false);
  };


  const deleteAnswer = () => {

    onDeleteAnswer(qId,ansId).then((res) => {

      if(res.status === 200 || res.status === 201){

        setMsg(res.data?.message)
        setSuccessAns(true)
        window.location.reload()
      }
    })
  }


  const editAnswers = () => {

    setLoading(true)

    onEditAnswer(qId,ansId,{answer: editAnswer}).then((res) => {

      if(res.status === 200 || res.status === 201){
          setMsg(res.data?.message)
          setSuccess(true)
          window.location.reload()
      }else{
        setLoading(false)
      }
    })
  }


    return (
        <>
        <div style={{ width: "90%" }}>
            {successAns && <div className='qaSuccMsg'><p style={{color:"green"}}>{msg}</p></div>}
                  <p>{answer} <div className="qaEditAns">{id === userId && <span onClick={handleOpenAns}>Edit</span>} {id === userId && <i className='fa-solid fa-trash-can' style={{color:"red", cursor:"pointer"}} onClick={deleteAnswer}></i>}</div>  </p> 
                  <p>
                    By {name} on{" "}
                    {new Date(qa.createdAt?.slice(0, 10)).toLocaleString(
                      "en-us",
                      { month: "short", day: "numeric", year: "numeric" }
                    )}
                  </p>
        </div>

        <Modal
        open={isOpenAns}
        onClose={handleCloseAns}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className='qaModal'>
            <div className='qaModalContent'>
                <div className='qaModalTitle'>
                    Edit Your Answer
                    <i className="fa-solid fa-xmark" onClick={handleCloseAns}></i>
                </div>

                <div className='qaInput'>
                <input type='text' placeholder='Answer...' value={editAnswer}
                 {...register("ans", {required: "Answer is required"})}
                onChange={(e) => {
                    setEditAnswer(e.target.value)
                } }/>
                {success && <div className='qaMsg'><p>{msg}</p></div>}
                <p style={{color: "red"}}>{errors.ans?.message}</p>
                
                <Button type="primary" size="middle" onClick={handleSubmit(editAnswers)}  loading={loading} disabled={loading}>
                        Post
                </Button>
                </div>
            </div>    
        </div>
      </Modal>
        </>
    )
}


export default Answer;