import './DestinationsList.css';


const LatestFilter = () => {

    return (

        <div className='latestFilter'>
            <h5>Latest Filter</h5>
            <img
                src="https://themes.pixelstrap.com/rica/assets/images/icon/adjust.png"
                alt=""
                onClick={() => {
                    document.getElementById('sidebar').style.display = "block"
                }}/>
        </div>
    )
}

export default LatestFilter;