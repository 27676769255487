import { useState, useEffect } from "react";
import axios from "axios";
import { onAddGiftToCart } from "src/services/cartApi";
import { useForm } from "react-hook-form";
import "./GiftCard.css";
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
  CAlert,
  CFormSelect,
  CFormTextarea,
} from "@coreui/react";
import { CSpinner } from "@coreui/react";
import { useParams, useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";

import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import CloseIcon from "@mui/icons-material/Close";
import { setTimeout } from "core-js";

const GiftCard = () => {
  const { id } = useParams();
  const [open, setOpen] = useState(false);
  const [serverMsg, setServerMsg] = useState();
  const [isServerError, setIsServerError] = useState(false);
  const [gift, setGift] = useState("");
  const [giftAmount, setGiftAmount] = useState("");
  const [radioValue, setRadioValue] = useState(true);
  const [successMsg, setSuccessMsg] = useState(false);
  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState("");
  const [values, setValues] = useState({
    amount: "",
    message: "",
    destination_id: "",
    gift_amount_id: "",
  });

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/destinations/getSingleDestinations/${id}`
      )
      .then((response) => {
        setGift(response.data.results.destination_data);
        setValues({
          destination_id: response.data.results.destination_data._id,
          amount: response.data.results.destination_data.price * 2,
        });
      })
      .catch((error) => error);

    axios
      .get(`${process.env.REACT_APP_BASE_URL}/gift/getAllGiftAmount`)
      .then((response) => {
        setGiftAmount(response.data);
      })
      .catch((error) => error);
  }, []);

  const handleChange = (event) => {
    const { name, value } = event.target;

    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleRadioChange = (event) => {
    if (event.target.value == "true") setRadioValue(true);
    else setRadioValue(false);
  };

  const Authaxios = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });

  const previewPdf = () => {
    setOpen(false);
    setIsServerError(false);
    let sendData = {
      message: values.message,
      is_suggested: radioValue,
    };
    console.log(sendData);
    if (!values.gift_amount_id) {
      setOpen(true);
      setIsServerError(true);
      setServerMsg("Select Gift Card Value.");
      return;
    }
    if (values.gift_amount_id == "1") {
      if (!values.amount) {
        setOpen(true);
        setIsServerError(true);
        setServerMsg("Select Gift Card Value.");
        return;
      }
      sendData.amount = values.amount;
    } else sendData.gift_amount_id = values.gift_amount_id;

    if (radioValue) sendData.destination_id = values.destination_id;

    Authaxios.patch(
      `${process.env.REACT_APP_BASE_URL}/gift/giftPreview`,
      sendData
    )
      .then((res) => {
        console.log(res);
        if (!res.error) {
          setOpen(true);
          setServerMsg(res.data.message);
          window.open(
            `${process.env.REACT_APP_BASE_URL + res.data.url}`,
            "_blank"
          );
        }
      })
      .catch((error) => {
        console.log(error);
        setOpen(true);
        setServerMsg(error.response.data.message);
        setIsServerError(true);
      });
  };
  
  const createGiftCard = () => {
    setLoading(true)
    onAddGiftToCart({amount: values.amount, message: values.message, is_suggested: radioValue, destination_id: values.destination_id, gift_amount_id: values.gift_amount_id}).then((res) => {
      if(res.status === 201){
        setSuccessMsg(true)
        setMsg("Gift Added to Cart Successfully")
        setLoading(false)
        setTimeout(() => {
          window.location.href = `/gift-card/${id}`
        },2000)
      }
      else{
        setLoading(false)
      }
    })
  };

  return (
    <div>
      {gift?.price ? (
        <div className="center">
          <div style={{ padding: "80px 0" }}>
            <div className="gift-header">
              <div className="gift-heading">Give a Petra Booking gift card</div>
              <div className="gift-sub-heading">
                Give the ones you love
                <br /> unforgettable travel experiences and
                <br /> make memories to last a lifetime.
              </div>
            </div>

            <div className="container">
              {serverMsg ? (
                <Box sx={{ width: "100%" }}>
                  <Collapse in={open}>
                    <Alert
                      variant="outlined"
                      severity={isServerError ? "error" : "success"}
                      action={
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          size="small"
                          onClick={() => {
                            setOpen(false);
                          }}
                        >
                          <CloseIcon fontSize="inherit" />
                        </IconButton>
                      }
                      sx={{ mb: 2 }}
                    >
                      {serverMsg ? serverMsg : ""}
                    </Alert>
                  </Collapse>
                </Box>
              ) : (
                ""
              )}

              <CRow className="mt-3">
                <CCol md={12}>
                  <CFormSelect
                    label="Choose the value of the gift card:"
                    name="gift_amount_id"
                    value={values.gift_amount_id}
                    onChange={handleChange}
                  >
                    <option value="0">Gift Amount..</option>
                    {giftAmount?.results?.map((item) => {
                      return (
                        <option key={item._id} value={item._id}>
                          {item.amount}
                        </option>
                      );
                    })}
                    <option value="1">Custom amount...</option>
                  </CFormSelect>
                </CCol>
              </CRow>

              {values.gift_amount_id == "1" ? (
                <CRow className="mt-3">
                  <CCol md={12}>
                    <CFormInput
                      name="amount"
                      value={values.amount}
                      onChange={handleChange}
                    />
                    (e.g. 2 Adults x {gift.price} for {gift.price * 2})
                  </CCol>
                </CRow>
              ) : (
                ""
              )}

              <CRow className="mt-3">
                <CCol md={12}>
                  <CFormTextarea
                    value={values.message}
                    onChange={handleChange}
                    name="message"
                    type="text"
                    id="message"
                    label="Add a personal message:"
                    required
                  />
                </CCol>
              </CRow>
              <div className="gift-image">
                <Card sx={{ display: "flex" }}>
                  <CardActionArea>
                    <CardMedia
                      component="img"
                      height="auto"
                      image={process.env.REACT_APP_BASE_URL + gift.image}
                      alt="green iguana"
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="div">
                        {gift.title}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {gift.short_description}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </div>

              <div className="gift-is-suggested">
                <FormLabel id="demo-row-radio-buttons-group-label">
                  Suggest this activity on the gift certificate.
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  value={radioValue}
                  onChange={handleRadioChange}
                  // defaultValue={true}
                >
                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="No"
                  />
                </RadioGroup>
              </div>

              <div className="gift-submit">
              {successMsg && <div className="giftSuccessMsg"><span>{msg}</span></div>}
                <Stack spacing={2} direction="row">
                  <Button variant="outlined" onClick={previewPdf}>
                    Preview Card
                  </Button>
                 
                  <Button variant="contained" size="medium" className="giftCartBtn" onClick={createGiftCard}>
                   {loading ? <CSpinner color="#fff"/> : <>Add to Cart</>}
                  </Button>
                </Stack>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default GiftCard;
