import './DestinationDetail.css';


const Meeting = ({meeting}) => {

    return (
        <>
        {meeting?.length ? <div className='expHighlights'>
            <h6>Meeting point</h6>

            <div className='expDetails'>
                <p>{meeting}</p>
            </div>
        </div> : <></>}
        </>
    )
}

export default Meeting;