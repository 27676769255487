import './DestinationDetail.css';
import ExpHighlights from './ExpHighlights';
import FullDescription from './FullDescription';
import ImportantInfo from './ImportantInfo';
import Includes from './Includes';
import Meeting from './Meeting';
import MeetingTiming from './MeetingTiming';


const Experience = ({highlights, description, meeting, meetingTime, important, desIncludes}) => {

    return (
        <div className='reuseableContainer'>    
            <ExpHighlights highlights={highlights}/>

            <FullDescription description={description}/>

            <Includes desIncludes={desIncludes}/>

            <Meeting meeting={meeting} />

            <MeetingTiming meetingTime={meetingTime}/>

            <ImportantInfo important={important}/>
        </div>
    )
}


export default Experience;