import axios from 'axios';

const BASE_URL = process.env.REACT_APP_BASE_URL;

const Authaxios = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
    }
})

export const onGetqa = async (payload,payload2) => {

    return await axios.get(`${BASE_URL}/destinationQA/search-qa/${payload}?searchTerm=${payload2}`)
    .then(response => response)
    .catch(error => error)
}


export const onPostQA = async (payload) => {

    return await Authaxios.post('/destinationQA/post-question',payload)
    .then(response => response)
    .catch(error => error)
}


export const onDeleteQuestion = async (payload) => {

    return await Authaxios.delete(`/destinationQA/delete-question/${payload}`)
    .then(response => response)
    .catch(error => error)
}


export const onUpdateQuestion = async (payload,payload2) => {

    return await Authaxios.patch(`/destinationQA/update-question/${payload}`,payload2)
    .then(response => response)
    .catch(error => error)
}


export const onPostAnswer = async (payload,payload2) => {

    return await Authaxios.patch(`/destinationQA/post-answer/${payload}`,payload2)
    .then(response => response)
    .catch(error => error)
}


export const onDeleteAnswer = async (payload,payload2) => {

    return await Authaxios.delete(`/destinationQA/delete-answer/${payload}/${payload2}`)
    .then(response => response)
    .catch(error => error)
}


export const onEditAnswer = async (payload,payload2,payload3) => {

    return await Authaxios.patch(`/destinationQA/update-answer/${payload}/${payload2}`,payload3)
    .then(response => response)
    .catch(error => error)
}


export const onVote = async (payload,payload2) => {

    return await Authaxios.patch(`/destinationQA/vote-question/${payload}`,payload2)
    .then(response => response)
    .catch(error => error)
}