import React from "react";
import Home from "../pages/Home";
import Login from "../components/Login/Login";
import Signup from "../components/Signup/Signup";
import Dynamic from "../pages/Dynamic/Dynamic";
import Header from "../components/UserHeader/Header";
import Footer from "../components/Footer/Footer";
import EmailVerify from "../components/EmailVerify/EmailVerify";
import UserDashboard from "../pages/UserDashboard/UserDashboard";
import { Route, Routes } from "react-router-dom";
import AuthRoute from "./AuthRoute";
import AuthSupplierRoute from "./AuthSupplierRoute";
import DestinationList from "../pages/DestinationsList/DestinationsList";
import DestinationDetail from "../pages/DestinationDetail/DestinationDetail";
import GiftCard from "../pages/GiftCard/GiftCard";
// import ChangePassword from "../pages/ChangePassword/ChangePassword";
// import EditProfile from "../pages/EditProfile/EditProfile";
import Profile from "../pages/Profile/Profile";
import ContactUs from "../pages/Contact/ContactUs";
import Wishlist from "../pages/Wishlist/Wishlist";
import WishlistDetails from "../pages/Wishlist/WishlistDetails";
import ForgotPassword from "src/pages/ForgotPassword/ForgotPassword";
import ResetPassword from "src/pages/ResetPassword/ResetPassword";
import Cart from "src/pages/Cart/Cart";
import SupplierRegister from "src/pages/Supplier/Register";
const DefaultLayout = React.lazy(() => import("../layout/DefaultLayout"));
const CustomRoutes = () => {
  return (
    <>
      <Header />

      <Routes>
        <Route
          exact
          path="admin/login"
          name="Login Page"
          element={<AuthRoute />}
        />
        <Route
          exact
          path="/supplier/login"
          name="Login Page"
          element={<AuthSupplierRoute />}
        />
        <Route path="/" element={<Home />} />
        <Route path="signup" element={<Signup />} />
        <Route path="login" element={<Login />} />
        <Route path="/page/:title" element={<Dynamic />} />
        <Route path="/:slug" element={<Dynamic />} />
        <Route path="/verify/:id" element={<EmailVerify />} />
        <Route path="/user/dashboard" element={<UserDashboard />} />
        <Route path="/page/:title" element={<Dynamic />} />
        <Route path="/admin/*" name="Notfound" element={<DefaultLayout />} />
        <Route path="/supplier/*" name="Notfound" element={<DefaultLayout />} />
        <Route path="/supplier/register" element={<SupplierRegister />} />
        <Route path="destinations" element={<DestinationList />} />
        <Route path="/destination/:slug" element={<DestinationDetail />} />
        <Route path="/gift-card/:id" element={<GiftCard />} />
        {/* <Route path="/change-password" element={<ChangePassword />} /> */}
        {/* <Route path="/edit-profile" element={<EditProfile />} /> */}
        <Route path="/profile" element={<Profile />} />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/wishlists" element={<Wishlist />} />
        <Route path="/wishlist/:id" element={<WishlistDetails />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset/:token" element={<ResetPassword />} />
        <Route path="/cart" element={<Cart />} />
      </Routes>
      <Footer />
    </>
  );
};

export default CustomRoutes;
