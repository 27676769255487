import {useState, useEffect} from 'react';
import { useParams} from 'react-router-dom';
import { useForm } from "react-hook-form";
import { CSpinner } from "@coreui/react";
import axios from 'axios';
import './ResetPassword.css';
import { setTimeout } from 'core-js';
import ErrorPage from 'src/components/ErrorPage/ErrorPage';

const ResetPassword = () => {

    const { register, handleSubmit, formState: { errors }} = useForm();
    const {token} = useParams();
    const [msg, setMsg] = useState("");
    const [errorMsg, setErrorMsg] = useState("");
    const [new_password, setNewPassword] = useState("");
    const [confirm_password, setConfirmPassword] = useState("");
    const [validUrl, setValidUrl] = useState("");
    const [pageError, setPageError] = useState("");
    const [loading, setLoading] = useState(false);
    const [pageLoading, setPageLoading] = useState(false);

    useEffect(() => {
        setPageLoading(true);
        const VerifyEmailUrl = async () => {
            try {
                const url = `${process.env.REACT_APP_BASE_URL}/reset/${token}`
                await axios.get(url);
                setValidUrl(true)
                setPageLoading(false)
                
            } catch (error) {
                setPageError(error.response.data.message);
                setValidUrl(false)
                setPageLoading(false)
            }
        }

        VerifyEmailUrl();

    },[token])


    const resetPassword = () => {
        setLoading(true)
        axios.post(`${process.env.REACT_APP_BASE_URL}/reset/${token}`,{new_password,confirm_password})
        .then((res) => {
            if(!res.error){
                document.getElementById("msg").style.display = "block"
                setMsg(res.data?.message)
                setLoading(false)
                setTimeout(() => {
                    document.getElementById("msg").style.display = "none"
                    window.location.href = "/"
                },3000)
            }
        }).catch((error) => {
            document.getElementById("errormsg").style.display = "block"
            setErrorMsg(error.response?.data?.message)
            setLoading(false)
            setTimeout(() => {
                document.getElementById("errormsg").style.display = "none"
            },3000)
        })
    }


    return (
        <>
        {pageLoading ? <div className="pageLoading"><CSpinner style={{color:"#ef3f3e", fontSize:"50px"}} /></div> : <>
        {!validUrl ? (<div className='pageError'>
            <ErrorPage msg={pageError} />

        </div>) :
        (<div className='resetPassword'>
            <img
            style={{ width: "100%", height: "400px" }}
            src="https://images.unsplash.com/photo-1497864149936-d3163f0c0f4b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=869&q=80"
            alt=""
        />

        <div className="loginbox">Reset Password</div>

        <div className='resetContain'>
            
        <h4>Reset Passoword</h4>

        <p id="msg" >{msg}</p>
        <p id="errormsg">{errorMsg}</p>

        <label>New Password</label>
        <input type="password" placeholder='New Password'

        {...register("newpassword", {
            required: "New Password is required",
            pattern: {
              value: /^\S*$/,
              message: "Invalid New Password"
            },
            minLength: {
              value: 8,
              message: "password must be 8 characters long",
            },
            maxLength: {
              value: 15,
              message: "password is too long it must be under 15 characters",
            },
          })}

         onChange={(e) => {
            setNewPassword(e.target.value)
         }}  
        />
        <p className="err">{errors.newpassword?.message}</p>

        <label>Confirm Password</label>
        <input type="password" placeholder="Confirm Password" 
            {...register("confirmpassword", {
              required: "Confirm Password is required",
              pattern: {
                value: /^\S*$/,
                message: "Invalid Confirm Password"
              },
              minLength: {
                value: 8,
                message: "password must be 8 characters long",
              },
              maxLength: {
                value: 15,
                message: "password is too long it must be under 15 characters",
              },
            })}

            onChange={(e) => {
                setConfirmPassword(e.target.value)
             }}
        />
        <p className="err">{errors.confirmpassword?.message}</p>

        <button  onClick={handleSubmit(resetPassword)}>{loading ? <><CSpinner color='primary' style={{width:"19px",height:"19px",background:"transparent"}} />Loading...</> : <>Reset Password</>}</button>

        </div>        
        </div>)}
        </>}
        </>
    )
}

export default ResetPassword;