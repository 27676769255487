import { useState } from 'react';
import { onPostAddToCart } from 'src/services/cartApi';
import { useNavigate } from 'react-router-dom';
import './DestinationDetail.css';
import { setTimeout } from 'core-js';

const AddToCart = ({id,persons, slug}) => {

    const [successMsg, setSuccessMsg] = useState(false);
    
    const navigate = useNavigate();

    const token = localStorage.getItem('token');

    const MoveToCart = () => {
           
       !token ? navigate('/login') : onPostAddToCart({booking_option_id:id, no_of_person: persons}).then((res) => {

            if(res.status === 201){
                setSuccessMsg(true)
                setTimeout(() => {
                    window.location.href = `/destination/${slug}`
                },2000)
            }
        })

    }

    
    return (
        <div className='addToCart'>
            {successMsg && <div className='successMsg'><h6>Added to Cart Successfully</h6></div>}
            <button onClick={MoveToCart}>Add To Cart</button>
        </div>
    )
}

export default AddToCart;