import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { setUserId } from "src/redux/destinationDetailSlice";
import "../../i18n/config";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { DownOutlined } from "@ant-design/icons";
import { Dropdown, Menu, Space } from "antd";
import i18next from "i18next";
import "./Header.css";
import { useLocation } from "react-router-dom";
import { onGetCart } from "src/services/cartApi";
import { setTimeout } from "core-js";

const Header = () => {
  const dispatch = useDispatch();

  const location = useLocation();
  const path = location.pathname.split("/");
  const { t } = useTranslation();
  const [username, setUsername] = useState(null);

  const [googleUser, setGoogleUser] = useState({});
  const [Loading, setLoading] = useState(true);

  const user = JSON.parse(localStorage.getItem("user"));

  const token = localStorage.getItem("token");

  const [cartItems, setCartItems] = useState(0);

  const Authaxios = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });

  useEffect(() => {
    const getUser = () => {
      Authaxios.get("/get-user")
        .then((res) => {
          setUsername(res.data?.user.first_name);
          dispatch(setUserId(res.data.user._id));
        })
        .catch((error) => {
          if (error.response?.data.message.includes("expired")) {
            localStorage.removeItem("token");
          }
        });

      setGoogleUser(user);

      setTimeout(() => {
        setLoading(false);
      }, 2000);
    };

    getUser();

    onGetCart().then((res) => {
      if (!res.error) {
        setCartItems(
          res.data?.results.destinations?.length +
            res.data?.result.gifts?.length
        );
      }
    });
  }, []);

  const logOut = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    window.location.href = "/";
  };

  const menu = (
    <Menu
      items={[
        {
          label: (
            <a rel="noopener noreferrer" href="/signup">
              Sign Up
            </a>
          ),
          key: "0",
        },
        {
          type: "divider",
        },
        {
          label: (
            <a rel="noopener noreferrer" href="/login">
              LogIn
            </a>
          ),
          key: "1",
        },
      ]}
    />
  );

  if (path[1] === "admin") {
    return <></>;
  }
  if (path[1] === "supplier") {
    return <></>;
  } else {
    return (
      <header style={{ position: "relative" }}>
        <div className="navbar">
          <div
            className="strechSection"
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              flexWrap: "wrap",
            }}
          >
            {/*logo*/}
            <div className="logo">
              <Link to="/">
                <h2>Petra Booking</h2>
              </Link>
            </div>

            <div className="hammenu " id="hammenu">
              <div className="forflex">
                <div className="navbar-content" id="navcontent">
                  <li>
                    <Link to="/wishlists">
                      <i className="fa-regular fa-heart"></i> Wishlist
                    </Link>
                  </li>

                  <li>
                    <Link to="/cart">
                      <i className="fa-solid fa-shopping-cart"></i>
                      Cart
                    </Link>
                  </li>

                  <li>
                    <Link to="/help">
                      <i className="fa-regular fa-circle-question"></i>
                      {t("help")}
                    </Link>
                  </li>
                </div>
              </div>
            </div>
            <span
              className="back"
              id="back"
              onClick={() => {
                document.getElementById("hammenu").style.display = "none";
                document.getElementById("back").style.display = "none";
                document.getElementById("ham").hidden = false;
              }}
            >
              BACK
            </span>

            <div className="selection">
              <i
                id="ham"
                style={{
                  color: "black",
                  cursor: "pointer",
                  fontSize: "25px",
                  marginRight: "5px",
                }}
                className="fa-solid fa-bars"
                onClick={() => {
                  document.getElementById("hammenu").style.display = "block";
                  document.getElementById("back").style.display = "block";
                  document.getElementById("ham").hidden = true;
                }}
              ></i>

              <select>
                <option value="USD">USD</option>
                <option value="EUR">EUR</option>
                <option value="INR">INR</option>
                <option value="AUD">AUD</option>
              </select>

              <select
                onClick={(e) => {
                  i18next.changeLanguage(e.target.value);
                }}
              >
                <option value="en">ENG</option>
                <option value="fr">FRE</option>
                <option value="es">SPA</option>
                <option value="zh">CHI</option>
                <option value="ar">ARA</option>
                <option value="id">INDO</option>
                <option value="ja">JAP</option>
                <option value="pt">PORT</option>
                <option value="ru">RUS</option>
                <option value="de">GER</option>
              </select>

              <div className="navbar-content home-header">
                <li>
                  <Link to="/wishlists">
                    <i className="fa-regular fa-heart"></i> {t("wishlist")}
                  </Link>
                </li>
                <li>
                  <Link to="/cart">
                    <i className="fa-solid fa-cart-shopping"></i> {t("cart")}
                  </Link>
                  <div
                    className="cartDot"
                    style={{ display: cartItems ? "flex" : "none" }}
                  >
                    <span>{cartItems}</span>
                  </div>
                </li>
                <li>
                  <i className="fa-regular fa-circle-question"></i> {t("help")}
                </li>
              </div>

              <div className="login home-login">
                {token ? (
                  <>
                    <div className="dropdown" style={{ float: "left" }}>
                      <button className="dropbtn">
                        <i className="fa-solid fa-user"></i>

                        {username === "" ? (
                          <></>
                        ) : (
                          username || googleUser?.first_name
                        )}
                        <i className="fa-solid fa-angle-down"></i>
                      </button>

                      <div className="dropdown-content" style={{ left: "0" }}>
                        {/* <div
                        className="logoutbutton changePassword"
                        onClick={() => {
                          window.location.href = "/change-password";
                        }}
                      >
                        Change Password
                      </div> */}
                        {/* <div
                        className="logoutbutton changePassword"
                        onClick={() => {
                          window.location.href = "/edit-profile";
                        }}
                      >
                        Edit Profile
                      </div> */}
                        <div
                          className="logoutbutton changePassword"
                          onClick={() => {
                            window.location.href = "/profile";
                          }}
                        >
                          Setting
                        </div>
                        <div className="logoutbutton" onClick={logOut}>
                          Log Out
                        </div>
                      </div>
                    </div>
                  </>
                ) : Loading ? (
                  <></>
                ) : (
                  <>
                    <Dropdown overlay={menu}>
                      <a onClick={(e) => e.preventDefault()}>
                        <Space>
                          <i className="fa-regular fa-user"></i> Accounts
                          <DownOutlined />
                        </Space>
                      </a>
                    </Dropdown>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </header>
    );
  }
};

export default Header;
