import './DestinationDetail.css';


const MeetingTiming = ({meetingTime}) => {

    return (
        <>
        {meetingTime?.length ? <div className='expHighlights'>
            <h6>Meeting coordinates</h6>

            <div className='expDetails'>
                <p>{meetingTime}</p>
            </div>
        </div> : <></>}
        </>
    )
}


export default MeetingTiming;