import './ErrorPage.css';

const ErrorPage = ({msg}) => {

    return (
    <div className='errorPage'>
    <img src="https://themes.pixelstrap.com/rica/assets/images/icon/failure-icon.png" alt='' />
     <h1>{msg}</h1>
     <a href="/"><button>Home Page</button></a>
     </div>
    )
}

export default ErrorPage;