import { useState } from 'react';
import Modal from '@mui/material/Modal';
import { Button } from 'antd';
import { useForm } from "react-hook-form";
import { onDeleteQuestion } from 'src/services/qaApi';
import { onUpdateQuestion } from 'src/services/qaApi';
import { onPostAnswer } from 'src/services/qaApi';
import { useNavigate } from 'react-router-dom';
import './DestinationDetail.css';


const Questions = ({question, id, qaUserId, userId}) => {

    

    const token = localStorage.getItem('token');

    const navigate = useNavigate();

    const [success, setSuccess] = useState(false);
    const [successD, setSuccessD] = useState(false);
    
    const [msg, setMsg] = useState("");
    const [editQuestion, setEditQuestion] = useState(question);
    const [answer, setAnswer] = useState("");
    const [loading, setLoading] = useState(false);

    const [isOpenQA, setIsOpenQA] = useState(false);
    const [isOpenA, setIsOpenA] = useState(false);

    const { register, handleSubmit, formState: { errors } } = useForm();
    
    const handleOpenQA = () => {
        setIsOpenQA(true);
      };
    
      const handleCloseQA = () => {
        setIsOpenQA(false);
      };
    
      
      const handleOpenA = () => {
        token ? setIsOpenA(true) : navigate('/login')
      };
    
      const handleCloseA = () => {
        setIsOpenA(false);
      };



    const removeQuestion = () => {

        onDeleteQuestion(id).then((res) => {

            if(res.status === 201){
                setMsg(res.data?.message)
                setSuccessD(true)
                window.location.reload()
            }
        } )
    }


    const postEditQuestion = () => {

        setLoading(true)
        onUpdateQuestion(id,{question: editQuestion}).then((res) => {

            if(res.status === 201 || res.status === 200){
                setMsg(res.data.message)
                setSuccess(true)
                window.location.reload()
            }else{
                setLoading(false)
            }
        })
    }

    const postAnswer = () => {
        setLoading(true)
        onPostAnswer(id, {answer}).then((res) => {

            if(res.status === 200 || res.status === 201){
                setMsg(res.data?.message)
                setSuccess(true)
                window.location.reload()
              
            }else{
                setLoading(false)
            }
        })
    }

   


    return (
        <>
        <div className='question'>
        {successD && <div className='qaSuccMsg'><p style={{color:"green"}}>{msg}</p></div>}
            <h6>Question:</h6>
            <p>{question} {userId !== qaUserId && <span style={{cursor:"pointer"}} onClick={handleOpenA}>Reply</span>} {userId === qaUserId && <div className='qaEditQue'><span onClick={handleOpenQA}>Edit</span> <i className='fa-solid fa-trash-can' style={{color:"red", cursor:"pointer"}} onClick={removeQuestion}></i></div>}</p>
                       
         </div>

         <Modal
        open={isOpenQA}
        onClose={handleCloseQA}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className='qaModal'>
            <div className='qaModalContent'>
                <div className='qaModalTitle'>
                    Edit Question
                    <i className="fa-solid fa-xmark" onClick={handleCloseQA}></i>
                </div>

                <div className='qaInput'>
                 
                <input type='text' placeholder='Question...' value={editQuestion}
                {...register("que", {required: "Question is required"})}
                onChange={(e) => {
                    setEditQuestion(e.target.value)
                }}/>
                {success && <div className='qaMsg'><p>{msg}</p></div>}
                <p style={{color: "red"}}>{errors.que?.message}</p>
               
                <Button type="primary" size="middle" onClick={postEditQuestion} loading={loading} disabled={loading}>
                        Edit
                </Button>
                </div>
            </div>    
        </div>
      </Modal>


       <Modal
        open={isOpenA}
        onClose={handleCloseA}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className='qaModal'>
            <div className='qaModalContent'>
                <div className='qaModalTitle'>
                    Give An Answer
                    <i className="fa-solid fa-xmark" onClick={handleCloseA}></i>
                </div>

                <div className='qaInput'>
                <input type='text' placeholder='Answer...' {...register("answer", {required: "Answer is required"})}
                 onChange={(e) => {
                    setAnswer(e.target.value)
                }}/>
                {success && <div className='qaMsg'><p>{msg}</p></div>}
                <p style={{color: "red"}}>{errors.answer?.message}</p>
                
              <Button type="primary" size="middle" onClick={postAnswer} loading={loading} disabled={loading}>
                        Post
                </Button>
                

               

                </div>
            </div>    
        </div>
      </Modal>  
        </>
    )
}

export default Questions;