import React from "react";
import { Navigate } from "react-router-dom";
import { getSupplierToken } from "../utils/localStorage";
import SupplierLogin from "../pages/Supplier/Login";

function AuthSupplierRoute() {
  const supplierToken = getSupplierToken();
  return supplierToken ? (
    <Navigate to="/supplier/dashboard" replace />
  ) : (
    <SupplierLogin />
  );
}

export default AuthSupplierRoute;
