import { useState, useEffect } from "react";
import axios from "axios";
import "./DestinationsList.css";

const CategoryFilter = ({ categoryToFilter }) => {
  const [categories, setCategories] = useState([]);
  const [categoryArray, setCategoryArray] = useState([]);

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/destinations/getAllDestinationCategory`
      )
      .then((res) => {
        if (!res.error) {
          setCategories(res.data.results);
        }
      });
  }, []);

  const categoryFilter = (e) => {
    const { value, checked } = e.target;

    if (checked) {
      setCategoryArray([...categoryArray, value]);
    } else if (!checked) {
      setCategoryArray((categoryId) =>
        categoryId.filter((val) => val !== value)
      );
    }
  };

  useEffect(() => {
    categoryToFilter(categoryArray);
  }, [categoryArray]);

  return (
    <div className="filterByRating">
      <div className="Title">
        <h6>Categories</h6>
        <i
          className="fa-solid fa-minus"
          id="minus5"
          onClick={() => {
            document.getElementById("Content5").style.display = "none";
            document.getElementById("minus5").style.display = "none";
            document.getElementById("plus5").style.display = "block";
          }}
        ></i>
        <i
          className="fa-solid fa-plus"
          id="plus5"
          onClick={() => {
            document.getElementById("Content5").style.display = "block";
            document.getElementById("minus5").style.display = "block";
            document.getElementById("plus5").style.display = "none";
          }}
        ></i>
      </div>
      <div className="Content" id="Content5">
        {categories.map((category) => {
          return (
            <div className="stars" key={category._id}>
              <input
                type="checkbox"
                value={category._id}
                className="form-check-input"
                id={category._id}
                onClick={categoryFilter}
              />
              <label className="form-check-label rating">
                <span className="ms-1">{category.title}</span>
              </label>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CategoryFilter;
