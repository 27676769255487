import { useState, useEffect } from "react";
import axios from "axios";
import "./DestinationsList.css";
import ReactPaginate from "react-paginate";
import DestinationsItem from "./DestinationItem";
import SideBar from "./SideBar";
import LatestFilter from "./LatestFilter";
import { setTimeout } from "core-js";

const AllDestinations = () => {
  const [search, setNewSearch] = useState("");
  const [pageNumber, setPageNumber] = useState(0);
  const [rating, setRating] = useState();
  const [priceRange, setPriceRange] = useState([0, 100000]);
  const [nights, setNights] = useState([]);
  const [filteredDestinations, setFilteredDestinations] = useState([]);
  const [sortBy, setSortBy] = useState("");
  const [categoryId, setCategoryId] = useState([]);

  const DestinationsPerPage = 8;
  const pagesVisited = pageNumber * DestinationsPerPage;

  const pageCount = Math.ceil(
    filteredDestinations.length / DestinationsPerPage
  );

  const newNights = nights.join(" - ");
  const RealPrice = priceRange.join(" - ");
  const categoryIds = categoryId.join("-");

  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  const searchDes = (value) => {
    setTimeout(() => {
      setNewSearch(value);
    },500)
  };

  const Destination = filteredDestinations.slice().reverse();

  const checkratingValue = (value) => {
    setRating(value);
  };

  const price = (value) => {
    setPriceRange(value);
  };

  const nightsToStay = (value) => {
    setNights(value);
  };

  const sortedBy = (value) => {
    setSortBy(value);
  };

  const sortByCategory = (value) => {
    setCategoryId(value);
  };



  useEffect(() => {
    axios
      .get(
        `${
          process.env.REACT_APP_BASE_URL
        }/destinations/getDestinationsBySearch?limit=0&duration=${[
          newNights,
        ]}&searchTerm=${search}&price_range=${[RealPrice]}&star=${[
          rating,
        ]}&sortByPrice=${sortBy}&cat=${categoryIds}`
      )
      .then((res) => {
        if (!res.error) {
          setFilteredDestinations(res.data.result);
        }
      });
  }, [newNights, search, RealPrice, rating, sortBy, categoryIds]);

  return (
    <>
      {/* <div className="searchBar">
        <div className="searchInput">
          <input
            type="text"
            placeholder="Search By Title Name"
            onChange={handleSearchChange}
          />
          <i className="fa-solid fa-magnifying-glass"></i>
        </div>
      </div> */}
      <LatestFilter />
      <div className="mainSection">
        <SideBar
          nightsToStay={nightsToStay}
          getPrice={price}
          ratingValue={checkratingValue}
          sortedBy={sortedBy}
          categoryId={sortByCategory}
          search={searchDes}
        />
        <div className="destinations">
          {!Destination.length ? (
            <div className="errorContainer">
              <h4 className="error">No Destinations Founds!</h4>
            </div>
          ) : (
            <div className="itemsContainer">
              {Destination.slice(
                pagesVisited,
                pagesVisited + DestinationsPerPage
              ).map((des) => {
                return (
                  <DestinationsItem
                    id={des._id}
                    image={des.image}
                    title={des.title}
                    description={des.short_description}
                    nights={des.tour_in_nights}
                    days={des.tour_in_days}
                    price={des.price}
                    offeredPrice={des.offer_price}
                    persons={des.tour_persons}
                    rating={des.star}
                    slug={des.slug}
                    category={des.destination_category_id.title}
                  />
                );
              })}
            </div>
          )}
        </div>
      </div>
      <div className="pagination">
        {Destination.length ? (
          <ReactPaginate
            previousLabel={"Previous"}
            nextLabel={"Next"}
            pageCount={pageCount}
            onPageChange={changePage}
            containerClassName={"paginationBttns"}
            previousLinkClassName={"previousBttn"}
            nextLinkClassName={"nextBttn"}
            disabledClassName={"paginationDisabled"}
            activeClassName={"paginationActive"}
          />
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default AllDestinations;
