import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    cartItems: [],
    giftItems: []
}

const cartSlice = createSlice({
    name:"cart",
    initialState,

    reducers: {

        getCart: (state, action) => {
            state.cartItems = action.payload
        },
        
        getGift: (state, action) => {
            state.giftItems = action.payload
        }
    }
})

export const { getCart, getGift } = cartSlice.actions;
export default cartSlice.reducer;