import React, { useState } from "react";
import "../login/login.css";
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
  CAlert,
  CSpinner,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { cilLockLocked, cilUser } from "@coreui/icons";
// import { useForm } from "react-hook-form";
import { onSupplierLogin } from "../../services/authApi";
import { useNavigate } from "react-router-dom";
import { setSupplierTokenInLocalStorage } from "../../utils/localStorage";
const Login = () => {
  // const {
  //   register,
  //   handleSubmit,
  //   formState: { errors },
  // } = useForm();
  const [errorMessages, setErrorMessages] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [isEmailInvalid, setIsEmailInvalid] = useState(false);
  const [isPassInvalid, setIsPassInvalid] = useState(false);
  let navigate = useNavigate();

  const handleOnChange = (e) => {
    setIsEmailInvalid(false);
      setIsPassInvalid(false);
    setErrorMessages("");
    const { name, value } = e.target;
    console.log(name, value);
    if (name === "email") {
      setEmail(value);
    } else if (name === "password") {
      setPassword(value);
    }
  };

  const handleOnClick = () => {
    setLoading(true);
    if (email === "") {
      setIsEmailInvalid(true);
      setLoading(false)
    }
    if(password === ""){
      setIsPassInvalid(true);
      setLoading(false)
    }
    else if(email && password) {
      onSupplierLogin({ email, password }).then((res) => {
        if (res.status === 200) {
          console.log("login success");
          console.log(res.data.token);
          setSupplierTokenInLocalStorage(res.data.token);

          setLoading(false);
          setIsEmailInvalid(false);
          setIsPassInvalid(false);
          // setEmail('')
          // setPassword('')
          navigate(`/supplier/dashboard`);
        } else {
          setErrorMessages(res.response.data.message);
          setLoading(false);
        }
      });
    }
  };
  return (
    <div className="bg-light min-vh-100 d-flex flex-row align-items-center login">
      <CContainer className="parentInputContainer">
        <CRow className="justify-content-center">
          <CCol className="ad-log centerContainer">
            <CCardGroup className="cardGroupContainer">
              <CCard className="p-4">
                {errorMessages && (
                  <CAlert color="danger">{errorMessages}</CAlert>
                )}

                <CCardBody>
                  <CForm className=" needs-validation adminLoginForm">
                    <h1>Login</h1>
                    <p className="text-medium-emphasis">
                      Sign In to your account
                    </p>
                    <CInputGroup className="mb-3 w-50 adminInputsConatiner">
                      <CInputGroupText>
                        <CIcon icon={cilUser} />
                      </CInputGroupText>
                      <CFormInput
                        placeholder="Email"
                        value={email}
                        name="email"
                        // {...register("email", {
                        //   required: "Email is required",
                        //   pattern: {
                        //     value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        //     message: "Invalid email address",
                        //   },
                        // })}
                        onChange={handleOnChange}
                        required
                      />
                    </CInputGroup>
                    {isEmailInvalid && <p className="validationErr">Email is required</p>}
                    {errorMessages?.includes("not") ? (
                      <p style={{ color: "red" }}>{errorMessages}</p>
                    ) : (
                      <></>
                    )}

                    <CInputGroup className="mb-4 w-50 adminInputsConatiner">
                      <CInputGroupText>
                        <CIcon icon={cilLockLocked} />
                      </CInputGroupText>
                      <CFormInput
                        type="password"
                        placeholder="Password"
                        name="password"
                        value={password}
                        // {...register("password", {
                        //   required: "Password is required",
                        // })}
                        onChange={handleOnChange}
                        required
                      />
                    </CInputGroup>
                    {isPassInvalid && <p className="validationErr">Password is required</p>}
                    {errorMessages?.includes("Password") ? (
                      <p style={{ color: "red" }}>{errorMessages}</p>
                    ) : (
                      <></>
                    )}
                    <CRow>
                      <CCol xs={6}>
                        <CButton
                          color="primary"
                          className="px-4"
                          onClick={handleOnClick}
                          disabled={loading}
                        >
                          {loading ? (
                            <CSpinner
                              color="white"
                              style={{ width: "19px", height: "19px" }}
                            />
                          ) : (
                            <>Login</>
                          )}
                        </CButton>
                      </CCol>
                    </CRow>
                  </CForm>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  );
};
export default Login;
