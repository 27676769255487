import AppleSignin from "react-apple-signin-auth";
import './Signup.css';

const AppleLogin = () => {

    return (
        <AppleSignin
        /** Auth options passed to AppleID.auth.init() */
        authOptions={{
          clientId: "com.pompah.app.client",
          scope: "email name",
          redirectURI: "https://pompahdev.inic.cloud/apple/callback",
          state: "",
          nonce: "nonce",
          usePopup: true,
          onSuccess: (response) => console.log(response),
          onError: (error) => console.log(error)
        }}
        uiType="dark"
        className="apple-auth-btn"
      />
    )
}

export default AppleLogin;