import {useState} from 'react';
import axios from 'axios';
import { useForm } from "react-hook-form";
import { CSpinner } from "@coreui/react";
import './Login.css'
import AppleLogin from '../Signup/AppleLogin';
import Facebook from '../Signup/FacebookLogin';
import { setTimeout } from 'core-js';
import GoogleLogin from '../Signup/GoogleLogin';



const Login = () => {

    const { register, handleSubmit, formState: { errors } } = useForm();
    const [email,setEmail] = useState("")
    const [password,setPassword] = useState("")
    const [msg, setMsg] = useState("");
    const [remember, setRemember] = useState(false);
    const [loading, setLoading] = useState(false);

    

    const onSubmit = async () => {
        setLoading(true)
        await axios.post(`${process.env.REACT_APP_BASE_URL}/login`,{is_remember:remember,email,password}).then((res) => 
        {
            if(!res.error){
                localStorage.setItem("token",res.data.token)
                window.location.href = "/"
            }
        }).catch((error) => {
            document.getElementById('msg').style.display = "block"
            setMsg(error.response.data.message)
            setLoading(false)

            setTimeout(() => {
                document.getElementById('msg').style.display = "none"
            },3000)
        })
    }
    

    const handleRemember = (e) => {
        const { checked } = e.target;
    
        if (checked) {
          setRemember(true);
        }
    
        else if(!checked){
          setRemember(false)
        }
      };



    return (
        <div >
        <img style={{width:"100%", height:"400px"}} src='https://images.unsplash.com/photo-1497864149936-d3163f0c0f4b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=869&q=80' alt='' />
        <div className='loginbox'>LOGIN</div>
        <div className='login'>
            
            <div className='main'>
            <div className='loginCenter'><p>Log In With</p></div>
            <div className='loginbtnContainer'>
            <GoogleLogin />    
           
            <Facebook />
            
            <AppleLogin />
                
                
            </div>
            <div className='orContainer'>
            <span className='or'>OR</span>
            </div>
            <div className='inputContainer'>
            <label>Email</label>
                <input type="text" placeholder="Enter email" autoComplete="off" {...register("email", {required: "Email is required",
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: "invalid email address"
              }})} onChange={(e) => {
                    setEmail(e.target.value)
                }}/>
                <p>{errors.email?.message}</p>
                <label>Password</label>
                <input type="password" placeholder="Password" onChange={(e) => {
                    setPassword(e.target.value)
                }}/>
                <p id="msg" style={{position:"absolute", top: msg.includes("Incorrect") ? "162px" : "80px"}}>{msg}</p>
                <div>

                <div className='forgotContainer'>
                <div>       
                <input type="checkbox" onClick={handleRemember}/>
                <label className='rememberLabel' >Remember Me</label>
                </div>
                <a href='/forgot-password' >Forgot password</a>
                </div>

                </div>
                
                {loading ? <button className='logbtn' style={{marginTop:"10px",marginLeft:"0px"}}  disabled><CSpinner color='primary' style={{width:"19px",height:"19px"}} />Loading...</button> :
                <button className='logbtn' style={{marginTop:"10px",marginLeft:"0px"}} onClick={handleSubmit(onSubmit)}>LOGIN</button>}
            </div>
            <div style={{display:"flex", flexDirection:"column"}}>
            <div className='orContainer'>    
            <span className='or'>OR</span>
            </div>
            <button className='createbtn' style={{marginLeft:"0px"}}  onClick={() => {
                    window.location.href = "/signup"
                }}>CREATE ACCOUNT</button>
            </div>
            </div>
        </div>
        </div>
    )
}

export default Login;