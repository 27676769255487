import { useState, useEffect } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import "./Wishlist.css";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import Grid from "@mui/material/Unstable_Grid2";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import LoadingButton from "@mui/lab/LoadingButton";
import CircleIcon from "@mui/icons-material/Circle";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #fff",
  borderRadius: "10px",
  boxShadow: 24,
  p: 2,
  textAlign: "center",
};

const Wishlist = () => {
  const [value, setTabValue] = useState("1");
  const [wishlist, setWishlists] = useState([]);
  const [open, setOpen] = useState(false);
  const [msg, setMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const [currDate, setCurrDate] = useState();
  const [values, setValues] = useState({
    name: "",
  });

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const Authaxios = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });

  useEffect(() => {
    const dd = new Date().toDateString();
    setCurrDate(new Date(dd).toISOString());

    Authaxios.get(`${process.env.REACT_APP_BASE_URL}/wishlist/getAllListByUser`)
      .then((response) => {
        setWishlists(response.data);
        console.log(response.data)
      })
      .catch((error) => error);
  }, []);

  const handleCreateList = () => {
    setLoading(true);

    Authaxios.post("/wishlist/createNewList", { name: values.name })
      .then((res) => {
        if (!res.error) {
          setLoading(false);
          setMsg("List Created Successfully")
          setValues({
            name: "",
          });
          window.location.reload()
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  return (
    <div>
      <img
        style={{ width: "100%", height: "400px" }}
        src="https://images.unsplash.com/photo-1497864149936-d3163f0c0f4b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=869&q=80"
        alt=""
      />
      <div className="loginbox wishlist">Wishlist</div>
      <div className="container  wishlist-2" style={{ padding: "100px 0" }}>
        <Grid container spacing={2} minHeight={100}>
          <Grid display="flex" justifyContent="center" alignItems="left">
            <h2>Your wishlist</h2>
          </Grid>
          <Grid xs display="flex" justifyContent="right" alignItems="right">
            <Button
              variant="contained"
              maxHeight={10}
              onClick={handleOpen}
              startIcon={<AddCircleOutlineIcon />}
              style={{
                backgroundColor: "#ef3f3e",
              }}
            >
              Create a new list
            </Button>
          </Grid>
        </Grid>
        <Box sx={{ width: "100%", typography: "body1" }}>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 0, borderColor: "divider" }} className="whis-tab">
              <TabList onChange={handleTabChange}>
                <Tab label="Upcoming" value="1" />
                <Tab label="Past" value="2" />
              </TabList>
            </Box>
            {wishlist?.results?.length ? (
              <>
                <TabPanel value="1">
                  <ImageList sx={{ width: 500, height: 450 }} style={{display:"flex"}}>
                    {wishlist?.results?.map((list, index) => {
                      return (
                        <>
                          {list.date >= currDate ? (
                            <div
                              onClick={() =>
                                window.open(`/wishlist/${list._id}`, "_self")
                              }
                              style={{width:"100%", marginTop:'15px'}}
                            >
                              <ImageListItem
                                className="card-cursor"
                                key={
                                  list.items[0]
                                    ? `${process.env.REACT_APP_BASE_URL}${list.items[0].destination_id?.image}`
                                    : "https://cdn.getyourguide.com/tf/assets/static/wishlists/wishlist-image-placeholder.svg"
                                }
                              >
                                <img
                                  src={
                                    list.items[0]
                                      ? `${process.env.REACT_APP_BASE_URL}${list.items[0].destination_id?.image}?w=248&fit=crop&auto=format`
                                      : "https://cdn.getyourguide.com/tf/assets/static/wishlists/wishlist-image-placeholder.svg"
                                  }
                                  srcSet={
                                    list.items[0]
                                      ? `${process.env.REACT_APP_BASE_URL}${list.items[0].destination_id?.image}?w=248&fit=crop&auto=format&dpr=2 2x`
                                      : "https://cdn.getyourguide.com/tf/assets/static/wishlists/wishlist-image-placeholder.svg"
                                  }
                                  alt={list.title}
                                  loading="lazy"
                                  style={{
                                    width: '100%',
                                    height: '170px'
                                  }}  
                                />
                                <ImageListItemBar
                                  title={list.list_name}
                                  subtitle={
                                    <span>
                                      {list.date
                                        ? new Date(list.date).toDateString()
                                        : ""}
                                      {} <CircleIcon /> {list.items.length}{" "}
                                      Activities
                                    </span>
                                  }
                                  position="below"
                                />
                              </ImageListItem>
                            </div>
                          ) : (
                            ""
                          )}
                        </>
                      );
                    })}
                  </ImageList>
                </TabPanel>

                <TabPanel value="2">
                  <ImageList sx={{ width: 500, height: 450 }}>
                    {wishlist?.results?.map((list, index) => {
                      return (
                        <>
                          {list.date < currDate ? (
                            <div
                              onClick={() =>
                                window.open(`/wishlist/${list._id}`, "_self")
                              }
                            >
                              <ImageListItem
                                className="card-cursor"
                                key={
                                  list.items[0]
                                    ? `${process.env.REACT_APP_BASE_URL}${list.items[0].destination_id?.image}`
                                    : "https://themes.pixelstrap.com/rica/assets/images/tour-bg.jpg"
                                }
                              >
                                <img
                                  src={
                                    list.items[0]
                                      ? `${process.env.REACT_APP_BASE_URL}${list.items[0].destination_id?.image}?w=248&fit=crop&auto=format`
                                      : "https://themes.pixelstrap.com/rica/assets/images/tour-bg.jpg"
                                  }
                                  srcSet={
                                    list.items[0]
                                      ? `${process.env.REACT_APP_BASE_URL}${list.items[0].destination_id?.image}?w=248&fit=crop&auto=format&dpr=2 2x`
                                      : "https://themes.pixelstrap.com/rica/assets/images/tour-bg.jpg"
                                  }
                                  alt={list.title}
                                  loading="lazy"
                                />
                                <ImageListItemBar
                                  title={list.list_name}
                                  subtitle={
                                    <span>
                                      {list.date
                                        ? new Date(list.date).toDateString()
                                        : ""}
                                      {} <CircleIcon /> {list.items.length}{" "}
                                      Activities
                                    </span>
                                  }
                                  position="below"
                                />
                              </ImageListItem>
                            </div>
                          ) : (
                            <h3>No wishlist found</h3>
                          )}
                        </>
                      );
                    })}
                  </ImageList>
                </TabPanel>
              </>
            ) : (
              <>
                <div className="bt-emty" style={{ padding: "50px" }}>
                  <h3>Your wishlist is empty</h3>
                  <h6>
                    Save activities to your wishlist by clicking on the heart
                    icon.
                  </h6>
                  <button
                    variant="contained"
                    onClick={(event) => (window.location.href = "/")}
                  >
                    Find things to do
                  </button>
                 
                </div>
              </>
            )}
          </TabContext>
        </Box>
        {wishlist != "" ? (
          ""
        ) : (
          <div style={{ textAlign: "center" }} className="error-text">
            Sync your wishlist across devices: Log in / Sign up
          </div>
        )}
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            alignItems="left"
          >
            Create a new List
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <Box
              sx={{
                width: 500,
                maxWidth: "100%",
              }}
            >
              <TextField
                fullWidth
                label="List name"
                id="name"
                value={values.name}
                name="name"
                onChange={handleChange}
              />
              {/* <Button
                fullWidth
                disabled={values.name ? false : true}
                sx={{ mt: 2 }}
                variant="contained"
                onClick={handleCreateList}
              >
                Done
              </Button> */}
              {msg && <div className="wishlistMsg"><p>{msg}</p></div>}
              <LoadingButton
                fullWidth
                disabled={values.name ? false : true}
                sx={{ mt: 2 }}
                variant="contained"
                onClick={handleCreateList}
                loading={loading}
                loadingIndicator="Loading…"
              >
                Done
              </LoadingButton>
            </Box>
          </Typography>
        </Box>
      </Modal>
    </div>
  );
};

export default Wishlist;
