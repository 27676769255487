import { useEffect, useState} from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { CSpinner } from "@coreui/react";
import BookDestination from "./BookDestination";
import "./DestinationDetail.css";
import Gallery from "./Gallery";
import HeadingTitles from "./HeadingTitles";
import MoreDest from "./MoreDest";
import NewsLetter from "./NewsLetter";
import Reviews from "./Reviews";
import Modal from "@mui/material/Modal";
import { setTimeout } from "core-js";
import Button from "@mui/material/Button";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import QA from "./QA";
import AboutThisDestination from "./AboutThisDestination";
import Experience from "./Experience";

const DestinationDetail = () => {

  const { slug } = useParams();
  const [destination, setDestination] = useState([]);
  const [destinationImages, setDestinationImages] = useState([]);
  const [Loading, setLoading] = useState(false);

  const [enquiry, setEnquiry] = useState(false);
  const handleEnquiry = () => setEnquiry(true);
  const enquiryClose = () => setEnquiry(false);

  

  useEffect(() => {
    const getDest = async () => {
      setLoading(true);
      window.scrollTo(0, 0);
      await axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/destinations/getDestinationsBySlug/${slug}`
        )
        .then((res) => {
          if (!res.error) {
            setDestination(res.data.results?.destination_data)
            setDestinationImages(res.data.results?.destination_image);
            setTimeout(() => {
              setLoading(false);
            }, 1000);
          }
        })
        .catch((error) => {
          setTimeout(() => {
            setLoading(false);
          }, 1000);
        });
    };

    getDest();
  }, []);


  return (
    <>
      {Loading ? (
        <div className="forLoading">
          <CSpinner style={{ color: "#ef3f3e" }} />
        </div>
      ) : (
        <div className="destinationDetail">
          <div className="strechContainer">
            <div className="strechSection">
              <div className="detailDesTitle">{destination.title}</div>

              <Gallery desImages={destinationImages} />
            </div>
          </div>
          <div className="strechContainer">
            <div className="mainDetails strechSection">
              <div className=" leftSection">
                <HeadingTitles />
                <h5 className="h5" id="aboutThis">
                  About This Destination
                </h5>
                <AboutThisDestination freeCancel={destination.free_cancellation} payLater={destination.is_pay_later}/>

                <h5 className="h5" id="experience">
                  Experience
                </h5>
                <Experience highlights={destination.highlights} description={destination.long_description} meeting={destination.meeting_points} meetingTime={destination.meeting_point_coordinates} important={destination.important_information} desIncludes={destination?.includes_in_destination}/>

                <h5 className="h5">Related Destinations</h5>
                <MoreDest />
              </div>
              <div className="rightSection">
                <div className="stickySection">
                  <div id="bookdestination">
                    <BookDestination
                      id={destination._id}
                      price={destination.price}
                      offerPrice={destination.offer_price}
                      image={destination.image}
                      title={destination.title}
                      slug={slug}
                    />
                  </div>
                  <NewsLetter />
                  <div className="gift-button">
                    <Button
                      startIcon={<CardGiftcardIcon />}
                      onClick={() => {
                        window.location.href = `/gift-card/${destination._id}`;
                      }}
                    >
                      Give this as a gift
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <QA id={destination._id} cId={destination?.destination_category_id}/>
          <Reviews id={destination._id} slug={slug} countryId={destination.country_id} categoryId={destination.destination_category_id?._id}/>

          <Modal open={enquiry} onClose={enquiryClose}>
            <div className="modalContainer">
              <div className="bookingModal reuseableContainer">
                <div className="headingContainer">
                  <h6>Quick Enquiry</h6>
                  <i className="fa-solid fa-xmark" onClick={enquiryClose}></i>
                </div>
                <input
                  className="detailInput"
                  type="text"
                  placeholder="Full Name"
                />
                <input
                  className="detailInput"
                  type="email"
                  placeholder="Enter Email"
                />
                <input
                  className="detailInput"
                  type="text"
                  placeholder="Phone Number"
                />
                <textarea
                  className="detailInput"
                  rows="3"
                  placeholder="Your Query"
                ></textarea>
                <button className="detailBtn">SUBMIT</button>
              </div>
            </div>
          </Modal>
          <div className="stickyModal">
            <a href="#bookdestination" style={{ color: "black" }}>
              <button className="bookingBtn">Book Now</button>
            </a>
            <button className="enquiryBtn" onClick={handleEnquiry}>
              Enquiry
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default DestinationDetail;
