import axios from "axios";

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const onGetAvailableOption = async (payload, payload2) => {
  return await axios
    .get(
      `${BASE_URL}/bookingOption/checkAvailability?destination_id=${payload}&available_date=${payload2}`
    )
    .then((response) => response)
    .catch((error) => error);
};
