import { useState } from 'react';
import axios from 'axios';
import { useForm } from "react-hook-form";
import { CSpinner } from "@coreui/react";
import './ForgotPassword.css';
import { setTimeout } from 'core-js';

const ForgotPassword = () => {

    const { register, handleSubmit, formState: { errors } } = useForm();
    const [email, setEmail] = useState("");
    const [msg, setMsg] = useState("");
    const [errorMsg, setErrorMsg] = useState("");
    const [loading, setLoading] = useState(false);

    const onSubmit = async () => {
        setLoading(true)
        await axios.post(`${process.env.REACT_APP_BASE_URL}/recover`,{email}).then((res) => 
        {
            if(!res.error){
              setMsg(res.data?.message)
              setLoading(false)
              setTimeout(() => {
                window.location.href = "/forgot-password"
              },4000)

            }
        }).catch((error) => {
            document.getElementById('errormsg').style.display = 'block'
            setErrorMsg(error.response?.data?.message)
            setLoading(false)
            setTimeout(() => {
                document.getElementById('errormsg').style.display = 'none'
            },3000)
        })
    }


    return (
        <div className='forgotPassword'>
            <img
            style={{ width: "100%", height: "400px" }}
            src="https://images.unsplash.com/photo-1497864149936-d3163f0c0f4b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=869&q=80"
            alt=""
        />
        <div className="loginbox">Forgot Password</div>
            <div className='forgotContain'>
                <h4>Forgot Password</h4>

                <label>Email</label>
                <input type="text" placeholder="Enter Your Email" autocomplete="off" {...register("email", {required: "Email is required",
                pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: "invalid email address"
                }})} onChange={(e) => {
                    setEmail(e.target.value)
                }}/>
                
                <p style={{color:"red"}}>{errors.email?.message}</p>
                <p id="msg" style={{color:"green"}}>{msg}</p>
                <p id="errormsg" style={{color:"red"}}>{errorMsg}</p>

                <button onClick={handleSubmit(onSubmit)}>{loading ? <><CSpinner color='primary' style={{width:"19px",height:"19px",background:"transparent"}} />Loading...</> : <>Forgot Password</>}</button>
            </div>
        </div>
    )
}

export default ForgotPassword;