import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  destinationDetail: [],
  user_Id: "" 
}

const detailSlice = createSlice({
    name:"detailPage",
    initialState,

    reducers:{ 
      desDetail: (state, action) => {
      state.DestinationDetail = action.payload
    },

    setUserId: (state, action) => {
      state.user_Id = action.payload
    }

  }

})

export const { desDetail,setUserId } = detailSlice.actions;
export default detailSlice.reducer;