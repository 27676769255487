import axios from 'axios';

const BASE_URL = process.env.REACT_APP_BASE_URL;

const Authaxios = axios.create({
    baseURL: "http://54.70.8.104:5000",
    headers : {
        Authorization: `Bearer ${localStorage.getItem('token')}`
    }
})

export const onGetReviews = async (payload, payload2, payload3, payload4) => {

    return await axios.get(`${BASE_URL}/destinationReview/getReviewByDestination/${payload}?offset=0&limit=0&sortBystar=${payload2}&searchTerm=${payload3}&star=${payload4}`)
    .then(response => response)
    .catch(error => error)
}

export const onAddReview = async (payload) => {

    return await Authaxios.post('/destinationReview/listReview', payload)
    .then(response => response)
    .catch(error => error)
}


export const onEditReview = async (payload,payload2) => {

    return await Authaxios.patch(`/destinationReview/updateReview/${payload}`,payload2)
    .then(response => response)
    .catch(error => error)
}