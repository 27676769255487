import FacebookLogin from 'react-facebook-login';

const Facebook = () => {

     
const responseFacebook = (response) => {
    console.log(response);
  }

    return (
        <FacebookLogin
    appId="3150909921836238"
    autoLoad={false}
    fields="name,email,picture"
    callback={responseFacebook} />
    )
}

export default Facebook;