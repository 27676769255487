import axios from "axios";

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const onAdminLogin = async (payload) => {
  return await axios
    .post(`${BASE_URL}/admin/login`, payload)
    .then((response) => response)
    .catch((error) => error);
};

export const onSupplierLogin = async (payload) => {
  return await axios
    .post(`${BASE_URL}/supplier/login`, payload)
    .then((response) => response)
    .catch((error) => error);
};
export const onLogout = async () => {
  // let tokenAdmin = JSON.parse(localStorage.getItem("tokenAdmin"));
  let data = {
    status: 200,
  };
  return data;
  // return await axios.post(`${BASE_URL}logout`, null, {
  //     headers: {
  //         'Authorization': `Bearer ${tokenAdmin}`
  //     }
  // })
  //     .then(response => response)
  //     .catch(error => error);
};
