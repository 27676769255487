import {useState, useEffect} from 'react';
import axios from "axios";
import WishlistModal from 'src/pages/Wishlist/WishlistModal';
import { Link, useNavigate } from 'react-router-dom';
import './DestinationsList.css';

const DestinationsItem = ({title,slug,nights,days,category,image,description,id, price, offeredPrice, persons, rating}) => {

  const navigate = useNavigate();
  const token = localStorage.getItem("token");

    const [readMoreDes, setReadMoreDes] = useState(false);
    const [openModal, setModalOpen] = useState(false);

    const [clicked, setClicked] = useState(false);

    const MoreDes = () => {
        setReadMoreDes(!readMoreDes)
    }

    const [wishlists, setWishlists] = useState([]);
    let present = false;

    let desId = 0;

   const handleModalOpen = () => {
      token ? setModalOpen(true) : navigate('/login')
  };

  const handleModalClose = (value) => setModalOpen(value);


  const Authaxios = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
});


useEffect(() => {

Authaxios.get(`/wishlist/getAllListByUser`)
  .then((res) => {
    setWishlists(res.data?.results);
    console.log(res.data)
  })
  .catch((error) => error);

},[])

wishlists.forEach((item) => {

    item?.items.forEach((des) => {
        if(des.destination_id?._id === id){
            present = true
            desId = des?._id
        }
    })
    
})

const removeDestination = () => {
    setClicked(true)
    Authaxios.delete(`/wishlist/removeDestinationFromList/${desId}`).then((res) => {

        if(!res.error){
            console.log(res.data)
        }

    }).catch((err) => {
        console.log(err)
    })
}



    return (
            <>
        
              <div className="item" key={id}>
                {present ? <i className={clicked ? "fa-regular fa-heart" : "fa-solid fa-heart"} style={{color: clicked ? "white" : "rgb(239, 63, 62)"}} onClick={clicked ? handleModalOpen : removeDestination}></i> : <i className="fa-regular fa-heart" onClick={handleModalOpen} ></i>}
               <Link to={`/destination/${slug}`}><img
                  src={`${process.env.REACT_APP_BASE_URL}${image}`}
                  alt= ""
                /></Link>
                <div className="content">
                  <Link to={`/destination/${slug}`}><h5>{title}</h5></Link>
                  <div className='ratingFlex'>
                    <div className='rating'>
                  { rating === 5 || rating === 5.1 || rating === 5.2 || rating ===  5.3 || rating ===  5.4 || rating ===  5.5  ? (
            <>
                <i className="fa-solid fa-star"></i>
                <i className="fa-solid fa-star"></i>
                <i className="fa-solid fa-star"></i>
                <i className="fa-solid fa-star"></i>
                <i className='fa-solid fa-star'></i>
            </>
          ) :
          rating === 4 || rating === 4.1 || rating === 4.2 || rating ===  4.3 || rating ===  4.4 || rating ===  4.5 ? ( 
        <>
          <i className="fa-solid fa-star"></i>
          <i className="fa-solid fa-star"></i>
          <i className="fa-solid fa-star"></i>
          <i className="fa-solid fa-star"></i>
          <i className='fa-regular fa-star'></i>
        </>  
            ) : rating === 3 || rating === 3.1 || rating === 3.2 || rating ===  3.3 || rating ===  3.4 || rating ===  3.5  ? (
            <>
                <i className="fa-solid fa-star"></i>
                <i className="fa-solid fa-star"></i>
                <i className="fa-solid fa-star"></i>
                <i className="fa-regular fa-star"></i>
                <i className='fa-regular fa-star'></i>
            </>
            ) : rating === 2 || rating === 2.1 || rating === 2.2 || rating ===  2.3 || rating ===  2.4 || rating ===  2.5 || rating ===  2.8  ? (
                <>
                    <i className="fa-solid fa-star"></i>
                    <i className="fa-solid fa-star"></i>
                    <i className="fa-regular fa-star"></i>
                    <i className="fa-regular fa-star"></i>
                    <i className='fa-regular fa-star'></i>
                </> 
            ) : rating === 1 || rating === 1.1 || rating === 1.2 || rating ===  1.3 || rating ===  1.4 || rating ===  1.5  ? (
                <>
                    <i className="fa-solid fa-star"></i>
                    <i className="fa-regular fa-star"></i>
                    <i className="fa-regular fa-star"></i>
                    <i className="fa-regular fa-star"></i>
                    <i className='fa-regular fa-star'></i>
                </>    
            ) : (<>
                    <i className="fa-regular fa-star"></i>
                    <i className="fa-regular fa-star"></i>
                    <i className='fa-regular fa-star'></i>
                    <i className="fa-regular fa-star"></i>
                    <i className="fa-regular fa-star"></i>     
            </>)}
            </div>
                  <div style={{display:"flex"}}>
                  <span> {`(${nights}N ${days}D)`} </span>
                  <span style={{marginLeft:"10px"}}>{category}</span>
                  </div>
                  </div>
                  <h6>

                  {!readMoreDes  ? description.slice(0,70) : description}
                    {description.length > 200 ? <span className='readmore' onClick={MoreDes}>{!readMoreDes ? `Read more ▼` : `Read less ▲`}</span> : <></>}

                </h6>
                 
                  <div className="price">
                    { offeredPrice === null ? (<h6>₹{price}</h6>) : (
                    <h6>
                      <del>{`₹${price}`}</del>₹{offeredPrice}
                    </h6>
                    )
                    }
                    <span>{`${persons} persons`}</span>
                  </div>
                </div>
              </div>

              <WishlistModal open={openModal} id={id} close={handleModalClose}/>
        </> 
    ) 
}

export default DestinationsItem;