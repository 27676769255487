import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    reviews: []
}

export const reviewSlice = createSlice({
    name: "review",
    initialState,
    reducers: {
        getReviews: (state,action) => {
            state.reviews = action.payload
        }
    }
})

export const { getReviews } = reviewSlice.actions;
export default reviewSlice.reducer;