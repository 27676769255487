import axios from 'axios';


const Authaxios= axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    headers : {
        Authorization: `Bearer ${localStorage.getItem('token')}`
    }
})


export const onGetCart = async () => {
    return await Authaxios.get("/cart/get-cart")
    .then(response => response)
    .catch(error => error)
}




export const onPostAddToCart = async (payload) => {

    return await Authaxios.post("/cart/add-to-cart",payload)
    .then(response => response)
    .catch(error => error)
}


export const onPostPersonsChange = async (payload) => {

    return await Authaxios.post("/cart/change-participant",payload)
    .then(response => response)
    .catch(error => error)
}


export const onPostDateChange = async (payload) => {

    return await Authaxios.post(`/cart/change-date`,payload)
    .then(response => response)
    .catch(error => error)
}


export const onDeleteRemoveFromCart = async (payload) => {

    return await Authaxios.delete(`/cart/remove-destination/${payload}`)
    .then(response => response)
    .catch(error => error)
}


export const onDeleteCart = async () => {

    return await Authaxios.delete('/cart/delete-cart')
    .then(response => response)
    .catch(error => error)
}


export const onApplyDiscount = async (payload) => {

    return await Authaxios.post("/cart/apply-discount",payload)
    .then(response => response)
    .catch(error => error)
}


export const onAddGiftToCart = async (payload) => {

    return await Authaxios.post('/cart/add-gift-to-cart',payload)
    .then(response => response)
    .catch(error => error)
}


export const onRemoveGift = async (payload) => {

    return await Authaxios.delete(`/cart/remove-gift/${payload}`)
    .then(response => response)
    .catch(error => error)
}