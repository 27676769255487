import { useState } from 'react';
import './DestinationDetail.css';


const ImportantInfo = ({important}) => {

    const [moreInfo, setMoreInfo] = useState(false);

    const showMoreInfo = () => {
        !moreInfo ? setMoreInfo(true) : setMoreInfo(false)
    }
   

    return (
        <>
        {important?.length ? <div className='expHighlights'>
            <h6>Important information</h6>

            <div className='expDetails'>
                <h6>Know before you go</h6>
                {important?.slice(0, moreInfo ? important?.length : 2)?.map((item) => {
                 return <ul>
                    <li>{item}</li>
                </ul>})}
                {important?.length > 2 ? <span onClick={showMoreInfo}>{!moreInfo ? `Show more ▼` : `Show less ▲`}</span> : <></>}
            </div>
        </div> : <></>}
        </>
    )
}

export default ImportantInfo;