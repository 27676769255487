import './DestinationDetail.css';


const Includes = ({desIncludes}) => {


    return (
        <>
        {desIncludes?.length ? <div  className='expHighlights'>
            <h6>Includes</h6>

            <div className='expDetails'>
                {desIncludes?.map((item) => {
                 return <div>
                    
                    <li style={{listStyle:"none", fontWeight:"600"}}><i className="fa-solid fa-check" style={{color:"green"}}></i> {item.value}</li>
                </div>})}
               
            </div>
        </div> : <></>}
        </>
    )
}


export default Includes;