import {useState} from 'react';
import axios from 'axios';
import { useForm } from "react-hook-form";
import '../../i18n/config';
import { useTranslation } from 'react-i18next';
import { CSpinner } from "@coreui/react";
import "./Subscribe.css";
import { setTimeout } from 'core-js';

const Subscribe = () => {

  const { t } = useTranslation();
  const { register, handleSubmit, formState: { errors } } = useForm();
  const [email, setEmail] = useState("");
  const [msg, setMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");  
  const [loading, setLoading] = useState(false);

  const NewsSubscribe = () => {
    
    setLoading(true)
      axios.post(`${process.env.REACT_APP_BASE_URL}/subscriber/listSubscriber`,{email}).then((res) => {
      
        if(!res.error){     
          document.getElementById("msg").style.display = "block"
          setMsg(res.data.message) 
          setLoading(false)
          setTimeout(() => {
              document.getElementById("msg").style.display = "none"            
          }, 4000);
        }
     }).catch((error) => {
      document.getElementById("errormsg").style.display = "block"
      setErrorMsg(error.response.data.message)
      setLoading(false)
      setTimeout(() => {
        document.getElementById("errormsg").style.display = "none"            
    }, 4000);
  })
  }

  return (
 
    <div className="subscribe strechSection">
      <div className="right">
        <h3 style={{ fontWeight: "bold" }}>{t("newschannel")}</h3>
        <h6
          style={{
            color: "rgba(168, 160, 160, 0.767)",
          }}
        >{t("subscribedes")}
        </h6>
      </div>
      <div style={{position:"relative"}}>
        <span className="line"></span>
      </div>
      <div className="left">
        <div>
          <input style={{border:"none"}} type="email" autoComplete="off" {...register("email", {required: "Email is required",
          pattern: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            message: "invalid email address"
          }})} placeholder={t("emailPlaceholder")} onChange={(e) => {
            setEmail(e.target.value)
          }} />

          <button style={{ borderRadius:"15px", padding:"5px", width:"130px"}} onClick={handleSubmit(NewsSubscribe)}>{loading ? <><CSpinner color='primary' style={{width:"19px",height:"19px", marginLeft:"40%",background:"transparent"}} /></> : <>{t("subscribe")}</>}</button>
          
          <p id='msg' style={{color :"green", fontSize:"15px", fontWeight:"normal"}}>{msg}</p>
          <p id='errormsg' style={{color: 'red', fontSize:"15px", fontWeight:"normal"}}>{errorMsg}</p>
          <p style={{color:"red",fontSize:"15px", fontWeight:"normal"}}>{errors.email?.message}</p>
        </div>
      </div>
      </div>
 
  );
};

export default Subscribe;
