import { useState, useEffect } from "react";
import axios from "axios";
import "./Wishlist.css";
import { useParams, useNavigate } from "react-router-dom";
import Subscribe from "../../components/Subscribe/Subscribe";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Unstable_Grid2";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import MoreVertIcon from "@mui/icons-material/MoreVert";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";

import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import Modal from "@mui/material/Modal";
import LoadingButton from "@mui/lab/LoadingButton";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #fff",
  borderRadius: "10px",
  boxShadow: 24,
  p: 2,
  textAlign: "center",
};

const Wishlist = () => {
  const [wishlist, setWishlists] = useState([]);
  const { id } = useParams();
  const [date, setDateValue] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [msg, setMsg] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [openModal, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currDate, setCurrDate] = useState();
  const open = Boolean(anchorEl);
  const [values, setValues] = useState({
    name: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleModalOpen = () => setModalOpen(true);
  const handleModalClose = () => setModalOpen(false);

  const Authaxios = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });

  useEffect(() => {
    Authaxios.get(
      `${process.env.REACT_APP_BASE_URL}/wishlist/getSingleListByUser/${id}`
    )
      .then((response) => {
        setWishlists(response.data);
        setValues({ name: response.data.results.list_name });
        setDateValue(
          response.data.results.date ? new Date(response.data.results.date) : ""
        );
      })
      .catch((error) => error);
  }, []);

  const handleDateChange = (newValue) => {
    setDateValue(newValue);
    Authaxios.patch(`/wishlist/addDate/${id}`, { date: newValue })
      .then((res) => {
        if (!res.error) {
          console.log(res);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleEditList = () => {
    setAnchorEl(null);
    setLoading(true);
    Authaxios.patch(`/wishlist/renameList/${id}`, { name: values.name })
      .then((res) => {
        if (!res.error) {
          setLoading(false);
          setMsg("List Name Updated Successfully")
          setValues({
            name: "",
          });
          window.location.reload()
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const deleteList = () => {
    setAnchorEl(null);
    Authaxios.delete(`/wishlist/removeList/${id}`)
      .then((res) => {
        if (!res.error) {
          
          setSuccessMsg("List Removed Successfully")
          window.location.href = "/wishlists"
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const deleteDestination = (id) => {
    
    Authaxios.delete(`/wishlist/removeDestinationFromList/${id}`).then((res) => {
      if(!res.error){
        setErrMsg("Destination Removed Successfully");
        window.location.reload()
        

      }
    }).catch((error) => {
      console.log(error);
    })
  }

  return (
    <>
      <div className="container" style={{ padding: "150px 0" }}>
        {successMsg && <div className="fixedMsg"><p >{successMsg}</p></div>}
        {errMsg && <div className="fixedMsg"><p>{errMsg}</p></div>}
        {wishlist?.results ? (
          <div>
            <Grid container spacing={6} minHeight={100}>
              <Grid display="flex" justifyContent="center" alignItems="left">
                <h4>
                  {" "}
                  <a href="/wishlists">
                    <ArrowBackIosIcon />
                  </a>{" "}
                  {wishlist.results.list_name}
                </h4>
              </Grid>
              <Grid xs display="flex" justifyContent="right" alignItems="right">
                {/* <Button
                  variant="contained"
                  maxHeight={10}
                  startIcon={<CalendarMonthIcon />}
                >
                  Add Date
                </Button> */}

                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <Stack spacing={3}>
                    <MobileDatePicker
                      label="Add/Change Date"
                      inputFormat="MM/dd/yyyy"
                      value={date}
                      onChange={handleDateChange}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </Stack>
                </LocalizationProvider>

                <MoreVertIcon
                  id="basic-button"
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                />

                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <MenuItem onClick={handleModalOpen}>Rename List</MenuItem>
                  <MenuItem onClick={deleteList}>Delete List</MenuItem>
                </Menu>
              </Grid>
            </Grid>
            <Box sx={{ width: "100%", typography: "body1", pt: 6 }} >
              {wishlist.results.items.length != 0 ? (
                <>
                  {wishlist?.results?.items?.map((list, index) => {
                    return (
                      <Card sx={{ display: "flex" }} key={list._id} style={{position:"relative", marginTop:"20px"}}>
                        <i className='fa-solid fa-trash' style={{
                                position: 'absolute',
                                right: '5%',
                                top: '45%',
                                color: 'red',
                                cursor: 'pointer'
                        }}
                        onClick={() => {
                          deleteDestination(list._id)
                        }}
                        ></i>
                        <Box sx={{ display: "flex", flexDirection: "row" }}>
                          <CardMedia
                            component="img"
                            sx={{ width: 151 }}
                            image={`${process.env.REACT_APP_BASE_URL}${list.destination_id?.image}`}
                            alt="Live from space album cover"
                          />
                          <CardContent sx={{ flex: "1 0 auto" }}>
                            <Typography component="div" variant="h5">
                              {list.destination_id?.title?.slice(0,15)}
                            </Typography>
                            <Typography
                              variant="subtitle1"
                              color="text.secondary"
                              component="div"
                            >
                              Duration: {list.destination_id?.tour_in_days}D /{" "}
                              {list.destination_id?.tour_in_nights}N
                            </Typography>
                            <Typography
                              variant="subtitle1"
                              color="text.secondary"
                              component="div"
                            >
                              Price: {list.destination_id?.price}
                            </Typography>
                          </CardContent>
                        </Box>
                      </Card>
                    );
                  })}
                </>
              ) : (
                <>
                  <div className="empty" style={{ padding: "50px" }}>
                    <h3>This list is empty</h3>
                    <h6>
                      Add activities by clicking the heart icon and then
                      selecting {wishlist.results?.list_name}.
                    </h6>
                    <button
                    className="emt-bt"
                      variant="contained"
                      onClick={(event) => (window.location.href = "/")}
                    >
                      Find things to do
                    </button>
                    <br />
                    <br />
                    <br />
                  </div>
                </>
              )}
            </Box>
          </div>
        ) : (
          <div>Loading</div>
        )}
      </div>
      <Subscribe />

      <Modal
        open={openModal}
        onClose={handleModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            alignItems="left"
          >
            Rename List
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <Box
              sx={{
                width: 500,
                maxWidth: "100%",
              }}
            >
              <TextField
                fullWidth
                label="List name"
                id="name"
                value={values.name}
                name="name"
                onChange={handleChange}
              />
              {msg && <div className="wishlistMsg"><p>{msg}</p></div>}
              <LoadingButton
                fullWidth
                disabled={values.name ? false : true}
                sx={{ mt: 2 }}
                variant="contained"
                onClick={handleEditList}
                loading={loading}
                loadingIndicator="Loading…"
              >
                Done
              </LoadingButton>
            </Box>
          </Typography>
        </Box>
      </Modal>
    </>
  );
};

export default Wishlist;
