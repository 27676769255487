import './DestinationDetail.css';

const ExpHighlights = ({highlights}) => {

    return (
        <>
        {highlights?.length ? <div className='expHighlights' id='experience'>
            <h6>Highlights</h6>
            <div className='expDetails'>
                {highlights?.map((item) => {
                 return <ul key={item}>
                    <li>{item}</li>
                </ul>})}
            </div>
        </div> : <></>}
        </>
    )
}


export default ExpHighlights;