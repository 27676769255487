import { useState, useEffect } from "react";
import axios from "axios";
import "../Profile/Profile.css";
import "./Register.css";
import {
  CAlert,
  CButton,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CFormLabel,
  CFormSelect,
  CRow,
  CFormTextarea,
  CFormCheck,
  CSpinner,
} from "@coreui/react";
// import { Tab, Tabs, TabList, TabPane } from "react-tabs";
import TabContext from "@mui/lab/TabContext";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import LockIcon from "@mui/icons-material/Lock";
import ApartmentIcon from "@mui/icons-material/Apartment";
import TextField from "@mui/material/TextField";
import LoadingButton from "@mui/lab/LoadingButton";
import { Box, Tab, Tabs, Typography } from "@mui/material";
import Button from "@mui/material/Button";

import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";

import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

import Stack from "@mui/material/Stack";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";

import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import CloseIcon from "@mui/icons-material/Close";
import { isError } from "lodash";

import {
  onGetAllCountry,
  onGetStateByCountry,
  onGetCityByState,
} from "src/services/countryApi";

import {
  onGetDestinations,
  onCreateDetinations,
  onGetDestinationsCategory,
} from "src/services/destinationApi";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  // border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const Register = () => {
  const [loading, setLoading] = useState(false);
  const [loadingPassword, setLoadingPassword] = useState(false);
  const [loadingAddress, setLoadingAddress] = useState(false);
  const [serverMsg, setServerMsg] = useState();
  const [isServerError, setIsServerError] = useState(false);

  const [country, setCountry] = useState([]);
  const [countryId, setCountryId] = useState([]);

  const [category, setCategory] = useState([]);
  const [categoryIds, setCategoryIds] = useState([]);

  const [offeredActivities, setOfferedActivities] = useState([]);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const reg = /^\S*$/

  const [values, setValues] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    mobile: "",
    company_name: "",
    city: "",
    country: "",
    state: "",
    address: "",
    pincode: "",
    website: "",
    contact_number: "",
  });

  const [tabIndex, setTabIndex] = useState(0);

  const handleTabChange = (event, newTabIndex) => {
    setTabIndex(newTabIndex);

  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setValues({
      ...values,
      [name]: value,
    });
 
  };

  

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showNewPassword: !values.showNewPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const Authaxios = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: {
      // Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });

  useEffect(() => {
    onGetAllCountry().then((res) => {
      if (res.status === 200) {
        let testOptions = [];
        res.data.results.forEach((element) => {
          testOptions.push({
            label: element.name,
            value: element._id,
          });
        });
        setCountry(testOptions);
      } else {
        console.log(res);
      }
    });

    onGetDestinationsCategory().then((res) => {
      if (res.status === 200) {
        let testOptions = [];
        res.data.results.forEach((element) => {
          if (element.is_active)
            testOptions.push({
              label: element.title,
              value: element._id,
              status: false,
            });
        });
        setCategory(testOptions);
      } else {
        console.log(res);
      }
    });
  }, []);

  const onCheckboxChange = (event) => {
    if (event.target.checked) category[event.target.value].status = true;
    else category[event.target.value].status = false;
  };

  // const handleChange = (event) => {
  //   setState({
  //     ...state,
  //     [event.target.name]: event.target.checked,
  //   });
  // };

  const handleSubmit = () => {
    setLoading(true);
    setIsServerError(false);

    let saveCategoryId = [];
    category.forEach((element) => {
      if (element.status) saveCategoryId.push(element.value);
    });

    const userData = {
      first_name: values.first_name,
      last_name: values.last_name,
      email: values.email,
      password: values.password,
      mobile: values.mobile,
      company_name: values.company_name,
      country: values.country,
      city: values.city,
      state: values.state,
      address: values.address,
      pincode: values.pincode,
      website: values.website,
      contact_number: values.contact_number,
      offered_activities: saveCategoryId,
    };

    Authaxios.post("/supplier/register", userData)
      .then((res) => {
        if (!res.error) {
          setLoading(false);
          setOpen(true);
          setServerMsg(res.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
        setOpen(true);
        setServerMsg(error.response.data.message);
        setIsServerError(true);
        setLoading(false);
      });
  };

  return (
    <>
      <div className="container profile-content supplier-content" style={{ padding: "150px 0" }}>
        {serverMsg ? (
          <Box sx={{ width: "100%" }}>
            <Collapse in={open}>
              <Alert
                variant="outlined"
                severity={isServerError ? "error" : "success"}
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setOpen(false);
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
                sx={{ mb: 2 }}
              >
                {serverMsg ? serverMsg : ""}
              </Alert>
            </Collapse>
          </Box>
        ) : (
          ""
        )}
        <Box>
          <Box>
            <Tabs value={tabIndex} onChange={handleTabChange} centered>
              <Tab label="General info" />
              <Tab label="Company Details" />
              <Tab label="Personal Details" />
            </Tabs>
          </Box>
          <Box
            sx={{ padding: 2 }}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            {tabIndex === 0 && (
              <Box
                sx={{
                  "& .MuiTextField-root": { width: "50ch" },
                }}
              >
                <div>
                  <div className="add-padding">
                    <div className="slect-text">What type of activities do you offer?</div>
                    <div className="slect-bt">
                      <Button variant="outlined" onClick={handleOpen}>
                        Select
                      </Button>
                    </div>
                  </div>
                  <div className="add-padding">
                    <div className="add-website">Add your website</div>
                    <div>
                      <TextField
                        required
                        value={values.website}
                        onChange={handleChange}
                        name="website"
                        type="text"
                        id=" website"
                        variant="filled"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              Website :{" "}
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="add-padding">
                  <Button variant="contained" disabled={!values.website || reg.test(values.website) === false} onClick={() => setTabIndex(1)}>
                    Next
                  </Button>
                </div>

                <Modal
                  aria-labelledby="transition-modal-title"
                  aria-describedby="transition-modal-description"
                  open={open}
                  onClose={handleClose}
                  closeAfterTransition
                  BackdropComponent={Backdrop}
                  BackdropProps={{
                    timeout: 500,
                  }}
                >
                  <Fade in={open}>
                    <Box sx={style}>
                      <Typography
                        id="transition-modal-title"
                        variant="h6"
                        component="h2"
                      >
                        What type of activities do you offer?
                      </Typography>
                      <Typography
                        id="transition-modal-description"
                        sx={{ mt: 2 }}
                      >
                        <FormGroup aria-label="position" row>
                          {category?.map((data, index) => {
                            return (
                              <FormControlLabel
                                control={
                                  <Checkbox defaultChecked={data.status} />
                                }
                                value={index}
                                label={data.label}
                                onChange={onCheckboxChange}
                              />
                            );
                          })}
                        </FormGroup>
                      </Typography>
                      <Typography className="add-padding">
                        <Button variant="contained" onClick={handleClose}>
                          Save
                        </Button>
                      </Typography>
                    </Box>
                  </Fade>
                </Modal>
              </Box>
            )}
            {tabIndex === 1 && (
              <Box
                sx={{
                  "& .MuiTextField-root": { width: "50ch" },
                }}
              >
                <Typography>
                  <div>
                    <div className="add-padding">
                      <div className="add-website">Company Name</div>
                      <div>
                        <TextField
                          required
                          value={values.company_name}
                          onChange={handleChange}
                          name="company_name"
                          type="text"
                          id=" company_name"
                          variant="filled"
                        />
                      </div>
                    </div>
                    <div className="add-padding">
                      <div className="add-website">Country</div>
                      <div>
                        <TextField
                          required
                          value={values.country}
                          onChange={handleChange}
                          name="country"
                          type="text"
                          id=" country"
                          variant="filled"
                        />
                      </div>
                    </div>
                    <div className="add-padding">
                      <div className="add-website">City</div>
                      <div>
                        <TextField
                          required
                          value={values.city}
                          onChange={handleChange}
                          name="city"
                          type="text"
                          id=" city"
                          variant="filled"
                        />
                      </div>
                    </div>
                    <div className="add-padding">
                      <div className="add-website">State</div>
                      <div>
                        <TextField
                          required
                          value={values.state}
                          onChange={handleChange}
                          name="state"
                          type="text"
                          id=" state"
                          variant="filled"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="add-padding">
                    <div className="add-website">Address</div>
                    <div>
                      <TextField
                        required
                        value={values.address}
                        onChange={handleChange}
                        name="address"
                        type="text"
                        id=" address"
                        variant="filled"
                      />
                    </div>
                  </div>
                  <div className="add-padding">
                    <div className="add-website">Pincode</div>
                    <div>
                      <TextField
                        required
                        value={values.pincode}
                        onChange={handleChange}
                        name="pincode"
                        type="number"
                        id=" pincode"
                        variant="filled"
                      />
                    </div>
                  </div>
                  <div className="add-padding">
                    <div className="add-website">Contact Number</div>
                    <div>
                      <TextField
                        required
                        value={values.contact_number}
                        onChange={handleChange}
                        name="contact_number"
                        type="number"
                        id=" contact_number"
                        variant="filled"
                      />
                    </div>
                  </div>

                  <div className="add-padding add">
                    <Button variant="contained" onClick={() => setTabIndex(0)}>
                      Back
                    </Button>
                    <Button variant="contained" disabled={!values.company_name || reg.test(values.company_name) === false || !values.country || reg.test(values.country) === false || !values.state || reg.test(values.state) === false || !values.city || reg.test(values.city) === false || !values.address || reg.test(values.address) === false || !values.pincode || !values.contact_number} onClick={() => setTabIndex(2)}>
                      Next
                    </Button>
                  </div>
                </Typography>
              </Box>
            )}
            {tabIndex === 2 && (
              <Box
                sx={{
                  "& .MuiTextField-root": { width: "50ch" },
                }}
              >
                <Typography>
                  <div>
                    <div className="add-padding">
                      <div className="add-website">First Name</div>
                      <div>
                        <TextField
                          required
                          value={values.first_name}
                          onChange={handleChange}
                          name="first_name"
                          type="text"
                          id=" first_name"
                          variant="filled"
                        />
                      </div>
                    </div>
                    <div className="add-padding">
                      <div className="add-website">Last Name</div>
                      <div>
                        <TextField
                          required
                          value={values.last_name}
                          onChange={handleChange}
                          name="last_name"
                          type="text"
                          id=" last_name"
                          variant="filled"
                        />
                      </div>
                    </div>
                    <div className="add-padding">
                      <div className="add-website">Email</div>
                      <div>
                        <TextField
                          required
                          value={values.email}
                          onChange={handleChange}
                          name="email"
                          type="email"
                          id=" email"
                          variant="filled"
                        />
                      </div>
                    </div>
                    <div className="add-padding">
                      <div className="add-website">Password</div>
                      <div>
                        <TextField
                          required
                          value={values.password}
                          onChange={handleChange}
                          name="password"
                          type="password"
                          id=" password"
                          variant="filled"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="add-padding">
                    <div className="add-website">Mobile</div>
                    <div>
                      <TextField
                        required
                        value={values.mobile}
                        onChange={handleChange}
                        name="mobile"
                        type="number"
                        id=" mobile"
                        variant="filled"
                      />
                    </div>
                  </div>

                  <div className="add-padding add">
                    <Button variant="contained" onClick={() => setTabIndex(1)}>
                      Back
                    </Button>
                    <Button variant="contained" disabled={!values.first_name || reg.test(values.first_name) === false || !values.last_name || reg.test(values.last_name) === false || !values.email || !values.mobile || !values.password || reg.test(values.password) === false} onClick={handleSubmit}>
                      Register
                    </Button>
                  </div>
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      </div>
    </>
  );
};

export default Register;
