import { useEffect, useState } from "react";
import axios from 'axios';

const TopPlaces = () => {

    const [destinations, setDestinations] = useState([]);

    useEffect(() => {

        axios.get(`${process.env.REACT_APP_BASE_URL}/destinations/getDestinationsBySearch?searchTerm&duration&price_range&limit=0&star&sortByPrice&cat`).then((res) => setDestinations(res.data.result))

    },[])

    const newOffers = destinations.slice().reverse();

    return (
        
        <>
        {newOffers.slice(0,6).map((des) => {
            return <a key={des._id} href={`/destination/${des.slug}`} ><img src={`${process.env.REACT_APP_BASE_URL}${des.image}`}
          alt=""
        /></a>})}
        </>
    )
}

export default TopPlaces;