import { useState, useEffect } from 'react';
import axios from 'axios';
import './DestinationsList.css';


const ServicesFilter = () => {

    const [services, setServices] = useState([]);

    useEffect(() => {

        axios.get(`${process.env.REACT_APP_BASE_URL}/destinations/getAllDestinationsServices`)
        .then((res) => {

            if(!res.error){
            setServices(res.data.results)
            }
        })

    },[])

    return (
        <div className="filterByRating">
        <div className="Title">
          <h6>Services</h6>
          <i
            className="fa-solid fa-minus"
            id="minus6"
            onClick={() => {
              document.getElementById("Content6").style.display = "none";
              document.getElementById("minus6").style.display = "none";
              document.getElementById("plus6").style.display = "block";
            }}
          ></i>
          <i
            className="fa-solid fa-plus"
            id="plus6"
            onClick={() => {
              document.getElementById("Content6").style.display = "block";
              document.getElementById("minus6").style.display = "block";
              document.getElementById("plus6").style.display = "none";
            }}
          ></i>
        </div>
        <div className="Content" id="Content6">

        {services.map((service) => {
        return <div className="stars" key={service._id}>
            <input
              type="checkbox"
              value="1"
              className="form-check-input"
              id="five"
              
            />
            <label className="form-check-label rating">
              <span className="ms-1">{service.service_name}</span>
            </label>
          </div>
        })}
        </div>
      </div>
    )
}

export default ServicesFilter;