import { useEffect } from 'react';
import axios from 'axios';
import jwt_decode from 'jwt-decode';
const GoogleLogin = () => {



    function handleCallbackResponse(response){
        if(!response.error){
        const user = jwt_decode(response.credential)
        const userInfo = {first_name: user.given_name, last_name: user.family_name ,email: user.email, token: response.credential}
        localStorage.setItem("user", JSON.stringify(userInfo))
        localStorage.setItem('token',userInfo.token)

        const Authaxios = axios.create({
            baseURL: process.env.REACT_APP_BASE_URL,
            headers : {
                Authorization: `Bearer ${response.credential}`
            }
        })

        Authaxios.post("/register-using-socialid", {first_name: userInfo.first_name, last_name: userInfo.last_name, email: userInfo.email}).then((res) => {
           if(!res.error){
             window.location.href = "/"
           }
        })
        }
    }

    useEffect(() => {
        window.google?.accounts.id.initialize({
            client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
            callback: handleCallbackResponse
        });
        window.google?.accounts.id.renderButton(
            document.getElementById('google-login'),
            {
                theme: "outline", size: "medium"
            }
        );
    }, [])
    return (
        <>
            <span id='google-login'></span>
        </>
    )
}

export default GoogleLogin;