import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { onGetCart } from "src/services/cartApi";
import { onDeleteCart } from "src/services/cartApi";
import { onApplyDiscount } from "src/services/cartApi";
import { CSpinner } from "@coreui/react";
import { getCart } from "src/redux/cartSlice";
import { getGift } from "src/redux/cartSlice";
import "./Cart.css";
import CartDestination from "./CartDestination";
import CheckOut from "./CheckOut";
import { setTimeout } from "core-js";
import CartGifts from "./CartGifts";

const Cart = () => {
  const dispatch = useDispatch();
  const cartItem = useSelector((state) => state.cartReducer.cartItems);
  const giftItem = useSelector((state) => state.cartReducer.giftItems);

  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState("");
  const [redeemCode, setRedeemCode] = useState("");
  const [afterDiscount, setAfterDiscount] = useState("");
  const [total, setTotal] = useState("");
  const [successMsg, setSuccessMsg] = useState(false);
  const [errorMsg, setErrorMsg] = useState(false);

  useEffect(() => {
    setLoading(true);
    onGetCart()
      .then((res) => {
        if (!res.error) {
          dispatch(getCart(res.data.results.destinations));
          dispatch(getGift(res.data.results.gifts));
          setTotal(res.data.result.total);
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  }, []);

  const deleteCart = () => {
    onDeleteCart().then((res) => {
      if (!res.error) {
        setSuccessMsg(true);
        setMsg(res.data.message);
        setTimeout(() => {
          window.location.href = "/cart";
        }, 2000);
      }
    });
  };

  const getDiscount = () => {
    onApplyDiscount({ redeemable_code: redeemCode }).then((res) => {
      if (res.status === 200) {
        setSuccessMsg(true);
        setAfterDiscount(res.data.result.payableAmount);
        setMsg(res.data.message);

        setTimeout(() => {
          setSuccessMsg(false);
        }, 3000);
      } else {
        setErrorMsg(true);
        setMsg(res.response.data.message);

        setTimeout(() => {
          setErrorMsg(false);
        }, 3000);
      }
    });
  };

  return (
    <div className="Cart">
      {successMsg && (
        <div className="removeCartMsg">
          <h6>{msg}</h6>
        </div>
      )}
      {errorMsg && (
        <div className="errorCartMsg">
          <h6>{msg}</h6>
        </div>
      )}
      <div className="strechContainer">
        <div className="strechSection">
          <div className="cartTitle cart-title">
            <h4>Shopping Cart</h4>
            {cartItem.length || giftItem.length ? (
              <span onClick={deleteCart}>Clear Cart</span>
            ) : (
              <></>
            )}
          </div>

          {loading ? (
            <div className="cartLoader">
              <CSpinner style={{ color: "#ef3f3e" }} />
            </div>
          ) : (
            <>
              {!cartItem?.length && !giftItem?.length ? (
                // <div className="cartError">
                //   <h4>Cart Is Empty</h4>
                // </div>
                <div className="emty-new">
                  <div className="cart-img">
                    <img
                      src={`${process.env.REACT_APP_BASE_URL}/assets/images/empty-cart.png`}
                      alt=""
                    />
                  </div>
                  <div className="cart-heading">Your cart is empty</div>
                  <div className="cart-subheading">
                    Looks like you have not added anything to your cart. Go
                    ahead and explore top categories.
                  </div>
                </div>
              ) : (
                <div className="cartContainer">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginBottom: "30px",
                    }}
                  >
                    {cartItem.map((item) => {
                      return (
                        <CartDestination
                          key={item.destination_id}
                          id={item.destination_id}
                          bId={item?.booking_option_id}
                          persons={item.no_of_person}
                          price={item.price}
                        />
                      );
                    })}

                    {giftItem.length ? (
                      <h4 className="giftsTitle">Gifts</h4>
                    ) : (
                      <></>
                    )}
                    {giftItem.map((item) => {
                      return (
                        <CartGifts
                          key={item._id}
                          image={item.image}
                          title={item.title}
                          description={item.short_description}
                          price={item.amount}
                          id={item._id}
                        />
                      );
                    })}

                    <div
                      className="cartDiscount"
                      id="cartDis"
                      onClick={() => {
                        document.getElementById("cartDis").style.display =
                          "none";
                        document.getElementById("redeemContain").style.display =
                          "block";
                      }}
                    >
                      Get Discount
                    </div>

                    <div className="redeemContainer" id="redeemContain">
                      <label>Enter redeem code</label>

                      <div>
                        <input
                          type="text"
                          onChange={(e) => {
                            setRedeemCode(e.target.value);
                          }}
                        />

                        <button onClick={getDiscount}>Redeem</button>
                      </div>
                    </div>
                  </div>

                  <CheckOut
                    des={cartItem}
                    gif={giftItem}
                    discount={afterDiscount}
                    amount={total}
                  />
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Cart;
