import { configureStore } from '@reduxjs/toolkit'
import tableDataReducer from './tableDataSlice'
import globalReducer from './globalSlice'
import webSocketGameReducer from './webSocketGameSlice'
import { adminApiSlice } from '../servicesRtkQuery/adminApi'
import detailReducer from './destinationDetailSlice'
import reviewReducer from './reviewsSlice'
import cartReducer from './cartSlice'

export const store = configureStore({
    reducer: {
        tableDataReducer,
        globalReducer,
        detailReducer,
        reviewReducer,
        cartReducer,
        webSocketGameReducer,
        [adminApiSlice.reducerPath]: adminApiSlice.reducer,
    },
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware().concat(adminApiSlice.middleware)
})
