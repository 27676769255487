import { useState, useEffect } from "react";
import "antd/dist/antd.min.css";
import axios from "axios";
import { Slider, Select } from "antd";
import "./DestinationsList.css";
import ServicesFilter from "./ServicesFilter";
import CategoryFilter from "./CategoryFilter";
import { setTimeout } from "core-js";

const SideBar = ({
  ratingValue,
  getPrice,
  nightsToStay,
  sortedBy,
  categoryId,
  search
}) => {
  const [boxValue, setboxValue] = useState();
  const [nightArray, setNightArray] = useState([]);
  const [value, setValue] = useState([0, 100000]);
  const [sortBy, setSortBy] = useState("");
  const [Search,setSearch] = useState("")
  const [categoryid, setCategoryid] = useState([]);
  const [destinations, setDestinations] = useState([]);

  const { Option } = Select;

  const priceChange = (value) => {
    setValue(value);
  };

  const handleChange = (e) => {
    const { value } = e.target;
    setboxValue(value);
  };

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const sortChange = (e) => {
    setSortBy(e);
  };


  const durationFilter = (e) => {
    const { value, checked } = e.target;

    if (checked) {
      setNightArray([...nightArray, value]);
    } else if (!checked) {
      setNightArray((night) => night.filter((val) => val !== value));
    }
  };

  const categoryToFilter = (value) => {
    setCategoryid(value);
  };

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/destinations/getDestinationsBySearch?searchTerm&duration&price_range&limit=0&star&sortByPrice&cat`
      )
      .then((res) => {
        if (!res.error) {
          setDestinations(res.data.result);
        }
      });
  }, []);

  useEffect(() => {
    nightsToStay(nightArray);
    getPrice(value);
    ratingValue(boxValue); 
    sortedBy(sortBy);
    categoryId(categoryid);
    search(Search)
  }, [nightArray, value, boxValue, sortBy, Search, categoryid]);

  return (
    <div className="sidebar" id="sidebar">
      <div
        className="back2"
        onClick={() => {
          document.getElementById("sidebar").style.display = "none";
        }}
      >
        Back
      </div>

      <div className="searchBar">
        <div className="searchInput">
          <input
            type="text"
            placeholder="Search By Title Name"
            onChange={handleSearchChange}
          />
          <i className="fa-solid fa-magnifying-glass"></i>
        </div>
      </div>

      <div className="filter">
        <h5>Latest Filter</h5>
        <img
          src="https://themes.pixelstrap.com/rica/assets/images/icon/adjust.png"
          alt=""
        />
      </div>

      <div className="filterByRating">
        <div className="sortBy">
          <h6>Sort By</h6>
          <Select
            defaultValue={sortBy}
            style={{
              width: '100%',
              marginTop: "10px",
            }}
            onChange={sortChange}
          >
            <Option value={-1}>Low to High</Option>
            <Option value={1}>High to Low</Option>
            <Option value="">None of These</Option>
          </Select>
        </div>
      </div>

      <div className="filterByRating">
       
        <CategoryFilter categoryToFilter={categoryToFilter} />
      </div>

      <div className="filterByRating">
        <div className="Title">
          <h6>By Rating</h6>
          <i
            className="fa-solid fa-minus"
            id="minus1"
            onClick={() => {
              document.getElementById("Content1").style.display = "none";
              document.getElementById("minus1").style.display = "none";
              document.getElementById("plus1").style.display = "block";
            }}
          ></i>
          <i
            className="fa-solid fa-plus"
            id="plus1"
            onClick={() => {
              document.getElementById("Content1").style.display = "block";
              document.getElementById("minus1").style.display = "block";
              document.getElementById("plus1").style.display = "none";
            }}
          ></i>
        </div>
      </div>

      <div className="filterByRating">
        <div className="Content" id="Content1">
          <div className="stars">
            <input
              type="radio"
              name="rating"
              className="form-check-input"
              id="five"
              onClick={() => {
                setboxValue("");
              }}
            />
            <label className="form-check-label rating">Show All</label>
          </div>

          <div className="stars">
            <input
              type="radio"
              name="rating"
              value={5}
              className="form-check-input"
              id="five"
              onClick={handleChange}
            />
            <label className="form-check-label rating">
              <i className="fas fa-star"></i>
              <i className="fas fa-star"></i>
              <i className="fas fa-star"></i>
              <i className="fas fa-star"></i>
              <i className="fas fa-star"></i>
            </label>
          </div>

          <div className="stars">
            <input
              type="radio"
              name="rating"
              value={4}
              className="form-check-input"
              id="five"
              onClick={handleChange}
            />
            <label className="form-check-label rating">
              <i className="fas fa-star"></i>
              <i className="fas fa-star"></i>
              <i className="fas fa-star"></i>
              <i className="fas fa-star"></i>
              <i className="fa-regular fa-star"></i>
            </label>
          </div>

          <div className="stars">
            <input
              type="radio"
              name="rating"
              value={3}
              className="form-check-input"
              id="five"
              onClick={handleChange}
            />
            <label className="form-check-label rating">
              <i className="fas fa-star"></i>
              <i className="fas fa-star"></i>
              <i className="fas fa-star"></i>
              <i className="fa-regular fa-star"></i>
              <i className="fa-regular fa-star"></i>
            </label>
          </div>

          <div className="stars">
            <input
              type="radio"
              name="rating"
              value={2}
              className="form-check-input"
              id="five"
              onClick={handleChange}
            />
            <label className="form-check-label rating">
              <i className="fas fa-star"></i>
              <i className="fas fa-star"></i>
              <i className="fa-regular fa-star"></i>
              <i className="fa-regular fa-star"></i>
              <i className="fa-regular fa-star"></i>
            </label>
          </div>

          <div className="stars">
            <input
              type="radio"
              name="rating"
              value={1}
              className="form-check-input"
              id="five"
              onClick={handleChange}
            />
            <label className="form-check-label rating">
              <i className="fas fa-star"></i>
              <i className="fa-regular fa-star"></i>
              <i className="fa-regular fa-star"></i>
              <i className="fa-regular fa-star"></i>
              <i className="fa-regular fa-star"></i>
            </label>
          </div>
        </div>
      </div>

      <div className="filterByRating">
        <div className="Title">
          <h6>Budget</h6>
          <i
            className="fa-solid fa-minus"
            id="minus4"
            onClick={() => {
              document.getElementById("Content4").style.display = "none";
              document.getElementById("minus4").style.display = "none";
              document.getElementById("plus4").style.display = "block";
            }}
          ></i>
          <i
            className="fa-solid fa-plus"
            id="plus4"
            onClick={() => {
              document.getElementById("Content4").style.display = "block";
              document.getElementById("minus4").style.display = "block";
              document.getElementById("plus4").style.display = "none";
            }}
          ></i>
        </div>
        <div className="Content" id="Content4">
          <div className="stars">
            <Slider
              range
              max={100000}
              step={500}
              value={value}
              onChange={priceChange}
            />

            <i
              className="fa-solid fa-xmark"
              style={{ color: "black", fontSize: "20px", cursor: "pointer" }}
              onClick={() => {
                setValue([0, 100000]);
              }}
            ></i>
          </div>
        </div>
      </div>

      <ServicesFilter />

      <div className="filterByRating">
        <div className="Title">
          <h6>trip duration</h6>
          <i
            className="fa-solid fa-minus"
            id="minus6"
            onClick={() => {
              document.getElementById("Content6").style.display = "none";
              document.getElementById("minus6").style.display = "none";
              document.getElementById("plus6").style.display = "block";
            }}
          ></i>
          <i
            className="fa-solid fa-plus"
            id="plus6"
            onClick={() => {
              document.getElementById("Content6").style.display = "block";
              document.getElementById("minus6").style.display = "block";
              document.getElementById("plus6").style.display = "none";
            }}
          ></i>
        </div>
        <div className="Content" id="Content6">
          <div className="stars">
            <input
              type="checkbox"
              value="1"
              className="form-check-input"
              id="five"
              onClick={durationFilter}
            />
            <label className="form-check-label rating">
              <span className="ms-1">1 nights</span>
            </label>
          </div>

          <div className="stars">
            <input
              type="checkbox"
              value="2"
              className="form-check-input"
              id="five"
              onClick={durationFilter}
            />
            <label className="form-check-label rating">
              <span className="ms-1">2 nights</span>
            </label>
          </div>

          <div className="stars">
            <input
              type="checkbox"
              value="3"
              className="form-check-input"
              id="five"
              onClick={durationFilter}
            />
            <label className="form-check-label rating">
              <span className="ms-1">3 nights</span>
            </label>
          </div>

          <div className="stars">
            <input
              type="checkbox"
              value="5"
              className="form-check-input"
              id="five"
              onClick={durationFilter}
            />
            <label className="form-check-label rating">
              <span className="ms-1">5 nights</span>
            </label>
          </div>

          <div className="stars">
            <input
              type="checkbox"
              value="7"
              className="form-check-input"
              id="five"
              onClick={durationFilter}
            />
            <label className="form-check-label rating">
              <span className="ms-1">7 nights</span>
            </label>
          </div>

          <div className="stars">
            <input
              type="checkbox"
              value="15"
              className="form-check-input"
              id="five"
              onClick={durationFilter}
            />
            <label className="form-check-label rating">
              <span className="ms-1">15 nights</span>
            </label>
          </div>

          <div className="stars">
            <input
              type="checkbox"
              value="25"
              className="form-check-input"
              id="five"
              onClick={durationFilter}
            />
            <label className="form-check-label rating">
              <span className="ms-1">25 nights</span>
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SideBar;
