import { useState, useEffect } from 'react';
import axios from 'axios';
import './Wishlist.css';
import Modal from "@mui/material/Modal";
import TextField from '@mui/material/TextField';
import { CButton, CSpinner } from '@coreui/react';
import { setTimeout } from 'core-js';


const WishlistModal = ({open, close, id}) => {

    const [value, setValue] = useState("");
    const [msg, setMsg] = useState("");
    const [succMsg, setSuccMsg] = useState("");
    const [errMsg, setErrMsg] = useState("");
    const [wishlists, setWishlists] = useState([]);
    const [loading, setLoading] = useState(false);
    const [newList, setNewList] = useState(false);

    

    const Authaxios = axios.create({
        baseURL: process.env.REACT_APP_BASE_URL,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });


    useEffect(() => {
 
        Authaxios.get(`${process.env.REACT_APP_BASE_URL}/wishlist/getAllListByUser`)
          .then((res) => {

            if(!res.error){
            setWishlists(res.data.results);
           
            }
            
          })
          .catch((error) => console.log(error));
    }, []);


    const createList = () => {
        setLoading(true);
       
        Authaxios.post("/wishlist/createNewList", { name: value, destination_id: id })
        .then((res) => {
          if (!res.error) {
         
            setMsg("Added To Wishlist Successfully")
            setLoading(false);
            window.location.reload()
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    }

    
    const handleClick = () => {
        close(false)
        setNewList(false)
    }

    const addItemInList = (listId) => {
    
        Authaxios.post('/wishlist/addDestinationToList',{list_id:listId ,destination_id:id}).then((res) => {
            if(!res.error){
            
                setSuccMsg("Added To Wishlist Successfully")
                // close(false)
                window.location.reload()
            }
        }).catch((err) => {
            setErrMsg(err.response?.data.message)
            setTimeout(() => {
                setErrMsg("")
            },2000)
        })
    }

    return (
        <Modal
        open={open}
        onClose={close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="wishlistModalContain">
        <div className='wishlistModal' style={{display: newList ? "none" : "flex"}}>
            <i className='fa-solid fa-xmark' onClick={handleClick}></i>
            {errMsg && <div className='fixedMsg' style={{background:"red"}}><p style={{
                color: 'darkred',
                fontWeight: '600'
            }}>{errMsg}</p></div>}
            {succMsg && <div className='fixedMsg' ><p>{succMsg}</p></div>}
            
            <h5>Select a list</h5>
            {wishlists?.length ? <button className="listBtn" onClick={() => setNewList(true)}>New list</button> : <></>}
          

            {!wishlists?.length ? <div className='noList'>
                <h5 className='noList-title'>You have no lists</h5>
                <span>Lists help you organize activities by city or trip.</span>
                <button onClick={() => setNewList(true)}>Create a new list</button>
            </div> :

            wishlists.map((item) => {
             return <div className='listsAvailable' key={item._id} onClick={() => addItemInList(item._id)}>
                {item?.items?.length ? <img src={`${process.env.REACT_APP_BASE_URL}${item?.items[0]?.destination_id?.image}`} alt="img" /> : <img src="https://cdn.vectorstock.com/i/preview-lt/48/06/image-preview-icon-picture-placeholder-vector-31284806.webp" alt="img" />}

                <div className="listsInfo">
                    <span>{item.list_name}</span>
                    <p>{item?.items?.length} activity</p>
                </div>
            </div>})}
            
            
        </div>


        <div className='wishlistModal' style={{display: newList ? "flex" : "none"}}>
        <i className='fa-solid fa-xmark' onClick={handleClick}></i>
            <h5>Create a new list</h5>
            <TextField id="outlined-basic" label="List Name" variant="outlined" onChange={(e) => setValue(e.target.value)}/>
            {msg && <div className='wishlistMsg'><p>{msg}</p></div>}
            <CButton style={{
                marginTop: '20px',
                padding: '10px',
                fontSize: '15px',
                fontWeight: '600',
                background: 'rgb(239,63,62)',
                borderRadius: '23px',
                color: 'white',
                width:"25%",
                border: "none"
                }} 
                
                disabled={!value ? true : false}
                onClick={createList}
                >{loading ? <CSpinner color="white" style={{width:"19px",height:"19px",background:"transparent"}}/> : <>Done</>}</CButton>
        </div>
        </div>
      </Modal>        
    )
}

export default WishlistModal;