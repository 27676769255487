import axios from "axios";

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const onGetCountry = async (payload) => {
  let tokenAdmin = JSON.parse(localStorage.getItem("tokenAdmin"));
  let supplierToken = JSON.parse(localStorage.getItem("tokenSupplier"));

  let config = {
    headers: { Authorization: `Bearer ${tokenAdmin || supplierToken}` },
    params: payload,
  };
  return await axios
    .get(`${BASE_URL}/countries/admin/get-multiple-countries`, config)
    .then((response) => response)
    .catch((error) => error);
};

export const onGetAllCountry = async (payload) => {
  let tokenAdmin = JSON.parse(localStorage.getItem("tokenAdmin"));
  let supplierToken = JSON.parse(localStorage.getItem("tokenSupplier"));

  let config = {
    headers: { Authorization: `Bearer ${tokenAdmin || supplierToken}` },
    params: payload,
  };
  return await axios
    .get(`${BASE_URL}/countries/admin/get-all-countries`, config)
    .then((response) => response)
    .catch((error) => error);
};

export const onGetStateByCountry = async (countryId) => {
  let tokenAdmin = JSON.parse(localStorage.getItem("tokenAdmin"));
  let supplierToken = JSON.parse(localStorage.getItem("tokenSupplier"));

  let config = {
    headers: { Authorization: `Bearer ${tokenAdmin || supplierToken}` },
  };
  return await axios
    .get(`${BASE_URL}/states/admin/get-state-by-country/${countryId}`, config)
    .then((response) => response)
    .catch((error) => error);
};
export const onGetCityByState = async (stateId) => {
  let tokenAdmin = JSON.parse(localStorage.getItem("tokenAdmin"));
  let supplierToken = JSON.parse(localStorage.getItem("tokenSupplier"));

  let config = {
    headers: { Authorization: `Bearer ${tokenAdmin || supplierToken}` },
  };
  return await axios
    .get(`${BASE_URL}/cities/admin/get-city-by-state/${stateId}`, config)
    .then((response) => response)
    .catch((error) => error);
};
