import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  games: [],
  users: [],
  destinations: [],
  newDes: false,
  newDesCount: "0",
  newDesSlug: "",
  onDesEdit: false,
  desCreated: false,
  imageAdded: false,
  slotsAdded: false,
  desEdited: false,
  desImages: [],
  desSlots: [],
  cms: [],
  contactsEmail: [],
  contactsFaq: [],
  contactsPhone: [],
  contactsWhatsApp: [],
  bookings: [],
  payments: [],
  bookingOptions: [],
  countries: [],
  coupons: [],
  destinationCategories: [],
  destinationImages: [],
  destinationServices: [],
  giftAmounts: [],
  purchasedGifts: [],
  subscribers: [],
  destinationQas: [],
  viewAnswers: [],
  suppliers: [],
};
const reducers = {
  setGames: (state, action) => {
    state.games = action.payload;
  },
  setUsers: (state, action) => {
    state.users = action.payload;
  },
  setDestination: (state, action) => {
    state.destinations = action.payload;
  },

  setNewDes: (state, action) => {
    state.newDes = action.payload;
  },

  setDesEdit: (state, action) => {
    state.onDesEdit = action.payload
  },

  setNewDesCount: (state, action) => {
    state.newDesCount = action.payload;
  },

  setDesCreated: (state, action) => {
    state.desCreated = action.payload;
  },

  setNewDesSlug: (state, action) => {
    state.newDesSlug = action.payload;
  },

  setImageAdded: (state,action) => {
    state.imageAdded = action.payload
  },

  setSlotsAdded: (state,action) => {
    state.slotsAdded = action.payload
  },

  setDesEdited: (state,action) => {
    state.desEdited = action.payload
  },

  setDesImages: (state,action) => {
    state.desImages = action.payload
  },

  setDesSlots: (state,action) => {
    state.desSlots = action.payload
  },

  setCms: (state, action) => {
    state.cms = action.payload;
  },
  setContactEmail: (state, action) => {
    state.contactsEmail = action.payload;
  },
  setContactFaq: (state, action) => {
    state.contactsFaq = action.payload;
  },
  setContactPhone: (state, action) => {
    state.contactsPhone = action.payload;
  },
  setContactWhatsApp: (state, action) => {
    state.contactsWhatsApp = action.payload;
  },
  setBooking: (state, action) => {
    state.bookings = action.payload;
  },
  setPayment: (state, action) => {
    state.payments = action.payload;
  },
  setBookingOption: (state, action) => {
    state.bookingOptions = action.payload;
  },
  setCountry: (state, action) => {
    state.countries = action.payload;
  },
  setCoupon: (state, action) => {
    state.coupons = action.payload;
  },
  setDestinationCategory: (state, action) => {
    state.destinationCategories = action.payload;
  },
  setDestinationImage: (state, action) => {
    state.destinationImages = action.payload;
  },
  setDestinationService: (state, action) => {
    state.destinationServices = action.payload;
  },
  setGiftAmount: (state, action) => {
    state.giftAmounts = action.payload;
  },
  setPurchasedGift: (state, action) => {
    state.purchasedGifts = action.payload;
  },
  setSubscriber: (state, action) => {
    state.subscribers = action.payload;
  },
  setDestinationQas: (state, action) => {
    state.destinationQas = action.payload;
  },
  setViewAnswers: (state, action) => {
    state.viewAnswers = action.payload;
  },
  setSuppliers: (state, action) => {
    state.suppliers = action.payload;
  },
};

export const tableDataSlice = createSlice({
  name: "tableDataSlice",
  initialState,
  reducers: reducers,
});

// Action creators are generated for each case reducer function
export const {
  setGames,
  setUsers,
  setDestination,
  setNewDes,
  setDesEdit,
  setNewDesCount,
  setNewDesSlug,
  setDesCreated,
  setImageAdded,
  setSlotsAdded,
  setDesEdited,
  setDesImages,
  setDesSlots,
  setCms,
  setContactEmail,
  setContactFaq,
  setContactPhone,
  setContactWhatsApp,
  setBooking,
  setPayment,
  setBookingOption,
  setCountry,
  setCoupon,
  setDestinationCategory,
  setDestinationImage,
  setDestinationService,
  setGiftAmount,
  setPurchasedGift,
  setSubscriber,
  setDestinationQas,
  setViewAnswers,
  setSuppliers,
} = tableDataSlice.actions;
export default tableDataSlice.reducer;
