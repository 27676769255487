import {useEffect, useState} from 'react';
import axios from 'axios';
import './Dynamic.css';
import {useParams} from 'react-router-dom';

const Dynamic = () => {

    const {slug} = useParams();
    const [page, setPage] = useState({});

    useEffect(() => {

      const fetch = async () => {
        try {
          const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/${slug}`);
          setPage(data);
          window.scrollTo(0, 0)
        } catch (err) {
          console.error(err);
        }
      };
      fetch();

    },[slug])

    return (
        <div>
        <img
        style={{ width: "100%", height: "350px" }}
        src="https://images.unsplash.com/photo-1497864149936-d3163f0c0f4b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=869&q=80"
        alt=""
      />
        <div className='pages'>
        <h3>{page.title}</h3>
        <div dangerouslySetInnerHTML={{__html: page.description}} />
       
        </div>
        </div>
    )
}

export default Dynamic;