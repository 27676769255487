import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n.use(initReactI18next).init({
  fallbackLng: "en",
  lng: document.documentElement.lang,
  resources: {
    en: {
      translations: require('./en/translation.json')
    },
    es: {
      translations: require('./es/translation.json')
    },
    fr: {
      translations: require('./fr/translation.json')
    },
    ar: {
      translations: require('./ar/translation.json')
    },
    de: {
      translations: require('./de/translation.json')
    },
    id: {
      translations: require('./id/translation.json')
    },
    ja: {
      translations: require('./ja/translation.json')
    },
    pt: {
      translations: require('./pt/translation.json')
    },
    ru: {
      translations: require('./ru/translation.json')
    },
    zh: {
      translations: require('./zh/translation.json')
    },
  },
  ns: ['translations'],
  defaultNS: 'translations'
});

i18n.languages = ['en', 'es','ar','de','fr','id','ja','pt','ru','zh'];

export default i18n;
