import { useState, useEffect } from "react";
import axios from "axios";
import "./Profile.css";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import LockIcon from "@mui/icons-material/Lock";
import ApartmentIcon from "@mui/icons-material/Apartment";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import LoadingButton from "@mui/lab/LoadingButton";

import Stack from "@mui/material/Stack";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";

import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import CloseIcon from "@mui/icons-material/Close";
import { isError } from "lodash";

const Profile = () => {
  const [loading, setLoading] = useState(false);
  const [date, setDateValue] = useState("");
  const [loadingPassword, setLoadingPassword] = useState(false);
  const [loadingAddress, setLoadingAddress] = useState(false);
  const [open, setOpen] = useState(false);
  const [serverMsg, setServerMsg] = useState();
  const [isServerError, setIsServerError] = useState(false);
  const [values, setValues] = useState({
    full_name: "",
    first_name: "",
    last_name: "",
    email: "",
    mobile: "",

    oldPassword: "",
    showNewPassword: false,
    newPassword: "",
    showConfPassword: false,

    street: "",
    address2: "",
    zipcode: "",
    city: "",
    state: "",
    country: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const handlePasswordChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleDateChange = (e) => {
    setDateValue(e);
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showNewPassword: !values.showNewPassword,
    });
  };

  const handleClickShowConfPassword = () => {
    setValues({
      ...values,
      showConfPassword: !values.showConfPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const Authaxios = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });

  useEffect(() => {
    Authaxios.get(`${process.env.REACT_APP_BASE_URL}/get-profile`)
      .then((response) => {
        setValues({
          full_name:
            response.data.user.first_name + " " + response.data.user.last_name,
          first_name: response.data.user.first_name,
          last_name: response.data.user.last_name,
          email: response.data.user.email,
          mobile: response.data.user.mobile,

          street: response.data.user.address
            ? response.data.user.address.street
            : "",
          address2: response.data.user.address
            ? response.data.user.address.address2
            : "",
          zipcode: response.data.user.address
            ? response.data.user.address.zipcode
            : "",
          city: response.data.user.address
            ? response.data.user.address.city
            : "",
          state: response.data.user.address
            ? response.data.user.address.state
            : "",
          country: response.data.user.address
            ? response.data.user.address.country
            : "",
        });
        setDateValue(
          response.data.user.dob ? new Date(response.data.user.dob) : ""
        );
      })
      .catch((error) => error);
  }, []);

  const handleProfile = () => {
    setLoading(true);
    setIsServerError(false);

    const newDate = new Date(date).toDateString();

    const userData = {
      first_name: values.first_name,
      last_name: values.last_name,
      mobile: values.mobile,
      dob: new Date(newDate).toISOString(),
    };

    Authaxios.patch("/edit-profile", userData)
      .then((res) => {
        if (!res.error) {
          setLoading(false);
          setOpen(true);
          setServerMsg(res.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
        setOpen(true);
        setServerMsg(error.response.data.message);
        setIsServerError(true);
        setLoading(false);
      });
  };

  const handlePassword = () => {
    setLoadingPassword(true);
    setIsServerError(false);

    const passwordData = {
      oldPassword: values.oldPassword,
      newPassword: values.newPassword,
    };

    Authaxios.patch("/change-password", passwordData)
      .then((res) => {
        if (!res.error) {
          setLoadingPassword(false);
          setOpen(true);
          setServerMsg(res.data.message);
          setValues({
            oldPassword: "",
            newPassword: "",
          });
        }
      })
      .catch((error) => {
        console.log(error);
        setOpen(true);
        setServerMsg(error.response.data.message);
        setIsServerError(true);
        setLoadingPassword(false);
      });
  };

  const handleAddress = () => {
    setLoadingAddress(true);
    setIsServerError(false);

    const addressdata = {
      street: values.street,
      address2: values.address2,
      zipcode: values.zipcode,
      city: values.city,
      state: values.state,
      country: values.country,
    };

    Authaxios.patch("/edit-address", addressdata)
      .then((res) => {
        if (!res.error) {
          setLoadingAddress(false);
          setOpen(true);
          setServerMsg(res.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
        setOpen(true);
        setServerMsg(error.response.data.message);
        setIsServerError(true);
        setLoadingAddress(false);
      });
  };

  return (
    <>
      <div className="container profile-content" style={{ padding: "150px 0" }}>
        {serverMsg ? (
          <Box sx={{ width: "100%" }}>
            <Collapse in={open}>
              <Alert
                variant="outlined"
                severity={isServerError ? "error" : "success"}
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setOpen(false);
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
                sx={{ mb: 2 }}
              >
                {serverMsg ? serverMsg : ""}
              </Alert>
            </Collapse>
          </Box>
        ) : (
          ""
        )}
        <div className="">
          <Tabs>
            <TabList>
              <div className="profile-overview">
                <div className="name">
                  {values.full_name ? values.full_name : ""}
                </div>
                <div className="email">{values.email ? values.email : ""}</div>
                <div className="mobile">
                  {values.mobile ? values.mobile : ""}
                </div>
              </div>
              <Tab>
                <p className="pr">
                  <AccountBoxIcon />
                   Profile
                </p>
              </Tab>
              <Tab>
                <p className="pr">
                  <LockIcon />
                  Password
                </p>
              </Tab>
              <Tab>
                <p className="pr">
                  <ApartmentIcon />
                  Address
                </p>
              </Tab>
            </TabList>

            <TabPanel>
              <div className="panel-content">
                <h3>Profile Details</h3>
                <hr />
                <Box
                  component="form"
                  sx={{
                    "& .MuiTextField-root": { m: 1, mb: 5, width: "50ch" },
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <div>
                    <TextField
                      fullWidth
                      required
                      id="outlined-required"
                      label="First Name"
                      value={values.first_name}
                      name="first_name"
                      onChange={handleChange}
                    />
                    <TextField
                      required
                      id="outlined-required"
                      label="Last Name"
                      value={values.last_name}
                      name="last_name"
                      onChange={handleChange}
                    />
                  </div>
                </Box>
                <h3>Contact Details</h3>
                <hr />
                <Box
                  component="form"
                  sx={{
                    "& .MuiTextField-root": { m: 1, mb: 5, width: "50ch" },
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <div>
                    <TextField
                      disabled
                      id="outlined-required"
                      label="Email"
                      value={values.email}
                      name="email"
                      onChange={handleChange}
                    />
                    <TextField
                      required
                      id="outlined-required"
                      label="Mobile"
                      value={values.mobile}
                      name="mobile"
                      onChange={handleChange}
                    />
                  </div>
                </Box>
                <h3>Date of Birth</h3>
                <hr />
                <Box
                  component="form"
                  sx={{
                    "& .MuiTextField-root": { m: 1, mb: 5, width: "33ch" },
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <div>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <Stack spacing={3}>
                        <MobileDatePicker
                          label="DOB"
                          inputFormat="MM/dd/yyyy"
                          value={date}
                          onChange={handleDateChange}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </Stack>
                    </LocalizationProvider>
                  </div>
                </Box>
                <Box
                  component="form"
                  sx={{
                    "& .MuiTextField-root": { m: 1, mb: 5, width: "50ch" },
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <LoadingButton
                    fullWidth
                    sx={{ mt: 2 }}
                    variant="contained"
                    onClick={handleProfile}
                    loading={loading}
                    loadingIndicator="Loading…"
                    style={{
                      backgroundColor: "#ef3f3e",
                    }}
                  >
                    Edit Profile
                  </LoadingButton>
                </Box>
              </div>
            </TabPanel>
            <TabPanel>
              <div className="panel-content">
                <h2>Change Password</h2>
                <hr />
                <Box
                  component="form"
                  sx={{
                    "& .MuiTextField-root": { m: 1, mb: 5, width: "50ch" },
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <div>
                    <FormControl
                      sx={{ m: 1, width: "50ch" }}
                      variant="outlined"
                    >
                      <InputLabel
                        fullWidth
                        htmlFor="outlined-adornment-password"
                      >
                        Current Password
                      </InputLabel>
                      <OutlinedInput
                        fullWidth
                        id="outlined-adornment-password"
                        type={values.showNewPassword ? "text" : "password"}
                        value={values.oldPassword}
                        onChange={handlePasswordChange("oldPassword")}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {values.showNewPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                        label="Current Password"
                      />
                    </FormControl>

                    <FormControl
                      sx={{ m: 1, width: "50ch" }}
                      variant="outlined"
                    >
                      <InputLabel
                        fullWidth
                        htmlFor="outlined-adornment-password"
                      >
                        New Password
                      </InputLabel>
                      <OutlinedInput
                        fullWidth
                        id="outlined-adornment-password"
                        type={values.showConfPassword ? "text" : "password"}
                        value={values.newPassword}
                        onChange={handlePasswordChange("newPassword")}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowConfPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {values.showConfPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                        label="New Password"
                      />
                    </FormControl>
                  </div>
                </Box>
                <Box
                  component="form"
                  sx={{
                    "& .MuiTextField-root": { m: 1, mb: 5, width: "50ch" },
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <LoadingButton
                    fullWidth
                    sx={{ mt: 2 }}
                    variant="contained"
                    onClick={handlePassword}
                    loading={loadingPassword}
                    loadingIndicator="Loading…"
                  >
                    Change Password
                  </LoadingButton>
                </Box>
              </div>
            </TabPanel>
            <TabPanel>
              <div className="panel-content">
                <h2>Address</h2>
                <hr />
                <Box
                  component="form"
                  sx={{
                    "& .MuiTextField-root": { m: 1, mb: 5, width: "50ch" },
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <div>
                    <TextField
                      required
                      fullWidth
                      id="outlined-required"
                      label="Address 1"
                      value={values.street}
                      name="street"
                      onChange={handleChange}
                    />
                    <TextField
                      required
                      id="outlined-required"
                      label="Address 2"
                      value={values.address2}
                      name="address2"
                      onChange={handleChange}
                    />
                  </div>
                  <div>
                    <TextField
                      required
                      id="outlined-required"
                      label="Zip Code"
                      value={values.zipcode}
                      name="zipcode"
                      onChange={handleChange}
                    />
                    <TextField
                      required
                      id="outlined-required"
                      label="City"
                      value={values.city}
                      name="city"
                      onChange={handleChange}
                    />
                  </div>
                  <div>
                    <TextField
                      required
                      id="outlined-required"
                      label="State"
                      value={values.state}
                      name="state"
                      onChange={handleChange}
                    />
                    <TextField
                      required
                      id="outlined-required"
                      label="Country"
                      value={values.country}
                      name="country"
                      onChange={handleChange}
                    />
                  </div>
                </Box>
                <Box
                  component="form"
                  sx={{
                    "& .MuiTextField-root": { m: 1, mb: 5, width: "50ch" },
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <LoadingButton
                    fullWidth
                    sx={{ mt: 2 }}
                    variant="contained"
                    onClick={handleAddress}
                    loading={loadingAddress}
                    loadingIndicator="Loading…"
                  >
                    Edit Address
                  </LoadingButton>
                </Box>
              </div>
            </TabPanel>
          </Tabs>
        </div>
      </div>
    </>
  );
};

export default Profile;
