import axios from "axios";

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const onGetDestinations = async (payload) => {
  let tokenAdmin = JSON.parse(localStorage.getItem("tokenAdmin"));
  let supplierToken = JSON.parse(localStorage.getItem("tokenSupplier"));

  let config = {
    headers: { Authorization: `Bearer ${tokenAdmin || supplierToken}` },
    params: payload,
  };
  return await axios
    .get(`${BASE_URL}/admin/destinations/getMultipleDestinations`, config)
    .then((response) => response)
    .catch((error) => error);
};

export const onGetAllDestinations = async (payload) => {
  let tokenAdmin = JSON.parse(localStorage.getItem("tokenAdmin"));
  let supplierToken = JSON.parse(localStorage.getItem("tokenSupplier"));

  let config = {
    headers: { Authorization: `Bearer ${tokenAdmin || supplierToken}` },
    params: payload,
  };
  return await axios
    .get(`${BASE_URL}/admin/destinations/getAllDestinations`, config)
    .then((response) => response)
    .catch((error) => error);
};

export const onCreateDetinations = async (payload) => {
  let tokenAdmin = JSON.parse(localStorage.getItem("tokenAdmin"));
  let supplierToken = JSON.parse(localStorage.getItem("tokenSupplier"));

  return await axios
    .post(`${BASE_URL}/admin/destinations/listDestinations`, payload, {
      headers: {
        Authorization: `Bearer ${tokenAdmin || supplierToken}`,
      },
    })
    .then((response) => response)
    .catch((error) => error);
};

export const onGetDestinationsCategory = async (payload) => {
  let tokenAdmin = JSON.parse(localStorage.getItem("tokenAdmin"));
  let supplierToken = JSON.parse(localStorage.getItem("tokenSupplier"));

  let config = {
    headers: { Authorization: `Bearer ${tokenAdmin || supplierToken}` },
    params: payload,
  };
  return await axios
    .get(`${BASE_URL}/admin/destinations/getAllDestinationCategory`, config)
    .then((response) => response)
    .catch((error) => error);
};

export const onGetSingleDestinations = async (destinationId) => {
  let tokenAdmin = JSON.parse(localStorage.getItem("tokenAdmin"));
  let supplierToken = JSON.parse(localStorage.getItem("tokenSupplier"));

  let config = {
    headers: { Authorization: `Bearer ${tokenAdmin || supplierToken}` },
  };
  return await axios
    .get(
      `${BASE_URL}/admin/destinations/getSingleDestinations/${destinationId}`,
      config
    )
    .then((response) => response)
    .catch((error) => error);
};

export const onGetDestinationsByCategory = async (categoryId) => {
  let tokenAdmin = JSON.parse(localStorage.getItem("tokenAdmin"));
  let supplierToken = JSON.parse(localStorage.getItem("tokenSupplier"));

  let config = {
    headers: { Authorization: `Bearer ${tokenAdmin || supplierToken}` },
  };
  return await axios
    .get(
      `${BASE_URL}/admin/destinations/getDestinationsByCategory/${categoryId}`,
      config
    )
    .then((response) => response)
    .catch((error) => error);
};

export const onEditDestinations = async (id, payload) => {
  let tokenAdmin = JSON.parse(localStorage.getItem("tokenAdmin"));
  let supplierToken = JSON.parse(localStorage.getItem("tokenSupplier"));

  return await axios
    .patch(`${BASE_URL}/admin/destinations/updateDestinations/${id}`, payload, {
      headers: {
        Authorization: `Bearer ${tokenAdmin || supplierToken}`,
      },
    })
    .then((response) => response)
    .catch((error) => error);
};

export const onMakeDestinationLive = async (id) => {
  let tokenAdmin = JSON.parse(localStorage.getItem("tokenAdmin"));

  return await axios
    .patch(`${BASE_URL}/admin/destinations/makeDestinationLive/${id}`, {
      headers: {
        Authorization: `Bearer ${tokenAdmin}`,
      },
    })
    .then((response) => response)
    .catch((error) => error);
};
