
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    errorMessages: {},
    successMessages: {},
}

const reducers = {
    setErrorMessage: (state, action) => {
        console.log('setErrorMessage method called', action.payload)
        state.errorMessages = action.payload
    },
    setSuccessMessage: (state, action) => {
        console.log('setSuccessMessage method called', action.payload)
        state.successMessages = action.payload
    },
}
export const globalSlice = createSlice({
    name: 'globalSlice',
    initialState,
    reducers: reducers,
})

// Action creators are generated for each case reducer function
export const { setErrorMessage, setSuccessMessage } = globalSlice.actions
export default globalSlice.reducer