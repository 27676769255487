import { useState } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import GoogleLogin from "./GoogleLogin";
import { CSpinner } from "@coreui/react";
import "./Signup.css";
import AppleLogin from "./AppleLogin";
import Facebook from "./FacebookLogin";



const Signup = () => {

  const [msg, setMsg] = useState("");
  const [first_name, setFirst_name] = useState("");
  const [last_name, setLast_name] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState(""); 
  const [loading, setLoading] = useState(false);

  const { register, handleSubmit, formState: { errors } } = useForm();

  const onSubmit = async () => {
    setLoading(true)
    await axios
    .post(`${process.env.REACT_APP_BASE_URL}/register`, {
      first_name,
      last_name,
      email,
      password,
    })
    .then((res) => {

      
      if(!res.error){
        document.getElementById("Submit_id").disabled=true;
        document.getElementById("Submit_id").style.background = "#E0E0E0";
        setMsg(res.data.message)
        setTimeout(
          function() {
            window.location.href = "/signup"
            }, 5000) 
      }
    }).catch((error) => {
      setMsg(error.response.data.message)
      setLoading(false)
    });

  }
  

  return (
    <div>
      <img
        style={{ width: "100%", height: "400px" }}
        src="https://images.unsplash.com/photo-1497864149936-d3163f0c0f4b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=869&q=80"
        alt=""
      />
      <div className="signupbox">SIGNUP</div>
      <div className="login">
        <div className="main">
          <div className="loginCenter"><p>Sign up With</p></div>
          <div className="loginbtnContainer">
           
            <GoogleLogin />
    
            <Facebook />
          
            <AppleLogin />
            

          </div>
          <div className="orContainer">
            <span className="or">OR</span>
          </div>
          <div className="inputContainer">
            <label>First Name</label>
            <input
              type="text"
              autoComplete="off"
              {...register("firstname", {required: "First name is required",pattern: {
                value: /^[a-zA-Z][a-zA-Z ]+$/,
                message: "Invalid name"
              }})}
              placeholder="Enter your first name"
              onChange={(e) => {
                setFirst_name(e.target.value);
              }}
            />
            <p>{errors.firstname?.message}</p>
            <label>Last Name</label>
            <input
              type="text"
              autoComplete="off"
              {...register("lastname", {required: "Last name is required",pattern: {
                value: /^[a-zA-Z]+$/,
                message: "Invalid name"
              }})}
              placeholder="Enter your last name"
              onChange={(e) => {
                setLast_name(e.target.value);
              }}
            />
            <p>{errors.lastname?.message}</p>
            <label>Email</label>
            <input
              type="email"
              autoComplete="off"
              {...register("email", {required: "Email is required",
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: "invalid email address"
              }})}
              placeholder="Enter email address"
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
            <p>{errors.email?.message}</p>
            {msg.includes("exists") ? (<p style={{color:"red"}}>{msg}</p>) : (<></>)}
            <label>Password</label>
            <input
              type="password"
              {...register("password", {required: "Password is required",minLength:{value:8, message:"Password must be 8 characters long"}, maxLength: {value:15, message:"Password is too long it must be under 15 characters"}})}
              placeholder="Password"
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
            <p>{errors.password?.message}</p>
          
            {msg.includes("verify") ? (<p style={{color:"rgb(22, 170, 22)"}}>{msg}</p>) : (<></>)}
            <button id="Submit_id"
              className="logbtn"
              style={{ marginTop: "10px",marginLeft:"0px"}}
              onClick={handleSubmit(onSubmit)}
            >
              {loading ? <CSpinner color='primary' style={{width:"19px",height:"19px"}} /> : <>CREATE ACCOUNT</>}
            </button>
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div className="orContainer">
              <span className="or">OR</span>
            </div>
            <button
              className="createbtn"
              style={{marginLeft:"0px"}}
              onClick={() => {
                window.location.href = "/login";
              }}
            >
              LOGIN
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signup;
