import './DestinationDetail.css';

const HeadingTitles = () => {

    return (
        <div className='headingTitles'>
            <a href='#highlight'><li>highlight</li></a>
            <a href='#experience'><li>experience</li></a>
            <a href='#qa' ><li>QA</li></a>
            <a href='#reviews'><li>reviews</li></a>
            
        </div>
    )
}

export default HeadingTitles;