import { useState } from "react";
import Answer from "./Answer";
import "./DestinationDetail.css";

const Answers = ({ qa, ans, qId }) => {
  const [more, setMore] = useState(false);

  const moreAns = () => {
    !more ? setMore(true) : setMore(false);
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }} 
      >
        {qa.is_active === true && <h6>Answer:</h6>}
        {ans?.length ? (
          <div className="answer">
            {ans?.slice(0, more ? ans?.length : 1)?.map((item) => {
              return (<>
                {item?.is_active === true && <Answer key={item?._id} answer={item?.answer} qId={qId} ansId={item?._id} id={item?.user_id} qa={qa} ans={ans} name={item?.user_id.first_name}/>}
                </>
              );
            })}
            {ans.length > 1 && qa?.answers.is_active === true ? (
              <div>
                {!more ? (
                  <div className="moreAnsBtn" onClick={moreAns}>
                    See more answers{" "}
                    <i className="fa-solid fa-chevron-down"></i>
                  </div>
                ) : (
                  <div className="moreAnsBtn" onClick={moreAns}>
                    Collapse all answers{" "}
                    <i className="fa-solid fa-chevron-up"></i>
                  </div>
                )}
              </div>
            ) : (
              <></>
            )}
          </div>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default Answers;
