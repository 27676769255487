import './DestinationDetail.css';

const Suggestion = ({image, title, days, nights,slug, id}) => {

    return (
        <div className='suggestion' key={id}>
            <a href={`/destination/${slug}`}><img src={`${process.env.REACT_APP_BASE_URL}${image}`} alt='' /></a>
            <div className='text'>
                <a href={`/destination/${slug}`}><h5>{title?.slice(0,20)}</h5></a>
                <h6>{`N${nights} D${days}`}</h6>
            </div>
            <div className='shadow'></div>
            
        </div>
    )
}

export default Suggestion;