export const setTokenInLocalSorage = (data) => {
  localStorage.setItem("tokenAdmin", JSON.stringify(data));
};
export const getTokenFromLocalSorage = () => {
  const token = JSON.parse(localStorage.getItem("tokenAdmin"));
  return token;
};

export const setSupplierTokenInLocalStorage = (data) => {
  localStorage.setItem("tokenSupplier", JSON.stringify(data));
};
export const getSupplierToken = () => {
  const token = JSON.parse(localStorage.getItem("tokenSupplier"));
  return token;
};

export const removeTokenInLocalSorage = () => {
  localStorage.clear();
};
