import './DestinationDetail.css';
import Rating from '@mui/material/Rating'

const OverallRating = ({reviews, avgRating}) => {

    return (
        <div className='overallRating'>
            <h6>Overall rating</h6>
            <h5>Customer reviews</h5>
            <div className='overallContainer'>
            {reviews.length ? <h1>{avgRating?.toFixed(1)}/<span>5</span></h1> :
            <h1>0/<span>5</span></h1>}
            <div className='overallStars'>
            {reviews.length ? <Rating name="read-only" value={avgRating?.toFixed(0)}  readOnly /> :
            <Rating name="read-only" value={0}  readOnly />}
            </div>
            
            <p>Based on {reviews?.length} reviews</p>
            </div>
        </div>
    )
}

export default OverallRating;