import { useState, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import Modal from "@mui/material/Modal";
import Paypal from "src/components/PayPal/PayPal";
import { CSpinner } from "@coreui/react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import DatesPicker from "react-datepicker";
// import { DatePicker } from "@mui/x-date-pickers/DatePicker";
// import TextField from "@mui/material/TextField";
// import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
// import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import "antd/dist/antd.min.css";
import "./DestinationDetail.css";
import CountryCode from "./CountryCode";
import AddToCart from "./AddToCart";
import { setTimeout } from "core-js";
import { addDays } from "date-fns";

const BookDestination = ({ id, image, offerPrice, slug }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [show, setShow] = useState(false);
  const [count, setCount] = useState(1);
  const [totalPrice, setTotalPrice] = useState(0);
  const [dateValue, setDateValue] = useState();
  const [utcDate, setUtcDate] = useState();
  const [dateChanged, setDateChanged] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [Loading, setLoading] = useState(false);
  const [hide, setHide] = useState(false);
  const [price, setPrice] = useState(0);
  const [title, setTitle] = useState("");
  const [error, setError] = useState("");
  const [options, setOptions] = useState([]);
  const [allDates, setAllDates] = useState([]);
  const [result, setResult] = useState(false);
  const [dates, setDates] = useState("");
  const [bookingId, setBookingId] = useState("");
  const [btnPressed, setBtnPressed] = useState(false);
  const [payBtnLoad, setPayBtnLoad] = useState(false);
  const [checkout, setCheckOut] = useState(false);
  const [startDate, setStartDate] = useState(new Date());

  const navigate = useNavigate();

  const [payModal, setPayModal] = useState(false);

  const token = localStorage.getItem("token");

  const specificDate = "2022-09-25T11:48:42.000Z";

  // const specYear = specificDate.slice(0,4);
  // const specMonth = specificDate.slice(5,7);
  const specDay = specificDate.slice(0, 10);

  const highlightedDates = [
    addDays(new Date(), 1),
    addDays(new Date(), 2),
    addDays(new Date(), 3),
    addDays(new Date(), 4),
    new Date(`${specDay}`),
  ];

  const handlePayOpen = () => {
    if (!token) {
      navigate("/login");
    } else {
      setLoading(true);
      setPayModal(true);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  };

  const handlePayClose = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setPayModal(false);
    }, 1000);
  };

  const Increase = () => {
    setCount(count + 1);
  };

  const Decrease = () => {
    if (count <= 1) {
      setCount(1);
    } else {
      setCount(count - 1);
    }
  };

  const SelectDate2 = (e) => {
    setDates(e.target.value);
    setBtnPressed(false);
  };

  const SelectDate = (e) => {
    setDates(e.target.value);
    setBtnPressed(true);
  };

  const onSubmit = () => {
    hide ? setCheckOut(true) : setCheckOut(false);

    setPayBtnLoad(true);

    setTimeout(() => {
      setPayBtnLoad(false);
    }, 2000);
  };

  useEffect(() => {
    console.log(utcDate);
    const getBookDes = async () => {
      await axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/bookingOption/checkAvailability?destination_id=${id}&available_date=${utcDate}`
        )
        .then((res) => {
          if (!res.error) {
            setOptions(res.data?.results);
            console.log(res.data?.results);
            setPrice(res.data?.results[0].price);
            setTitle(res.data?.results[0].title);
            setBookingId(res.data?.results[0]._id);
            setDates(res.data?.results[0].start_date);
            setResult(false);
          }
        })
        .catch((error) => {
          if (error.response.data.message.includes("No")) {
            setError(error.response?.data?.message);
            setResult(true);
          }
        });
    };

    getBookDes();
  }, [utcDate, error, result]);

  useEffect(() => {
    {
      // offerPrice
      //   ? setTotalPrice(count * offerPrice)
      setTotalPrice(count * price);
    }
  }, [count, totalPrice, price, offerPrice]);

  return (
    <div className="bookDestination">
      <h4>Prices & Booking</h4>
      <div className="participants">
        <h5>Person</h5>
        <div className="totalParticipants">
          <div>
            <h6>Participant</h6>
            <span>0-99 years</span>
          </div>
          <div className="participantCounter">
            <button onClick={Decrease}>-</button>
            <span>{count}</span>
            <button onClick={Increase}>+</button>
          </div>
        </div>
      </div>

      <div className="datePicker">
        <h5>Date</h5>
        {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            
            openTo="year"
            views={["day"]}
            value={utcDate}
            onChange={(newValue) => {
              setIsLoading(true);
              setUtcDate(newValue.toISOString());
              setDateValue(newValue.toDateString());              
              
              setTimeout(() => {
                setDateChanged(true);
                setIsLoading(false);
              }, 1000);
            }}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider> */}

        <DatesPicker
          selected={startDate}
          onChange={(date) => {
            setStartDate(date);

            setIsLoading(true);

            setUtcDate(date.toISOString());
            setDateValue(date.toDateString());

            setTimeout(() => {
              setDateChanged(true);
              setIsLoading(false);
            }, 1000);
          }}
          minDate={new Date()}
          highlightDates={highlightedDates}
          inline
        />
      </div>

      {isLoading ? (
        <div className="spinnerContainer">
          <CSpinner color="primary" />
        </div>
      ) : (
        <div
          className="availableOptions"
          style={{ display: dateChanged ? "block" : "none" }}
        >
          {result ? (
            <div>
              <h5>{error}</h5>
            </div>
          ) : (
            <>
              <h5>Available Options on {dateValue} </h5>
              <div className="optionCard">
                <div className="option">
                  <div>
                    <span>Option 1</span>
                    <span>Total Price</span>
                  </div>
                  <div className="flexOption">
                    <h6>{title}</h6>
                    <span>₹{totalPrice}</span>
                  </div>
                </div>
                <div className="bookThis">
                  <div>
                    <i className="fa-regular fa-clock"></i>{" "}
                    {options[0]?.no_of_days} days
                  </div>
                  <div className="time">
                    <i className="fa-solid fa-calendar"></i>
                    <div className="timeAvailable">
                      <button
                        className="timeBtn"
                        style={{ background: !btnPressed ? "blue" : "#219653" }}
                        value={options[0]?.start_date}
                        onClick={SelectDate2}
                      >
                        {options[0]?.start_date &&
                          new Date(options[0].start_date).toLocaleString(
                            "en-US",
                            {
                              hour: "numeric",
                              minute: "numeric",
                              hour12: true,
                            }
                          )}

                        {/* {options[0]?.start_date?.slice(11, 13) < 12
                          ? `${options[0]?.start_date?.slice(11, 16)} AM`
                          : `${options[0]?.start_date?.slice(11, 16)} PM`} */}
                      </button>
                      {options?.slice(1, options?.length).map((date) => {
                        return (
                          <button
                            key={date._id}
                            className="timeBtn"
                            value={date?.start_date}
                            onClick={SelectDate}
                            style={{
                              background: btnPressed ? "blue" : "#219653",
                            }}
                          >
                            {/* {date?.start_date?.slice(11, 13) < 12
                              ? `${date?.start_date?.slice(11, 16)} AM`
                              : `${date?.start_date?.slice(11, 16)} PM`} */}

                            {date?.start_date &&
                              new Date(date?.start_date).toLocaleString(
                                "en-US",
                                {
                                  hour: "numeric",
                                  minute: "numeric",
                                  hour12: true,
                                }
                              )}
                          </button>
                        );
                      })}
                    </div>
                  </div>

                  <div
                    className="priceBreakdown"
                    onClick={() => {
                      !show ? setShow(true) : setShow(false);
                    }}
                  >
                    <p>see price breakdown</p>
                    <i
                      className={
                        show ? "fa-solid fa-angle-up" : "fa-solid fa-angle-down"
                      }
                    ></i>
                  </div>
                  <div
                    className="breakdownContent"
                    id="breakdownContent"
                    style={{ display: show ? "block" : "none" }}
                  >
                    <div className="participantTotal">
                      <h6>
                        Participant {count} x ₹{price}
                      </h6>
                      <h6>₹{totalPrice}</h6>
                    </div>
                    <div
                      className="participantTotal"
                      style={{ borderBottom: "none" }}
                    >
                      <h6>Total Price</h6>
                      <h6>₹{totalPrice}</h6>
                    </div>
                    <p>
                      <i className="fa-solid fa-check"></i> All fees and taxes
                      included
                    </p>
                  </div>
                  <AddToCart id={bookingId} persons={count} slug={slug} />
                  <div className="bookBtn" onClick={handlePayOpen}>
                    <h6>BOOK THIS OPTION</h6>
                    <i className="fa-solid fa-arrow-right"></i>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      )}

      <Modal
        open={payModal}
        onClose={handlePayClose}
        style={{ overflow: "scroll" }}
      >
        {Loading ? (
          <div className="forLoading">
            <CSpinner style={{ color: "#ef3f3e" }} />
          </div>
        ) : (
          <div className="paymentModal">
            <div className="checkout">
              <div>
                <h4>Checkout</h4>
                <p>
                  <i className="fa-solid fa-lock"></i> Payment data is encrypted
                  and transmitted securely over SSL.
                </p>
              </div>

              <i className="fa-solid fa-xmark" onClick={handlePayClose}></i>
            </div>
            <div className="destinationOverview">
              <div className="overviewImg">
                <img src={`${process.env.REACT_APP_BASE_URL}${image}`} alt="" />
              </div>
              <div className="destinationInfo">
                <h5>{title}</h5>
                <h6 className="h6">
                  <i className="fa-solid fa-share-nodes"></i> {title}
                </h6>
                <h6 className="h6">
                  <i className="fa-solid fa-calendar-day"></i>{" "}
                  {dates?.slice(0, 10)} -
                  {dates?.slice(11, 13) < 12
                    ? `${dates?.slice(11, 16)} AM`
                    : `${dates?.slice(11, 16)} PM`}
                </h6>
                <h6 className="h6">
                  <i className="fa-solid fa-clock"></i> {options[0]?.no_of_days}{" "}
                  days
                </h6>

                <div className="priceBreakdown2">
                  <p>Price Breakdown</p>
                  <div className="breakdownContent2">
                    <div className="participantTotal2">
                      <h6>
                        Participant {count} x ₹{price}
                      </h6>
                      <h6>₹{totalPrice}</h6>
                    </div>
                    <div
                      className="participantTotal2"
                      style={{ borderBottom: "none" }}
                    >
                      <h6>Total Price</h6>
                      <h6>₹{totalPrice}</h6>
                    </div>
                    <p>
                      <i className="fa-solid fa-check"></i> All fees and taxes
                      included
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="paymentContainer">
              <div className="paymentContact">
                <h5>Contact</h5>
                <div className="contactInput">
                  <i className="fa-solid fa-user"></i>
                  <input
                    type="text"
                    placeholder="Full name"
                    {...register("fullname", {
                      required: "Full name is required",
                      pattern: {
                        value: /^[a-zA-Z][a-zA-Z ]+$/,
                        message: "Invalid Full name",
                      },
                    })}
                  />
                </div>
                <p className="perr">{errors.fullname?.message}</p>

                <div className="contactInput">
                  <i className="fa-solid fa-envelope"></i>
                  <input
                    type="email"
                    placeholder="Email"
                    {...register("email", {
                      required: "Email is required",
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: "invalid email address",
                      },
                    })}
                  />
                </div>
                <p className="perr">{errors.email?.message}</p>

                <p>
                  After successful payment, tickets will be sent instantly to
                  the given email address.
                </p>

                <div className="contactInput">
                  <CountryCode />
                  <i className="fa-solid fa-phone"></i>
                  <input
                    type="text"
                    placeholder="Phone number"
                    {...register("phoneno", {
                      required: "Phone number is required",
                    })}
                  />
                </div>
                <p className="perr">{errors.phoneno?.message}</p>

                <p>
                  Local partner can contact you in case of important
                  notifications or changes.
                </p>
              </div>
              <div className="paymentDetails">
                <h5>Payment</h5>
                <div className="paymentCard">
                  <div className="cardTitle">
                    <input
                      type="radio"
                      name="paymentMethod"
                      onClick={() => {
                        setHide(false);
                        setCheckOut(false);
                      }}
                    />
                    <h6>Credit/Debit Card</h6>
                    <div className="cardImages">
                      <img
                        src="https://checkoutshopper-live.adyen.com/checkoutshopper/images/logos/visa.svg"
                        alt="VISA"
                        title="VISA"
                      />
                      <img
                        src="https://checkoutshopper-live.adyen.com/checkoutshopper/images/logos/mc.svg"
                        alt="MasterCard"
                        title="MasterCard"
                      />
                      <img
                        src="https://checkoutshopper-live.adyen.com/checkoutshopper/images/logos/amex.svg"
                        alt="AMEX"
                        title="AMEX"
                      />
                      <img
                        src="https://checkoutshopper-live.adyen.com/checkoutshopper/images/logos/bcmc.svg"
                        alt="bcmc"
                        title="bcmc"
                      />
                      <img
                        src="https://checkoutshopper-live.adyen.com/checkoutshopper/images/logos/jcb.svg"
                        alt="JCB"
                        title="JCB"
                      />
                      <img
                        src="https://checkoutshopper-live.adyen.com/checkoutshopper/images/logos/discover.svg"
                        alt="discover"
                        title="discover"
                      />
                    </div>
                  </div>

                  <div
                    className="cardItself"
                    style={{ display: !hide ? "block" : "none" }}
                  >
                    <div className="cardLine"></div>

                    <div className="cardInput">
                      <i className="fa-solid fa-credit-card"></i>
                      <input
                        type="number"
                        placeholder="0000 0000 0000 0000"
                        {...register("cardno", {
                          required: hide ? false : "Card number is required",
                        })}
                        onChange={(e) => {
                          e.target.value = e.target.value.slice(0, 16);
                        }}
                      />
                    </div>

                    <div className="cardInput">
                      <i className="fa-regular fa-user"></i>
                      <input
                        type="text"
                        placeholder="Card holder name"
                        {...register("holdername", {
                          required: hide
                            ? false
                            : "Card holder name is required",
                          pattern: {
                            value: /^[a-zA-Z][a-zA-Z ]+$/,
                            message: "Invalid Card holder name",
                          },
                        })}
                      />
                    </div>

                    <div className="cardTwoInputs">
                      <div className="cardInput">
                        <i className="fa-regular fa-calendar"></i>
                        <input
                          type="number"
                          placeholder="MM/YY"
                          {...register("exdate", {
                            required: hide
                              ? false
                              : "Expiration date is required",
                          })}
                          onChange={(e) => {
                            e.target.value = e.target.value.slice(0, 4);
                          }}
                        />
                      </div>

                      <div className="cardInput">
                        <i className="fa-solid fa-lock"></i>
                        <input
                          type="password"
                          placeholder="CVC"
                          {...register("cvcno", {
                            required: hide ? false : "CVC number is required",
                          })}
                        />
                      </div>
                    </div>
                  </div>
                  <div style={{ display: hide ? "none" : "block" }}>
                    <p className="perr">{errors.cardno?.message}</p>
                    <p className="perr">{errors.holdername?.message}</p>
                    <p className="perr">{errors.exdate?.message}</p>
                    <p className="perr">{errors.cvcno?.message}</p>
                  </div>
                </div>

                <div
                  className="paymentCard"
                  style={{
                    background: "white",
                    borderBottomLeftRadius: "20px",
                    borderBottomRightRadius: "20px",
                  }}
                >
                  <div className="cardTitle cardTitle2">
                    <div>
                      <input
                        type="radio"
                        value="paypal"
                        name="paymentMethod"
                        onClick={() => {
                          setHide(true);
                        }}
                      />
                      <h6>Paypal</h6>
                    </div>
                    <div className="cardImages">
                      <img
                        src="https://checkoutshopper-live.adyen.com/checkoutshopper/images/logos/paypal.svg"
                        alt="VISA"
                        title="VISA"
                      />
                    </div>
                  </div>
                  <span style={{ display: hide ? "block" : "none" }}>
                    You will be redirected to a new window to complete the
                    payment.
                  </span>
                </div>

                <div className="paymentCancel">
                  <i className="fa-solid fa-shield"></i>
                  Cancel up to 24 hours in advance to receive a full refund.
                </div>

                <div className="cardCheckboxes">
                  <div className="cardCheckbox">
                    <input type="checkbox" />
                    <p>I would like to sign up for newsletter.</p>
                  </div>

                  <div className="cardCheckbox" style={{ marginTop: "10px" }}>
                    <input
                      type="checkbox"
                      {...register("checkbox", {
                        required: "Terms and conditions acceptance is required",
                      })}
                    />
                    <p>
                      I accept{" "}
                      <a href="/terms-and-conditions" target="blank">
                        terms and conditions
                      </a>{" "}
                      and{" "}
                      <a href="/privacy-policy" target="blank">
                        privacy policy
                      </a>
                    </p>
                  </div>
                  <p className="perr">{errors.checkbox?.message}</p>
                </div>

                {checkout && <Paypal />}

                <button onClick={handleSubmit(onSubmit)}>
                  {payBtnLoad ? (
                    <>
                      <CSpinner
                        color="primary"
                        style={{
                          width: "19px",
                          height: "19px",
                          background: "transparent",
                        }}
                      />
                      Loading...
                    </>
                  ) : (
                    <>BOOK & PAY NOW - ₹{totalPrice}</>
                  )}
                </button>
              </div>
            </div>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default BookDestination;
