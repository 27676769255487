import { useState, useEffect } from 'react';
import axios from "axios";
import WishlistModal from 'src/pages/Wishlist/WishlistModal';
import { useNavigate } from 'react-router-dom';
import './Offers.css'


const Offers = (props) => {

    const navigate = useNavigate();
    const token = localStorage.getItem("token");

    const [openModal, setModalOpen] = useState(false);
    const [clicked, setClicked] = useState(false);


    const [wishlists, setWishlists] = useState([]);
    let present = false;

    let desId = 0;

    const handleModalOpen = () => {
        token ? setModalOpen(true) : navigate('/login')
    };
    const handleModalClose = (value) => setModalOpen(value);


    const Authaxios = axios.create({
        baseURL: process.env.REACT_APP_BASE_URL,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    });

    
    useEffect(() => {

    Authaxios.get(`/wishlist/getAllListByUser`)
      .then((res) => {
        setWishlists(res.data?.results);
        console.log(res.data)
      })
      .catch((error) => error);

 },[])

    wishlists.forEach((item) => {
    
        item?.items.forEach((des) => {
            if(des.destination_id?._id === props.id){
                present = true
                desId = des?._id
            }
        })
        
    })

    const removeDestination = () => {
        setClicked(true)
        Authaxios.delete(`/wishlist/removeDestinationFromList/${desId}`).then((res) => {

            if(!res.error){
                console.log(res.data)
            }

        }).catch((err) => {
            console.log(err)
        })
    }


    

    return (
        <>
            <div className='experience' key={props.id}>
            {present ? <i className={clicked ? "fa-regular fa-heart" : "fa-solid fa-heart"} style={{color: clicked ? "white" : "rgb(239, 63, 62)"}} onClick={clicked ? handleModalOpen : removeDestination}></i> : <i className={props.icon} onClick={handleModalOpen} ></i>}
                <div onClick={() => (
                    window.location.href = `/destination/${props.slug}`
                )}><img src={`${process.env.REACT_APP_BASE_URL}${props.image}`} alt='' /></div>    
                <div className='price'>
                    
                    <div className='offertitle' >OFFER</div>
                    <div className='pricetitleContianer'><div className='pricetitle'>{props.price2 ? `$${props.price1}` : ""}</div>{props.price2 ? `$${props.price2}` : `$${props.price1}`}</div>
                </div>
                <div className='offerInfo'>
                    <div className='ratingbox' ><a href={`/destination/${props.slug}`}><h6>{props.title?.slice(0,15)}</h6></a>
                    <div className='rating'>
                    { props.star === 5 || props.star === 5.1 || props.star === 5.2 || props.star ===  5.3 || props.star ===  5.4 || props.star ===  5.5  ? (
            <>
                <i className="fa-solid fa-star"></i>
                <i className="fa-solid fa-star"></i>
                <i className="fa-solid fa-star"></i>
                <i className="fa-solid fa-star"></i>
                <i className='fa-solid fa-star'></i>
            </>
          ) :
          props.star === 4 || props.star === 4.1 || props.star === 4.2 || props.star ===  4.3 || props.star ===  4.4 || props.star ===  4.5 ? ( 
        <>
          <i className="fa-solid fa-star"></i>
          <i className="fa-solid fa-star"></i>
          <i className="fa-solid fa-star"></i>
          <i className="fa-solid fa-star"></i>
          <i className='fa-regular fa-star'></i>
        </>  
            ) : props.star === 3 || props.star === 3.1 || props.star === 3.2 || props.star ===  3.3 || props.star ===  3.4 || props.star ===  3.5  ? (
            <>
                <i className="fa-solid fa-star"></i>
                <i className="fa-solid fa-star"></i>
                <i className="fa-solid fa-star"></i>
                <i className="fa-regular fa-star"></i>
                <i className='fa-regular fa-star'></i>
            </>
            ) : props.star === 2 || props.star === 2.1 || props.star === 2.2 || props.star ===  2.3 || props.star ===  2.4 || props.star ===  2.5 || props.star ===  2.8  ? (
                <>
                    <i className="fa-solid fa-star"></i>
                    <i className="fa-solid fa-star"></i>
                    <i className="fa-regular fa-star"></i>
                    <i className="fa-regular fa-star"></i>
                    <i className='fa-regular fa-star'></i>
                </> 
            ) : props.star === 1 || props.star === 1.1 || props.star === 1.2 || props.star ===  1.3 || props.star ===  1.4 || props.star ===  1.5  ? (
                <>
                    <i className="fa-solid fa-star"></i>
                    <i className="fa-regular fa-star"></i>
                    <i className="fa-regular fa-star"></i>
                    <i className="fa-regular fa-star"></i>
                    <i className='fa-regular fa-star'></i>
                </>    
            ) : (<>
                    <i className="fa-regular fa-star"></i>
                    <i className="fa-regular fa-star"></i>
                    <i className='fa-regular fa-star'></i>
                    <i className="fa-regular fa-star"></i>
                    <i className="fa-regular fa-star"></i>     
            </>)}
                    </div>
                    </div>
                    <p className='detail' style={{margin:"0px",fontWeight:"lighter"}}>{props.category}</p>
                    <p className='detail' >{props.detail?.slice(0, 30)}...</p>
                    <div className='tripduration' >
                        <h6 style={{color:"grey"}}>{props.days} days | {props.nights} nights</h6>
                        <h6 >{props.persons} persons</h6>
                    </div>
                </div>
            </div>

            <WishlistModal open={openModal} id={props.id} close={handleModalClose}/>
        </>
    )
}

export default Offers;