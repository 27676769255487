import { useState,useEffect } from 'react';
import { onGetPopularOffers } from 'src/services/homeDestinationsApi';
import '../i18n/config';
import { useTranslation } from 'react-i18next';
import Banner from '../components/Banner/Banner';
import Offers from '../components/Offers/Offers';
import Subscribe from '../components/Subscribe/Subscribe';
import Carousel from 'react-elastic-carousel';
import '../App.css';



const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2, itemsToScroll: 2 },
    { width: 768, itemsToShow: 3 },
    { width: 1200, itemsToShow: 3 }
  ];


const Home = () => {


    const { t } = useTranslation(); 
    const [destinations,setDestinations] = useState([]);
    

    useEffect(() => {

        onGetPopularOffers().then((res) => {
            if(!res.error){
                setDestinations(res.data.result)
                console.log(res.data.result)
            }
        })
    },[])

    const newOffers = destinations.slice().reverse();


    return (
        <>
            <Banner />
            
            <div className="strechContainer">
            <div className='strechSection offerSection'>
                <div style={{display:"flex", flexDirection:"column", alignItems:"center",marginLeft:"30px"}} className="offer-text">
                    <div style={{background:"#EF3F3E", width:"200px", display:"flex", justifyContent:"center" ,color:"white", borderRadius:"10px", padding:"2px", fontWeight:"bold"}}>{t("newoffers")}</div>
                    <h4 style={{marginTop:"10px", fontWeight:"bolder"}}>{t("populartours")}</h4>
                    <h5 style={{marginBottom:"60px", marginTop:"10px", fontWeight:"bold", color:"rgba(128, 128, 128, 0.603)", fontSize:"17px"}}>{t("experience")}</h5>
                </div>
             <div style={{display:"flex"}}>
            <Carousel breakPoints={breakPoints} >
                {newOffers.slice(0,4).map((des) => {
                    return (<Offers id={des._id} icon={"fa-regular fa-heart"} slug={des.slug} title={des.title} category={des.destination_category_id?.title} detail={des.short_description} price1={des.price} price2={des.offer_price} days={des.tour_in_days} nights={des.tour_in_nights} persons={des.tour_persons} image={des.image} star={des.star}/>)
                })}         
            </Carousel>
            
            </div>
            </div>
            <Subscribe />
            </div>
            
        </>
    )
}

export default Home;