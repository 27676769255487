import "./UserDashboard.css";

const UserDashboard = () => {
  return (
    <div className="userdashboard">
      <img
        src="https://themes.pixelstrap.com/rica/assets/images/inner-bg.jpg"
        alt=""
      />
      <div className="section">
        <div className="sideContain">
          <div className="sidebar">
            <div className="col">
              <div className="pro_sticky_info">
                <div className="dashboard-sidebar">
                  <div className="profile-top">
                    <div className="profile-image">
                      <img
                        src="https://themes.pixelstrap.com/rica/assets/images/avtar/1.jpg"
                        className="img-fluid blur-up lazyloaded"
                        alt=""
                      />
                      <a className="profile-edit" href="#home">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="feather feather-edit"
                        >
                          <path d="M20 14.66V20a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h5.34"></path>
                          <polygon points="18 2 22 6 12 16 8 16 8 12 18 2"></polygon>
                        </svg>
                      </a>
                    </div>
                    <div className="profile-detail">
                      <h5>mark enderess</h5>
                      <h6>+91 123 - 456 - 7890</h6>
                      <h6>mark.enderess@mail.com</h6>
                    </div>
                  </div>
                  <div className="faq-tab">
                    <ul className="nav nav-tabs" id="top-tab" role="tablist">
                      <li className="nav-item">
                        <a
                          data-bs-toggle="tab"
                          className="nav-link"
                          href="#dashboard"
                        >
                          dashboard
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          data-bs-toggle="tab"
                          className="nav-link"
                          href="#profile"
                        >
                          profile
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          data-bs-toggle="tab"
                          className="nav-link"
                          href="#bookings"
                        >
                          bookings
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          data-bs-toggle="tab"
                          className="nav-link"
                          href="#added-card"
                        >
                          cards &amp; payment
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          data-bs-toggle="tab"
                          className="nav-link"
                          href="#bookmark"
                        >
                          bookmark
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          data-bs-toggle="tab"
                          className="nav-link"
                          href="#security"
                        >
                          security
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="content">
          <div className="completeProfile">
            <h5 style={{ fontWeight: "bold" }}>welcome! Mark Enderess</h5>
            <p>
              you have completed 70% of your profile. add basic info to complete
              profile.
            </p>
            <div className="buttonFlex">
              <div className="button">
                <i className="fa-regular fa-square-check"></i> Verified Email ID
              </div>
              <div className="button">
                <i className="fa-regular fa-square-check"></i> Verified Phone
                Number
              </div>
              <div className="button">
                <i className="far fa-window-close"></i> Complete Basic Info
              </div>
            </div>
          </div>

          <div className="counterContainer">
            <div className="boxes">
              <div className="counterBox">
                <img
                  src="https://themes.pixelstrap.com/rica/assets/images/icon/hotel.png"
                  alt=""
                />
                <h3>25</h3>
                <h5>hotel booked</h5>
              </div>
              <div className="counterBox">
                <img
                  src="https://themes.pixelstrap.com/rica/assets/images/icon/flight.png"
                  alt=""
                />
                <h3>12</h3>
                <h5>flight booked</h5>
              </div>
            </div>

            <div className="boxes">
              <div className="counterBox">
                <img
                  src="https://themes.pixelstrap.com/rica/assets/images/icon/taxi.png"
                  alt=""
                />
                <h3>50</h3>
                <h5>car booked</h5>
              </div>
              <div className="counterBox">
                <img
                  src="https://themes.pixelstrap.com/rica/assets/images/icon/food.png"
                  alt=""
                />
                <h3>40</h3>
                <h5>food ordered</h5>
              </div>
            </div>
          </div>
          <div className="lastSection">
            <div className="analytic">
              <div className="info">
                <button
                  className="total"
                  onMouseEnter={() => {
                    document.getElementById("total").style.display = "block";
                    document.getElementById("upcomingperc").style.display =
                      "none";
                    document.getElementById("cancelledperc").style.display =
                      "none";
                    document.getElementById("completedperc").style.display =
                      "none";
                  }}
                >
                  Total
                </button>
                <button
                  className="completed"
                  onMouseEnter={() => {
                    document.getElementById("completedperc").style.display =
                      "block";
                    document.getElementById("total").style.display = "none";
                    document.getElementById("upcomingperc").style.display =
                      "none";
                    document.getElementById("cancelledperc").style.display =
                      "none";
                  }}
                >
                  Completed
                </button>
                <button
                  className="upcoming"
                  onMouseEnter={() => {
                    document.getElementById("upcomingperc").style.display =
                      "block";
                    document.getElementById("total").style.display = "none";
                    document.getElementById("completedperc").style.display =
                      "none";
                    document.getElementById("cancelledperc").style.display =
                      "none";
                  }}
                >
                  Upcoming
                </button>
                <button
                  className="cancelled"
                  onMouseEnter={() => {
                    document.getElementById("cancelledperc").style.display =
                      "block";
                    document.getElementById("upcomingperc").style.display =
                      "none";
                    document.getElementById("completedperc").style.display =
                      "none";
                    document.getElementById("total").style.display = "none";
                  }}
                >
                  Cancelled
                </button>
              </div>
              <div className="percentage">
                <div className="total" id="total">
                  <h4>Total</h4>
                  <h3>80</h3>
                </div>

                <div className="completedperc" id="completedperc">
                  <h4>Completed</h4>
                  <h3>50%</h3>
                </div>

                <div className="upcomingperc" id="upcomingperc">
                  <h4>Upcoming</h4>
                  <h3>30%</h3>
                </div>

                <div className="cancelledperc" id="cancelledperc">
                  <h4>Cancelled</h4>
                  <h3>20%</h3>
                </div>
              </div>
            </div>
            <div className="recentActivity">
              <div>
                <h6>recent activityName</h6>
                <li>
                  <span>
                    <i className="fa-solid fa-plane"></i> Paris To Dubai
                  </span>
                  <span>3 Days Ago</span>
                </li>
                <li>
                  <span>
                    <i className="fa-solid fa-plane"></i> Paris To Dubai
                  </span>
                  <span>23 June</span>
                </li>
                <li style={{ borderColor: "skyblue" }}>
                  <span>
                    <i className="fa-solid fa-hotel"></i> Hotel Sea View
                  </span>
                  <span>20 April</span>
                </li>
                <li style={{ borderColor: "gold" }}>
                  <span>
                    <i className="fa-solid fa-car"></i> Paris To Toulouse
                  </span>
                  <span>12 Feb</span>
                </li>
                <li style={{ borderColor: "skyblue" }}>
                  <span>
                    <i className="fa-solid fa-plane"></i> Paris To Dubai
                  </span>
                  <span>14 Jan</span>
                </li>
                <li>
                  <span>
                    <i className="fa-solid fa-hotel"></i> Hotel Sea View
                  </span>
                  <span>12 Jan</span>
                </li>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserDashboard;
