import Paypal from 'src/components/PayPal/PayPal';
import { useState } from 'react';
import './Cart.css';

const CheckOut = ({des, gif, amount,discount}) => {

    const [checkOut, setCheckOut] = useState(false);


    return (
        <div className='checkoutConatiner' style={{marginTop: checkOut ? "20px" : "0px"}}>
        {checkOut && <Paypal />}
        <div className='cartCheckout'>
                <div className='checkoutTitle'>
                    <h5>Total({des?.length + gif?.length} item):</h5>
                    <div className='checkoutPrice'>
                        <div>{discount && <span>₹{amount}</span>}<h5>₹{discount ? discount : amount}</h5></div>
                        <p>All taxes and fees included</p>
                    </div>
                </div>
                <button onClick={() => {
                    !checkOut ? setCheckOut(true) : setCheckOut(false)
                }}>Checkout</button>
                <span><i className="fa-solid fa-calendar-check"></i> Cancel before 8:30 AM  on August 27 for a full refund.</span>
        </div>
        </div>
    )
}

export default CheckOut;