import { useState } from 'react';
import './DestinationDetail.css';


const FullDescription = ({description}) => {

    const [showMore, setShowMore] = useState(false);

    const showMoreDes = () => {
        !showMore ? setShowMore(true) : setShowMore(false)
    }

    return (
        <div className='expHighlights'>
            <h6>Full description</h6>

            <div className='expDetails'>
                <p>{description?.slice(0,showMore ? description?.length : 172)} {showMore ? <></> : <>...</>}</p>
                {description?.length > 172 ? <span onClick={showMoreDes}>{!showMore ? `Show more ▼` : `Show less ▲`}</span> : <></>}
            </div>

        </div>
    )
}

export default FullDescription;