import React, { useRef, useEffect } from "react";

export default function Paypal() {
  const paypal = useRef();

  useEffect(() => {

    if(window.myButton) window.myButton.close();
    window.myButton = window.paypal
      .Buttons({
        createOrder: (data, actions, err) => {
          return actions.order.create({
            intent: "CAPTURE",
            purchase_units: [
              {
                description: "Destination",
                amount: {
                  currency_code: "CAD", 
                  value: 650,
                },
              },
            ],
          });
        },
        onApprove: async (data, actions) => {
          const order = await actions.order.capture();
          console.log(order);
        },
        onError: (err) => {
          console.log(err);
        },
        style: {
            color:  'blue',
            shape:  'pill',
            label:  'pay',
            height: 40
        },
      })
     window.myButton.render(paypal.current);
  }, []);



  return (
    <div>
      <div ref={paypal}></div>
    </div>
  );
}