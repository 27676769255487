import '../../i18n/config';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import './Banner.css';


const Banner = () => {

    const { t } = useTranslation();

    const navigate = useNavigate();

    return (
        
        <div className='banner'>
            <img id='banner_img' src='https://themes.pixelstrap.com/rica/assets/images/tour-bg.jpg' alt='' />
        <div className='strechContainer' style={{position:"relative", top:"-55%"}}>    
        <div className=' strechSection' style={{position:"relative"}}>
            <div className='chngImg'>
                <li className='one' style={{fontSize:"30px", letterSpacing:"1.5px"}} onClick={() => {document.getElementById('banner_img').src='https://themes.pixelstrap.com/rica/assets/images/tour-bg.jpg'}}>01</li>
                <li className='two' style={{color:"rgba(255, 255, 255, 0.548)", letterSpacing:"1.5px"}} onClick={() => {document.getElementById('banner_img').src='https://themes.pixelstrap.com/rica/assets/images/tour-bg1.jpg'}}>02</li>
                <li className='three' style={{color:"rgba(255, 255, 255, 0.548)", letterSpacing:"1.5px"}} onClick={() => {document.getElementById('banner_img').src='https://themes.pixelstrap.com/rica/assets/images/tour-bg.jpg'}}>03</li>
            </div>
           
        <div className='journeyContain'><h6><p className='vl'></p>{t("journey")}</h6></div>
        <div className='search'>
            <div>
            <input type='text' placeholder="Where to" />
            <i style={{left:"20%"}} className="fa-solid fa-flag"></i>
            </div>
            <div >
            <input type='text' placeholder="When" />
            <i style={{left:"70%"}} className="fa-solid fa-calendar-day"></i>
            </div>
          
            <button onClick={() => {
                navigate("/destinations")
            }}>{t("search")}</button>
        </div>
        </div>
        </div>
        </div>

    )
}

export default Banner;