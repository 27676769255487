import React from "react";
import { Navigate } from "react-router-dom";
import { getTokenFromLocalSorage } from "../utils/localStorage";
import AdminLogin from "../pages/login/Login";

function AuthRoute() {
  const token = getTokenFromLocalSorage();
  return token ? <Navigate to="admin/dashboard" replace /> : <AdminLogin />;
}

export default AuthRoute;
