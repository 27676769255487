import {useState, useEffect} from 'react';
import { useParams,Link } from 'react-router-dom';
import axios from 'axios';
import "./EmailVerify.css";

const EmailVerify = () => {

    const [validUrl, setValidUrl] = useState(false);
    const [msg, setMsg] = useState("");
    const params = useParams();

    useEffect(() => {
        const VerifyEmailUrl = async () => {
            try {
                const url = `${process.env.REACT_APP_BASE_URL}/verify/${params.id}`
                const {data} = await axios.get(url);
                setMsg(data.message)
                setValidUrl(true)
                
            } catch (error) {
                console.log(error)
                setValidUrl(false)
            }
        }

        VerifyEmailUrl();

    },[params])


    return (
        <div className='emailVerify'>
        
        {validUrl?(
            <div className='success'>
              <img src="https://themes.pixelstrap.com/rica/assets/images/tour/booking-success.png" alt='' />   
                <h1>{msg}</h1>
                <Link to="/login"><button>Login</button></Link>
            </div>
        ):(
            <div className='failure'>
               <img src="https://themes.pixelstrap.com/rica/assets/images/icon/failure-icon.png" alt='' />
            <h1>Error 404 Page Not Found</h1>
            <Link to="/"><button>Home Page</button></Link>
            </div>
        )}

        </div>
    )
}

export default EmailVerify;