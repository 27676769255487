import './DestinationDetail.css';

const AboutThisDestination = ({freeCancel, payLater}) => {

    return (
        <div className='reuseableContainer'>
            {freeCancel === true && <div className='aboutThis'>
                <i className='fa-regular fa-calendar-check'></i>
                <div>
                <h6>Free cancellation</h6>
                <p>Cancel up to 24 hours in advance to receive a full refund</p>
                </div>
            </div>}
           
            {payLater === true && <div className='aboutThis'>
                <i className='fa-solid fa-money-check'></i>
                <div>
                <h6>Pay later</h6>
                <p>Pay later option is available</p>
                </div>
            </div>}
       
        </div>
    )
}


export default AboutThisDestination;