// this file includes the admin api functions but not included games menu api functions

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const adminApiSlice = createApi({
    reducerPath: 'adminApi',
    baseQuery: fetchBaseQuery({
        baseUrl: "http://44.227.211.59:8000/api/",
        prepareHeaders: (headers) => {
            const adminToken = JSON.parse(localStorage.getItem('tokenAdmin'));
            headers.set('Authorization', `Bearer ${adminToken}`)
            return headers
        },
    }),
    endpoints: builder => ({
        getAccountStatement: builder.query({
            query: () => ({
                url: 'get-account-statement',
                method: 'GET',

            })
        }),
        getBids: builder.query({
            query: () => ({
                url: 'get-bid',
                method: 'GET',

            })
        })
    })
})

export const { useGetAccountStatementQuery, useGetBidsQuery } = adminApiSlice