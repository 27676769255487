import { useState, useEffect } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import "./Contact.css";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CallIcon from "@mui/icons-material/Call";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import EmailIcon from "@mui/icons-material/Email";
import { Stack, Button } from "@mui/material";
import Grid from "@mui/material/Grid";
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
  CAlert,
} from "@coreui/react";
import { CSpinner } from "@coreui/react";
import { style } from "@mui/system";

const ContactUs = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [msg, setMsg] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [contact, setContact] = useState("");
  const [isBookingId, setIsBookingId] = useState(false);
  const [isDisplay, setIsDisplay] = useState(false);
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    booking_id: "",
    name: "",
    email: "",
    phone: "",
    message: "",
    image: "",
  });

  const handleForm = (e) => {
    setIsBookingId(e);
    setIsDisplay(true);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleFileChange = (event) => {
    if (event.target.files[0]) {
      setValues({
        ...values,
        ["image"]: event.target.files[0],
      });
    }
  };

  // const Authaxios = axios.create({
  //   baseURL: process.env.REACT_APP_BASE_URL,
  //   headers: {
  //     Authorization: `Bearer ${localStorage.getItem("token")}`,
  //   },
  // });

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/contact/getContact`)
      .then((response) => {
        setContact(response.data);
      })
      .catch((error) => error);
  }, []);

  const onSubmit = async () => {
    setLoading(true);
    let formData = new FormData();
    formData.append("booking_id", values.booking_id);
    formData.append("name", values.name);
    formData.append("email", values.email);
    formData.append("phone", values.phone);
    formData.append("message", values.message);
    formData.append("attachment", values.image);

    await axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/contact/listContactSupportEmail`,
        formData
      )
      .then((res) => {
        console.log(res);
        setLoading(false);
        setMsg(res.data.message);
        document.getElementById("notification").style.display = "block";
        setTimeout(() => {
          document.getElementById("notification").style.display = "none";
          setIsDisplay(false);
          setMsg("");
          setValues({
            booking_id: "",
            name: "",
            email: "",
            phone: "",
            message: "",
            image: "",
          });
        }, 3000);
      })
      .catch((error) => {
        setLoading(false);
        document.getElementById("errnotification").style.display = "block";
        setErrMsg(error.response.data.message);
        setTimeout(() => {
          setErrMsg("");
          document.getElementById("errnotification").style.display = "none";
        }, 4000);
      });
  };

  return (
    <div>
      <img
        style={{ width: "100%", height: "400px" }}
        src="https://images.unsplash.com/photo-1497864149936-d3163f0c0f4b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=869&q=80"
        alt=""
      />
      <div className="loginbox contact-box">Contact Us</div>

      {contact?.results ? (
        <div className="container center">
          <h1 style={{ padding: "30px 0" }} className="contact-info">
            Welcome to the Help Center
          </h1>
          <div style={{ paddingBottom: "100px" }}>
            <h3>Browse by topic</h3>

            {contact?.results?.contactTopic?.map((no, index) => {
              return (
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>{no.topic}</Typography>
                  </AccordionSummary>

                  <AccordionDetails>
                    {no?.contact_qa?.map((subNo, index) => {
                      return (
                        <Typography>
                          <p>
                            <Accordion>
                              <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel3a-content"
                                id="panel3a-header"
                              >
                                <Typography>{subNo.question}</Typography>
                              </AccordionSummary>
                              <AccordionDetails>
                                <AccordionDetails>
                                  <Typography>
                                    <p>
                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html: subNo.answer,
                                        }}
                                      />
                                    </p>
                                  </Typography>
                                </AccordionDetails>
                              </AccordionDetails>
                            </Accordion>
                          </p>
                        </Typography>
                      );
                    })}
                  </AccordionDetails>
                </Accordion>
              );
            })}

            <h4 style={{ padding: "10px 0 10px 0" }}>Still need help?</h4>

            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography>
                  <EmailIcon />
                  <b>Send an email</b> <br />{" "}
                  <span className="get-para">
                    Get in touch with our customer service team{" "}
                  </span>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography style={{ textAlign: "center" }}>
                  <div className="wp-text">Do you have a Petra Booking ID?</div>

                  <Stack
                    spacing={2}
                    direction="row"
                    container
                    justify="center"
                    className="wp-bt"
                  >
                    <Button
                      variant="contained"
                      onClick={() => handleForm(true)}
                    >
                      Yes
                    </Button>
                    <Button
                      variant="contained"
                      onClick={() => handleForm(false)}
                    >
                      No
                    </Button>
                  </Stack>

                  {isDisplay ? (
                    <>
                      <div className="inputContainer">
                        <p
                          className="notification"
                          style={{ color: "green" }}
                          id="notification"
                        >
                          {msg}
                        </p>
                        <p
                          className="errnotification"
                          style={{ color: "red" }}
                          id="errnotification"
                        >
                          {errMsg}
                        </p>
                        {isBookingId ? (
                          <div class="form-group book-fm">
                            <div className="text-frm">
                              {" "}
                              These details must match those associated with the
                              booking reference in order for us to be able to
                              respond to your request.
                            </div>{" "}
                            <br />
                            <label className="bkyt">Booking reference</label>
                            <input
                              type="text"
                              placeholder="Booking reference"
                              autoComplete="off"
                              {...register("booking_id", {
                                required: "Booking reference is required",
                                // pattern: {
                                //   value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                //   message: "invalid email address",
                                // },
                              })}
                              value={values.booking_id}
                              name="booking_id"
                              onChange={handleChange}
                            />
                            <p>{errors.booking_id?.message}</p>
                          </div>
                        ) : (
                          ""
                        )}

                        <div class="form-group book-fm">
                          <label className="bkyt-2">Name</label>
                          <input
                            type="text"
                            placeholder="Name"
                            autoComplete="off"
                            {...register("name", {
                              required: "Name is required",
                            })}
                            value={values.name}
                            name="name"
                            onChange={handleChange}
                          />
                          <p>{errors.name?.message}</p>
                        </div>

                        <div class="form-group book-fm">
                          <label className="bkyt">Email address</label>
                          <input
                            type="text"
                            placeholder="Enter email"
                            autoComplete="off"
                            {...register("email", {
                              required: "Email is required",
                              pattern: {
                                value:
                                  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                message: "invalid email address",
                              },
                            })}
                            value={values.email}
                            name="email"
                            onChange={handleChange}
                          />
                          <p>{errors.email?.message}</p>
                        </div>

                        <div class="form-group book-fm">
                          <label className="bkyt">Phone Number</label>
                          <input
                            type="number"
                            placeholder="Phone number"
                            autoComplete="off"
                            {...register("phone", {
                              // required: "Phone Number is required",
                            })}
                            value={values.phone}
                            name="phone"
                            onChange={handleChange}
                          />
                          <p>{errors.phone?.message}</p>
                        </div>

                        <div class="form-group book-fm">
                          <label className="bkyt">Message</label>
                          <textarea
                            type="text"
                            placeholder="Message"
                            autoComplete="off"
                            value={values.message}
                            name="message"
                            onChange={handleChange}
                          />
                        </div>

                        <div class="form-group book-fm fm-book">
                          <label className="bkyt">
                            Attachment (Pdf or Image)
                          </label>
                          <input
                            onChange={handleFileChange}
                            name="file"
                            type="file"
                            id="image"
                            accept="application/pdf, image/jpg, image/png, image/jpeg, image/gif"
                            label="Select Image (.pdf .jpg, .jpeg, .gif, .png) Upto 4MB"
                            required
                          />
                        </div>
                        {msg.includes("exists") ? (
                          <p style={{ color: "red" }}>{msg}</p>
                        ) : (
                          <></>
                        )}

                        <div class="form-group book-fm">
                          {loading ? (
                            <button
                              className="logbtn"
                              style={{ marginTop: "10px", marginLeft: "0px" }}
                              disabled
                            >
                              <CSpinner
                                color="primary"
                                style={{ width: "19px", height: "19px" }}
                              />
                              Loading...
                            </button>
                          ) : (
                            <button
                              className="logbtn"
                              style={{ marginTop: "10px", marginLeft: "0px" }}
                              onClick={handleSubmit(onSubmit)}
                            >
                              Submit Request
                            </button>
                          )}
                        </div>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </Typography>
              </AccordionDetails>
            </Accordion>

            <div style={{ padding: "10px" }}></div>

            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography>
                  <CallIcon />
                  <b>Call customer service</b> <br />{" "}
                  <span className="get-para">
                    Talk to us over the phone 24/7{" "}
                  </span>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                {contact?.results?.contactSupport?.map((no, index) => {
                  return (
                    <Typography>
                      <Grid
                        className="call-para"
                        container
                        spacing={2}
                        columns={16}
                        pt={3}
                      >
                        <Grid
                          item
                          xs={8}
                          display="flex"
                          justifyContent="flex-start"
                          className="flag"
                        >
                          <img
                            style={{
                              maxWidth: "50px",
                              verticalAlign: "baseline",
                            }}
                            src={no.icon}
                          />{" "}
                          <span style={{ paddingLeft: "10px" }}>
                            {no.country_id.name}
                          </span>
                        </Grid>
                        <Grid
                          item
                          xs={8}
                          display="flex"
                          justifyContent="flex-end"
                          alignItems="around"
                        >
                          {no.number} {no.is_toll_free ? "(Toll Free)" : ""}{" "}
                        </Grid>
                      </Grid>
                    </Typography>
                  );
                })}
              </AccordionDetails>
            </Accordion>
            <div style={{ padding: "10px" }}></div>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3a-content"
                id="panel2a-header"
              >
                <Typography>
                  <WhatsAppIcon />
                  <b>Message us using WhatsApp</b> <br />
                  <span className="get-para">
                    {" "}
                    We currently support chat only
                  </span>
                </Typography>
              </AccordionSummary>

              <AccordionDetails>
                <AccordionDetails>
                  {contact?.results?.contactSupportWA?.map((no, index) => {
                    return (
                      <Typography>
                        <p className="number-details">
                          <span style={{ float: "" }}>{no.language}</span>
                        
                          <span style={{ float: "" }}>{no.number}</span>
                         
                        </p>
                      </Typography>
                    );
                  })}
                </AccordionDetails>
              </AccordionDetails>
            </Accordion>

            {/* <Accordion disabled>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3a-content"
                id="panel3a-header"
              >
                <Typography>Disabled Accordion</Typography>
              </AccordionSummary>
            </Accordion> */}
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default ContactUs;
