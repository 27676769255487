import { useState,useEffect } from "react";
import "./DestinationDetail.css";
import Image from "./Image";
import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from 'react-image-gallery';
import Modal from "@mui/material/Modal";
import StaticImage from "./StaticImage";

const Gallery = ({ desImages }) => {

  const [dyImages, setDyImages] = useState([]);

  const [AllImg, setAllImg] = useState(false);
  const AllImgOpen = () => setAllImg(true);
  const AllImgClose = () => setAllImg(false);

  const [dyAllImg, setDyAllImg] = useState(false);
  const DyAllImgOpen = () => setDyAllImg(true);
  const DyAllImgClose = () => setDyAllImg(false);

  const images = [
    {
      original: 'https://themes.pixelstrap.com/rica/assets/images/tour/tour/7.jpg',
      thumbnail: 'https://themes.pixelstrap.com/rica/assets/images/tour/tour/7.jpg',
    },
    {
      original: 'https://themes.pixelstrap.com/rica/assets/images/tour/tour/8.jpg',
      thumbnail: 'https://themes.pixelstrap.com/rica/assets/images/tour/tour/8.jpg',
    },
    {
      original: 'https://themes.pixelstrap.com/rica/assets/images/tour/tour/9.jpg',
      thumbnail: 'https://themes.pixelstrap.com/rica/assets/images/tour/tour/9.jpg',
    },
    {
      original: 'https://themes.pixelstrap.com/rica/assets/images/tour/tour/10.jpg',
      thumbnail: 'https://themes.pixelstrap.com/rica/assets/images/tour/tour/10.jpg',
    },
    {
      original: 'https://themes.pixelstrap.com/rica/assets/images/tour/tour/11.jpg',
      thumbnail: 'https://themes.pixelstrap.com/rica/assets/images/tour/tour/11.jpg',
    },
    {
      original: 'https://themes.pixelstrap.com/rica/assets/images/tour/tour/12.jpg',
      thumbnail: 'https://themes.pixelstrap.com/rica/assets/images/tour/tour/12.jpg',
    }
  ];


  useEffect(() => {
    setDyImages(
      Object.keys(desImages).map((item) => ({
        original: `${process.env.REACT_APP_BASE_URL}${desImages[item]?.images}`,
        thumbnail: `${process.env.REACT_APP_BASE_URL}${desImages[item]?.images}`
      }))
    );
  },[])

  return (
    <>
      <div className="gallery">
        <div className="imageFlex">
          {desImages.length ? (
            <div className="styleImages">
              <button onClick={DyAllImgOpen}>View all {desImages?.length} images</button>
              {desImages[0]?.images ? (
                <div className="firstImage">
                  <Image image={dyImages} image0={desImages[0]?.images} number={0}/>
                </div>
              ) : (
                <></>
              )}
              <div className="styleImages2">
                {desImages[1]?.images ? (
                  <div className="secondImage">
                    <Image image={dyImages} image0={desImages[1]?.images} number={1} />
                  </div>
                ) : (
                  <>
                  </>
                )}
                <div style={{ display: "flex", height: "70%" }}>
                  {desImages[2]?.images ? (
                    <div className="thirdImage">
                      <Image image={dyImages} image0={desImages[2]?.images} number={2}/>
                    </div>
                  ) : (
                    <></>
                  )}
                  {desImages[3]?.images ? (
                    <div className="thirdImage" style={{ marginLeft: "8px" }}>
                      <Image image={dyImages} image0={desImages[3]?.images} number={3}/>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          ) : (
            <div className="styleImages">
              <button onClick={AllImgOpen}>View all 6 images</button>
              <div className="firstImage">
                <StaticImage image={images} image0={images[0]?.original} number={0}/>
              </div>
              <div className="styleImages2">
                <div className="secondImage">
                  <StaticImage image={images} image0={images[1]?.original} number={1} />
                </div>
                <div style={{ display: "flex", height: "70%" }}>
                  <div className="thirdImage">
                    <StaticImage image={images} image0={images[2]?.original} number={2} />
                  </div>
                  <div className="thirdImage" style={{ marginLeft: "8px" }}>
                    <StaticImage image={images} image0={images[3]?.original} number={3} />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        
        {desImages.length ? (
            <div className="allImgModal2">
            <ImageGallery items={dyImages} showBullets={true} showThumbnails={false} showFullscreenButton={false} showPlayButton={false} autoPlay={true}/>
          </div>
        ) : 
         (

  <div className="allImgModal2">
    <ImageGallery items={images} showThumbnails={false} showBullets={true} showFullscreenButton={false} showPlayButton={false} autoPlay={true}/>
  </div>
        )}

      </div>

      <Modal open={AllImg} onClose={AllImgClose}>
        <div className="allImgModal">
          <i style={{color:"#fff"}} className="fa-solid fa-xmark" onClick={AllImgClose}></i>
          <ImageGallery items={images} />
        </div>
      </Modal>

      <Modal open={dyAllImg} onClose={DyAllImgClose}>
        <div className="allImgModal">
          <i style={{color:"#fff"}} className="fa-solid fa-xmark" onClick={DyAllImgClose}></i>
  
            <ImageGallery items={dyImages} />
        </div>
      </Modal>
    </>
  );
};

export default Gallery;
