import "./DestinationsList.css";
import AllDestinations from './AllDestinations';

const DestinationsList = () => {




  return (
    <div className="destinationsList">
      <img
        src="https://themes.pixelstrap.com/rica/assets/images/inner-pages/breadcrumb1.jpg"
        alt=""
      />
      <section>
        <div className="strechContainer">
        <div className="strechSection">
         <AllDestinations  />
         </div>
         </div>
      </section>
    </div>
  );
};

export default DestinationsList;
