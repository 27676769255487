import { useState, useEffect, useMemo } from 'react';
import { onGetReviews } from 'src/services/desReviewApi';
import { onAddReview } from 'src/services/desReviewApi';
import { onEditReview } from 'src/services/desReviewApi';
import { useDispatch, useSelector } from 'react-redux';
import { getReviews } from 'src/redux/reviewsSlice';
import { CSpinner } from "@coreui/react";
import { useForm } from "react-hook-form";
import { useNavigate } from 'react-router-dom';
import './DestinationDetail.css';
import OverallRating from './OverallRating';
import { Select, Modal } from 'antd';
import Rating from '@mui/material/Rating'
import ImportExportIcon from '@mui/icons-material/ImportExport';
import TuneIcon from '@mui/icons-material/Tune';
import Review from './Review';
import ReviewSummary from './ReviewSummary';


const Reviews = ({id, countryId, categoryId, slug}) => {

    const reviewsList = useSelector((state) => state.reviewReducer.reviews)
    const userId = useSelector((state) => state.detailReducer.user_Id);
    const dispatch = useDispatch()

    const navigate = useNavigate();

    const { register, handleSubmit, formState: { errors } } = useForm();

    const { Option } = Select;
    const [reviews, setReviews] = useState([]);
    const [sortByRating, setSortByRating] = useState("");
    const [search, setSearch] = useState("");
    const [stars, setStars] = useState("");
    const [average, setAverage] = useState([]);
    const [avgRating, setAvgRating] = useState("");
    const [reviewInput, setReviewInput] = useState("");
    const [starValue, setStarValue] = useState(0);
    const [msg, setMsg] = useState("");
    const [reviewId, setReviewId] = useState("");
    const [selectedReview, setSelectedReview] = useState("");
    const [selectedStar, setSelectedStar] = useState(0);

    const reviewsPerRow = 4;

    const [next, setNext] = useState(reviewsPerRow);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);
    const [showAddReview, setShowAddReview] = useState(false);
    const [showEditReview, setShowEditReview] = useState(false);
    const [successMsg, setSuccessMsg] = useState(false);
    const [loading, setLoading] = useState(false);

    
    const user = localStorage.getItem('token');
    

    const handleMoreReviews = () => {
        setNext(next + reviewsPerRow);
      };

    useEffect(() => {

      onGetReviews(id,sortByRating,search,stars).then((res) => {

        if(!res.error){
          dispatch(getReviews(res.data?.result))
          
          }
      })

    },[id, sortByRating, search, stars])

    useMemo(() => {
        setReviews(reviewsList)
    },[reviewsList]) 

    useMemo(() => {
    const avgArray = []
    
    const avgrt = reviews?.map((item) => {
        avgArray.push(item.star)
      })

    setAverage(avgArray)
  },[reviews])
    
    useMemo(() => {
    const Average = arr => arr.reduce( ( p, c ) => p + c, 0 ) / arr.length;
    const result = Average(average); 
    setAvgRating(result);
  },[average])
 

    const reviewByRating = (e) => {

        setStars(e.target.value)
    }


    const filterReviews = (e) => {
      setSortByRating(e)
    }
 


    const showModal = () => {
      setIsModalVisible(true);
    };
   
    const handleCancel = () => {
      setIsModalVisible(false);
    };

    const showModal2 = () => {
      setModalVisible(true);
    };
   
    const handleCancel2 = () => {
      setModalVisible(false);
    };

    const addReview = () => {
      setLoading(true)
      onAddReview({destination_id: id, country_id: countryId, category_id: categoryId, review: reviewInput, user_category: "solo", star: starValue}).then((res) => {

        if(res.status === 201){
          setLoading(false)
          setSuccessMsg(true)
        
          setMsg("Review added Successfully")
          window.location.reload()
        }else{
          setLoading(false)
        }
      })
    }


    const onClickEditReview = () => {
      setLoading(true)
      onEditReview(reviewId, {destination_id: id,review: selectedReview, star: selectedStar}).then((res) => {

        if(res.status === 200){
          setSuccessMsg(true)
          setMsg("Review Edited Successfully")
          setLoading(false)
          window.location.reload()
        }else{
          setLoading(false)
        }
      })
    }

    return (
        <>
        <div className='reviews reuseableContainer'>
          <div className='strechContainer' style={{width:"100%"}}>
          <div className='strechSection'>
            <h4 id='reviews'>Customer Reviews</h4>
       
        {reviews?.length ? <>
        <div className='customerReviews'>
            <OverallRating reviews={reviews} avgRating={avgRating}/>
            <ReviewSummary reviews={reviews}/>
        </div>
        <div className='reviewSortBy'>
          <div className='reviewFlex'>
            <h5 className='rvh5'>Sort by:</h5>
            
            <Select
            defaultValue={sortByRating}
            onChange={filterReviews}
            style={{
              width: 160,
            }}
          >
            <Option value="">Recommended</Option>
            <Option value={-1}>Highest rated</Option>
            <Option value={1}>Lowest rated</Option>
          </Select>
          </div>
          <div className='reviewSearch'>
            <i className="fa-solid fa-magnifying-glass"></i>
            <input type='text' placeholder='Search reviews' onChange={(e) => {
              setSearch(e.target.value)
            }}/>
          </div>

        </div></> : <></>}

        {reviews?.length ? <div className='filterModalBtns'>
            <button onClick={showModal}><ImportExportIcon /> Sort</button>
              <button onClick={showModal2}><TuneIcon /> Filter</button>
          </div> : <></>}

        <div className="reviewContainer">
      <div className="reviewFilter">
      
      {reviews?.length ? <>
          <h6>Star rating:</h6>
       
          
          <div className='reviewFilterStars'>
          <input type="radio" value="" id="star6" className="form-check-input" defaultChecked name="review" onClick={() => {
            setStars("")
          }}/><label for="star6">All star rating</label>
          </div>

          <div className='reviewFilterStars'>
            <input type="radio" className="form-check-input" id="star5" name="review" value={5} onClick={reviewByRating}/>
            <label for="star5">5 stars</label>
                <Rating name="read-only" value={5}  readOnly />
            </div>

            <div className='reviewFilterStars'>
            <input type="radio" className="form-check-input" id="star4" name="review" value={4} onClick={reviewByRating}/>
             <label for="star4">4 stars</label>
                <Rating name="read-only" value={4}  readOnly />
            
            </div>

            <div className='reviewFilterStars' >
            <input type="radio" className="form-check-input" id="star3" name="review" value={3} onClick={reviewByRating}/>
            <label for="star3">3 stars</label>
            <Rating name="read-only" value={3}  readOnly />
            
            </div>

            <div className='reviewFilterStars'>
            <input type="radio" className="form-check-input" id="star2" name="review" value={2} onClick={reviewByRating}/>
            <label for="star2">2 stars</label>
            <Rating name="read-only" value={2}  readOnly />
          
            </div>

            <div className='reviewFilterStars'>
            <input type="radio" className="form-check-input" id="star1" name="review" value={1} onClick={reviewByRating}/>
              <label for="star1">1 stars</label>
              <Rating name="read-only" value={1}  readOnly />

        
            </div>
            </> : <></>}

           <><button className='addReviewBtn' onClick={() => {
              !user ? navigate("/login") : !showAddReview ? setShowAddReview(true) : setShowAddReview(false)
              setShowEditReview(false)
            }}>Give A Review</button>
            {showAddReview && <div className='addReview' style={{display: showEditReview && "none"}}>
                <textarea type='text' placeholder='write review'  {...register("review", {required: "Review is required",})} onChange={(e) => {
                  setReviewInput(e.target.value)
                }}/>
                 <p style={{color:"red"}}>{errors.review?.message}</p>  
                
                <Rating
                    name="simple-controlled"
                    value={starValue}
                    onChange={(event, newValue) => {
                      setStarValue(newValue);
                      
                    }}
                  />
               
                {successMsg && <div className='addReviewSucc'><p>{msg}</p></div>}
                <button onClick={handleSubmit(addReview)}>{loading ? <CSpinner style={{ color: "#fff" }} /> : <>Add Review</>}</button>

            </div>}</>

            {showEditReview && <div className='addReview'>
                <textarea type='text' value={selectedReview} placeholder='write review' {...register("editreview", {required: "Review is required",})} onChange={(e) => {

                    setSelectedReview(e.target.value)
                }}/>
                  <p style={{color:"red"}}>{errors.editreview?.message}</p>
                
                <Rating
                    name="simple-controlled"
                    value={selectedStar}
                    onChange={(event, newValue) => {
                      setSelectedStar(newValue);
                      
                    }}
                  />
                {successMsg && <div className='addReviewSucc'><p>{msg}</p></div>}
               
                <button onClick={handleSubmit(onClickEditReview)}>{loading ? <CSpinner style={{ color: "#fff" }} /> : <>Edit Review</>}</button>

            </div>} 
        
      </div>
        <div className='Div'>
        {!reviews?.length ? <div className='reviewError'><h5>Reviews Not Found</h5></div> : reviews?.slice(0,next).map((userReview) => {  
         return <div  style={{position:"relative"}}>
         <Review key={userReview._id} createdAt={userReview.createdAt}  stars={userReview.star}  comment={userReview.review}/>
         {userReview.user_id === userId && <button  onClick={() => {
              setShowEditReview(true)
              setReviewId(userReview._id)
              setSelectedReview(userReview.review)
              setSelectedStar(userReview.star)
              
            }} style={{
              position: 'absolute',
              top: '23%',
              right: '5%',
              border: 'none',
              background: 'none',
              fontSize: '15px',
              fontWeight: '700',
              color: 'red',
            }}>edit</button>} 
         </div>
        })}
          {next < reviews?.length && (<div className='reviewBtn'>
            <button onClick={handleMoreReviews}>See more reviews</button>
            </div>)}
          </div>
          </div>
        </div>
        </div>
        </div>

        
        <Modal title="Sort By" centered={false} visible={isModalVisible} onCancel={handleCancel} footer={null}>
        <div className='reviewFilterStars' style={{display:"flex", borderBottom:"1px solid #ebeef1"}}>
              <input type="radio" className="form-check-input" id="radio0" name="review" value="" onClick={() => {
                setSortByRating("")
                setIsModalVisible(false)
              }}/>
                  <label  for="radio0">Recommended</label>
            </div>

            <div className='reviewFilterStars' style={{display:"flex", borderBottom:"1px solid #ebeef1",marginTop:"10px"}}>
              <input type="radio" className="form-check-input" id="radio1" name="review" value={-1} onClick={(e) => {
                setSortByRating(e.target.value)
                setIsModalVisible(false)
              }}/>
                  <label  for="radio1">Highest rated</label>
            </div>

            <div className='reviewFilterStars' style={{display:"flex", marginTop:'10px'}}>
            <input type="radio" className="form-check-input" id="radio2" name="review" value={1} onClick={(e) => {
                setSortByRating(e.target.value)
                setIsModalVisible(false)
              }}/>
                <label  for="radio2">Lowest rated</label>
            
            </div>
        </Modal>

        <Modal title="Filter by rating" centered={false} visible={modalVisible} onCancel={handleCancel2} footer={null}>
        <div className="reviewFilter">
       
          <div className='reviewFilterStars'>
          <input type="radio" value="" id="all" className="form-check-input" defaultChecked name="review" onClick={() => {
            setStars("")
            setModalVisible(false)
          }}/><label for="all">All star rating</label>
          </div>

          <div className='reviewFilterStars'>
            <input type="radio" className="form-check-input" id="star05" name="review" value={5} onClick={(e) => {
              setStars(e.target.value)
              setModalVisible(false)
            }}/>
            <label for="star05">5 stars</label>
                <Rating name="read-only" value={5}  readOnly />
            </div>

            <div className='reviewFilterStars'>
            <input type="radio" className="form-check-input" id="star04" name="review" value={4} onClick={(e) => {
              setStars(e.target.value)
              setModalVisible(false)
            }}/>
             <label for="star04">4 stars</label>
                <Rating name="read-only" value={4}  readOnly />
            
            </div>

            <div className='reviewFilterStars' >
            <input type="radio" className="form-check-input" id="star03" name="review" value={3} onClick={(e) => {
              setStars(e.target.value)
              setModalVisible(false)
            }}/>
            <label for="star03">3 stars</label>
            <Rating name="read-only" value={3}  readOnly />
            
            </div>

            <div className='reviewFilterStars'>
            <input type="radio" className="form-check-input" id="star02" name="review" value={2} onClick={(e) => {
              setStars(e.target.value)
              setModalVisible(false)
            }}/>
            <label for="star02">2 stars</label>
            <Rating name="read-only" value={2}  readOnly />
          
            </div>

            <div className='reviewFilterStars'>
            <input type="radio" className="form-check-input" id="star01" name="review" value={1} onClick={(e) => {
              setStars(e.target.value)
              setModalVisible(false)
            }}/>
              <label for="star01">1 stars</label>
              <Rating name="read-only" value={1}  readOnly />

        
            </div>
        
      </div>
        </Modal>
      
        </>
    )
}

export default Reviews;