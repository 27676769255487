import './DestinationDetail.css';

const NewsLetter = () => {

    return (
        <div className='newsLetter'>
            <div className='newsContainer'>
                <h5>Always First</h5>
                <p>Be the first to find out latest tours and exclusive offers and get 15% off your first booking.</p>
                <input className='detailInput' type='text' placeholder='Enter your email' />
                <button className='detailBtn'>Be The First</button>
            </div>
        </div>
    )
}

export default NewsLetter;